import { AgoraCall } from '@/utils/types';
import { create } from 'zustand';

interface CallStore {
	call: AgoraCall | null;
	setCall: (call: AgoraCall) => void;
}

export const useAgoraCallStore = create<CallStore>((set) => {
	return {
		call: null,
		setCall: (call) => set(() => ({ call })),
	};
});
