import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

import { mutationAPI } from './api/mutations';
import { queryAPI } from './api/queries';

import meetSlice from '@/lib/slice/meetSlice';
import authSlice from './slice/authSlice';
import counts from './slice/counts';
import notifications from './slice/notificatins';

const createNoopStorage = () => {
	return {
		getItem(_key: unknown) {
			return Promise.resolve(null);
		},
		setItem(_key: unknown, value: unknown) {
			return Promise.resolve(value);
		},
		removeItem(_key: unknown) {
			return Promise.resolve();
		},
	};
};

const appReducer = combineReducers({
	[queryAPI.reducerPath]: queryAPI.reducer,
	[mutationAPI.reducerPath]: mutationAPI.reducer,
	auth: authSlice,
	notifications,
	counts,
	meet: meetSlice,
});

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

const persistConfig = {
	key: 'root',
	storage,
	blacklist: [queryAPI.reducerPath, mutationAPI.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat([queryAPI.middleware, mutationAPI.middleware]),
});

export const persist = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type ReduxPersist = ReturnType<typeof persistStore>;
export type RootState = ReturnType<typeof store.getState>;

export default store;
