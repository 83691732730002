import { db } from '@/firebase/firebase';
import { useUserStore } from '@/hooks/chat/userStore';
import { AgoraCall } from '@/utils/types';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useEffect } from 'react';
import { useAgoraCallStore } from './agoraCallStore';

export function useRealTimeCallAgora() {
	const { setCall } = useAgoraCallStore();
	const { currentUser } = useUserStore();

	useEffect(() => {
		const q = query(
			collection(db, 'agoraCalls'),
			orderBy('createdAt', 'desc')
			// Ensure documents are ordered by the timestamp
		);

		const unsubscribe = onSnapshot(q, (snapshot) => {
			if (!snapshot.empty) {
				snapshot.docChanges().forEach((change) => {
					const call = change.doc.data() as AgoraCall;
					if (change.type !== 'removed') {
						if (
							currentUser?.id && // to check if the current user is available.
							call.participantIds.includes(currentUser.id) // If I am I call participant.
						) {
							setCall(call as AgoraCall);
						}
					}
				});
			} else {
				console.log('No calls found in the collection.');
			}
		});

		return () => {
			unsubscribe();
		};
	}, [setCall, currentUser]);

	return {};
}
