import { meetStateType } from '@/utils/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: meetStateType = {
	is_audio_enable: true,
	is_video_enable: true,
	is_decline_call: false,
	caller: null,
	accepted_at: null,
	roomUsers: [],
};

const authSlice = createSlice({
	name: 'meet',
	initialState,
	reducers: {
		setAudioPermission: (state, action: PayloadAction<boolean>) => {
			state.is_audio_enable = action.payload;
		},
		setVideoPermission: (state, action: PayloadAction<boolean>) => {
			state.is_video_enable = action.payload;
		},
		setDeclineCall: (state, action: PayloadAction<boolean>) => {
			state.is_decline_call = action.payload;
		},
		setCaller: (state, action: PayloadAction<any>) => {
			state.caller = action.payload;
		},
		setAcceptAt: (state, action: PayloadAction<any>) => {
			state.accepted_at = action.payload;
		},
		setInitCall: (state, action: PayloadAction<any>) => {
			state.is_audio_enable = true;
			state.is_video_enable = false;
			state.is_decline_call = false;
			state.caller = null;
			state.accepted_at = null;
			state.roomUsers = [];
		},
		pushRoomUser: (state, action: PayloadAction<any>) => {
			const _roomUsers = [...state.roomUsers];
			_roomUsers.push(action.payload);
			state.roomUsers = _roomUsers;
		},
		setRoomUsers: (state, action: PayloadAction<any>) => {
			state.roomUsers = action.payload;
		},
		removeRoomUser: (state, action: PayloadAction<any>) => {
			// WIP
			// state.roomUsers.push(action.payload);
			// return state;
		},
	},
});

export const {
	setVideoPermission,
	setAudioPermission,
	setCaller,
	setInitCall,
	setDeclineCall,
	setAcceptAt,
	pushRoomUser,
	removeRoomUser,
	setRoomUsers,
} = authSlice.actions;
export default authSlice.reducer;
