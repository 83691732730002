import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CountsType {
	notifications: number;
	wishlists: number;
	cart: number;
	credits: string;
	balance: string;
}

const initialState = {
	counts: {
		notifications: 0,
		wishlists: 0,
		cart: 0,
		credits: '',
		balance: '',
	},
};

const counts = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		initialReadCounts: (state, action: PayloadAction<CountsType>) => {
			state.counts = action.payload;
		},
	},
});

export const { initialReadCounts } = counts.actions;
export default counts.reducer;
