/* eslint-disable react-hooks/exhaustive-deps */
import Circle from '@/components/Circle';
import Profile from '@/components/icons/connections_card/Profile';
import Modal from '@/components/Modal';
import { useAudioControls } from '@/hooks/useAudioControls';
import { useAppSelector } from '@/lib/hooks';
import { useCallback, useEffect, useState } from 'react';
import Button from '../../Button';
import IncomingCallIcon from '../../icons/IncomingCallIcon';

import AudioStatus from '@/components/icons/AudioStatus';
import VideoStatus from '@/components/icons/VideoStatus';
import { getUserById } from '@/firebase/users';
import useCall from '@/hooks/chat/useCall';
const IncomingCallModalAgora = () => {
	// main status
	const { call, changeUserStatus, IAmCaller } = useCall();
	const [isOpenCallModal, setIsOpenCallModal] = useState<boolean>(false);
	const { user } = useAppSelector((state) => state.auth);

	// controls
	const [mic, setMic] = useState<boolean>(false);
	const [camera, setCamera] = useState<boolean>(false);

	const [userNames, setUserNames] = useState<(string | null)[]>([]);

	useEffect(() => {
		const fetchUserNames = async () => {
			if (call?.participantIds) {
				try {
					// Map participant IDs to display names
					const names = await Promise.all(
						call.participantIds.map(async (id) => {
							if (id === user.id) return null; // Exclude the current user
							try {
								const user = await getUserById(id);
								return user?.displayName ?? null; // Return display name or null
							} catch {
								return null; // Handle individual user fetch errors
							}
						})
					);
					setUserNames(names.filter(Boolean)); // Remove null values from the list
				} catch (error) {
					console.error('Failed to fetch user names:', error);
				}
			}
		};

		fetchUserNames();
	}, [call, user.id]);

	// audio controls
	const { playAudio: playIncoming, stopAudio: stopIncoming } = useAudioControls('/google_meet.mp3');

	// Redirect to call page
	const handleAccept = useCallback(async () => {
		if (user && call) changeUserStatus(call.id, user?.id, 'joined');
		stopIncoming();
		window.open(
			`/my-office/new-chat/call-room?callId=${call?.id}&mic=${mic}&camera=${camera}&chatId=${call?.chatId}&callType=${call?.type}`
		);
		setIsOpenCallModal(false);
	}, [user, call, changeUserStatus, stopIncoming]);

	// Play/Stop audio when call modal is opened/closed
	useEffect(() => {
		if (isOpenCallModal) playIncoming();
		else stopIncoming();
	}, [isOpenCallModal, playIncoming, stopIncoming]);

	// Open call modal when call is initiated and close when call is declined
	useEffect(() => {
		if (call?.participantStatus[user.id] === 'initiated' && !IAmCaller) setIsOpenCallModal(true);
		else setIsOpenCallModal(false);
	}, [IAmCaller, call?.participantStatus, user.id]);

	// Hangup call if not accepted in 30 seconds
	useEffect(() => {
		if (isOpenCallModal && call?.participantStatus[user.id] === 'initiated') {
			const timeoutId = setTimeout(() => {
				changeUserStatus(call.id, user?.id, 'declined');
			}, 30000);
			return () => clearTimeout(timeoutId);
		}
	}, [call, isOpenCallModal]);

	if (!call) return null;

	return (
		<Modal
			icon={<IncomingCallIcon />}
			onClose={() => setIsOpenCallModal(false)}
			isOpen={isOpenCallModal}
			heading={'Incoming Call'}
			footer={
				<div className={'flex gap-[15px]'}>
					<Button
						title={'Cancel'}
						type={'secondary'}
						onClick={async () => {
							await changeUserStatus(call.id, user?.id, 'declined');
						}}
					/>
					{<Button title={'Accept'} type={'primary'} onClick={handleAccept} />}
				</div>
			}
		>
			<div className="flex flex-col items-center space-y-6 p-6">
				<Circle backgroundClass="!size-[45px]" type={0} icon={<Profile />} status="online" imageUrl={''} />

				<div className="text-center">
					<h2 className="mb-2 text-2xl font-bold text-gray-800">
						{(() => {
							const maxVisible = 2; // Number of names to display before showing "+X others"
							const visibleNames = userNames.slice(0, maxVisible).filter(Boolean); // Show valid names only
							const othersCount = userNames.length - visibleNames.length;

							if (userNames.length === 0) return 'No participants';
							if (othersCount > 0) {
								return `${visibleNames.join(', ')} +${othersCount} others`;
							}
							return visibleNames.join(', ');
						})()}
					</h2>
					<p
						className={
							'mb-2.5 animate-pulse whitespace-nowrap bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] bg-clip-text text-center text-[14px] font-bold not-italic text-transparent'
						}
					>
						Incoming call...
					</p>
				</div>

				<div className="flex justify-center space-x-4">
					<div className={'flex gap-[15px] rounded-[50px] border border-[#0000001A] p-[15px]'}>
						<AudioStatus onClick={() => setMic(!mic)} status={!mic} className="cursor-pointer" />
						<div onClick={() => setCamera(!camera)} className="cursor-pointer">
							<VideoStatus status={!camera} />
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default IncomingCallModalAgora;
