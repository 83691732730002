import { db } from '@/firebase/firebase';
import { AgoraCall, AgoraCallStatus, AgoraCallTypes } from '@/utils/types';
import { collection, deleteDoc, doc, getDocs, query, setDoc, Timestamp, where } from 'firebase/firestore';
import { useState } from 'react';
import { useAgoraCallStore } from './agoraCallStore';
import { useUserStore } from './userStore';

const useCall = () => {
	const { call } = useAgoraCallStore();
	const [icommingCall, setIcommingCall] = useState<boolean>(false);

	// Get the current user
	const { currentUser } = useUserStore();

	// Is am I the Caller
	const IAmCaller = currentUser?.id === call?.hostId;

	// Check if the user in a call already.
	const checkIfUserInCall = async (userId: string) => {
		const existingCallQuery = query(
			collection(db, 'calls'),
			where('participantIds', 'array-contains', userId),
			where('status', 'in', ['ongoing', 'initiated'])
		);
		const existingCallSnapshot = await getDocs(existingCallQuery);
		return !existingCallSnapshot.empty;
	};

	// Create a new call
	const createCall = async (chatId: string, hostId: string, participantIds: string[], type: AgoraCallTypes) => {
		// // get other user
		// const otherUser = participantIds.filter((id) => id !== callerId);

		// // Check if the user is in a call already
		// const userInCall = await checkIfUserInCall(otherUser[0]);
		// if (userInCall) {
		// 	toast('User in a call, please try again later.', {
		// 		icon: '⌛',
		// 	});
		// 	return;
		// }

		// Create a new call document
		const callDoc = doc(collection(db, 'agoraCalls'));

		// Delete existing call if any
		const existingCallQuery = query(collection(db, 'agoraCalls'), where('chatId', '==', chatId));
		const existingCallSnapshot = await getDocs(existingCallQuery);
		existingCallSnapshot.forEach(async (doc) => {
			await deleteDoc(doc.ref);
		});

		const call: AgoraCall = {
			id: callDoc.id,
			hostId,
			chatId,
			type,
			participantIds: participantIds,
			createdAt: Timestamp.now(),
			participantStatus: participantIds.reduce(
				(acc, id) => {
					acc[id] = 'initiated';
					return acc;
				},
				{} as Record<string, AgoraCallStatus>
			),
		};

		await setDoc(callDoc, call);
		return callDoc.id;
	};

	const changeUserStatus = async (callId: string, userId: string, status: AgoraCallStatus) => {
		const callQuery = query(collection(db, 'agoraCalls'), where('id', '==', callId));
		const callSnapshot = await getDocs(callQuery);

		if (callSnapshot.empty) {
			throw new Error('Call not found');
		}
		const callDoc = callSnapshot.docs[0].ref;
		await setDoc(
			callDoc,
			{
				participantStatus: {
					[userId]: status,
				},
			},
			{ merge: true }
		);
	};

	return { call, checkIfUserInCall, createCall, changeUserStatus, IAmCaller };
};

export default useCall;
