'use client';

import { useChatStore } from '@/hooks/chat/chatStore';
import { useRealtimeNotifications } from '@/hooks/chat/useRealtimeNotifications';
import useFcmToken from '@/hooks/useFcmToken';
import { useLazyNotificationCountQuery } from '@/lib/api/queries';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { usePathname } from 'next/navigation';
import { ReactNode, useEffect } from 'react';
import Header from '../components/Header';
import Footer from './ui/Footer';

import { useRealTimeCallAgora } from '@/hooks/chat/useRealtimeAgoraCall';
import { initialReadCounts } from '@/lib/slice/counts';
import IncomingCallModalAgora from './call/modals/IncomingCallModalAgora';
type LayoutWrapperProps = {
	children: ReactNode;
};

const initialCountsData = { notifications: 0, wishlists: 0, cart: 0, credits: '', balance: '' };

const LayoutWrapper = ({ children }: LayoutWrapperProps) => {
	const pathname = usePathname();
	const isAdminRoute = pathname?.startsWith('/admin');
	const { isLogin } = useAppSelector((state) => state.auth);

	const [getNotificationCount, { data: countsData }] = useLazyNotificationCountQuery();
	//realtime notificaion
	const { token, notificationPermissionStatus } = useFcmToken();

	const dispatch = useAppDispatch();
	useEffect(() => {
		if (isLogin) getNotificationCount({}, false);
	}, [getNotificationCount, isLogin]);

	useEffect(() => {
		if (countsData?.counts) {
			dispatch(initialReadCounts(countsData?.counts || initialCountsData));
		}
	}, [countsData?.counts]);

	// Add permission for chat notification
	const { chatId } = useChatStore();
	const { requestNotificationPermission, totalUnread } = useRealtimeNotifications({ currentChatId: chatId ?? '' });

	useEffect(() => {
		requestNotificationPermission();
	}, [requestNotificationPermission]);

	useRealTimeCallAgora();

	return isAdminRoute ? (
		children
	) : (
		<>
			<IncomingCallModalAgora />
			<Header totalUnread={totalUnread || 0} />
			<div className="mb-[30px] w-full pt-[76px]">{children}</div>
			<Footer />
		</>
	);
};

export default LayoutWrapper;
