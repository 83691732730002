import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const MenuPodcast = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
		<path
			d="M9 10.6143C9 8.95741 10.3431 7.61426 12 7.61426C13.6569 7.61426 15 8.95741 15 10.6143V13.6143C15 15.2712 13.6569 16.6143 12 16.6143C10.3431 16.6143 9 15.2712 9 13.6143V10.6143Z"
			stroke="white"
		/>
		<path d="M12.9999 10.6143H14.9999" stroke="white" stroke-linecap="round" />
		<path d="M12.9999 13.6143H14.9999" stroke="white" stroke-linecap="round" />
		<path d="M9 10.6143H10" stroke="white" stroke-linecap="round" />
		<path d="M9 13.6143H10" stroke="white" stroke-linecap="round" />
		<path
			d="M16.9513 8.86419C17.0893 9.25473 17.5178 9.45942 17.9084 9.32139C18.2989 9.18336 18.5036 8.75486 18.3655 8.36433L16.9513 8.86419ZM6.7499 14.7681V10.6142H5.2499V14.7681H6.7499ZM5.2499 14.7681C5.2499 15.3735 4.75914 15.8643 4.15375 15.8643V17.3643C5.58756 17.3643 6.7499 16.202 6.7499 14.7681H5.2499ZM17.2498 14.7681C17.2498 16.202 18.4122 17.3643 19.846 17.3643V15.8643C19.2406 15.8643 18.7498 15.3735 18.7498 14.7681H17.2498ZM11.9998 3.86426C8.27197 3.86426 5.2499 6.88633 5.2499 10.6142H6.7499C6.7499 7.71476 9.1004 5.36426 11.9998 5.36426V3.86426ZM11.9998 5.36426C14.2846 5.36426 16.2302 6.8241 16.9513 8.86419L18.3655 8.36433C17.4393 5.74366 14.94 3.86426 11.9998 3.86426V5.36426Z"
			fill="white"
		/>
		<path
			d="M2.00006 12.6143C2.00006 11.5097 2.89549 10.6143 4.00006 10.6143C5.10463 10.6143 6.00006 11.5097 6.00006 12.6143V14.6143C6.00006 15.7189 5.10463 16.6143 4.00006 16.6143C2.89549 16.6143 2.00006 15.7189 2.00006 14.6143V12.6143Z"
			stroke="white"
		/>
		<path
			d="M18 12.6143C18 11.5097 18.8954 10.6143 20 10.6143C21.1046 10.6143 22 11.5097 22 12.6143V14.6143C22 15.7189 21.1046 16.6143 20 16.6143C18.8954 16.6143 18 15.7189 18 14.6143V12.6143Z"
			stroke="white"
		/>
		<path d="M12 16.6143V19.6143" stroke="white" stroke-linecap="round" />
	</svg>
);
export default MenuPodcast;
