'use client';

import AgoraRTC, { AgoraRTCProvider } from 'agora-rtc-react';

export default function AgoraProvider({ children }: { children: React.ReactNode }) {
	// In video call, set mode to "rtc"
	const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

	return <AgoraRTCProvider client={client}>{children}</AgoraRTCProvider>;
}
