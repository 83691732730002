import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
	color?: string;
}

const Plus = (props: SVGProps<SVGSVGElement>) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M12 5V19" stroke={props.color || 'white'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M5 12H19" stroke={props.color || 'white'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);
export default Plus;
