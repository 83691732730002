// src/hooks/useRealtimeNotifications.ts
import Circle from '@/components/Circle';
import Profile from '@/components/icons/connections_card/Profile';
import { isChatMuted, markChatAsRead } from '@/firebase/chats';
import { db } from '@/firebase/firebase';
import { getMessagePreview } from '@/firebase/messages';
import { Message } from '@/utils/types';
import { collection, onSnapshot, orderBy, query, Timestamp, where } from 'firebase/firestore';
import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useChatStore } from './chatStore';
import { useUserStore } from './userStore';
interface UseRealtimeNotificationsProps {
	currentChatId?: string;
}

export function useRealtimeNotifications({ currentChatId }: UseRealtimeNotificationsProps = {}) {
	const { currentUser } = useUserStore();
	const [totalUnread, setTotalUnread] = useState(0);
	const [notificationSound] = useState(new Audio('/notification_beep.mp3'));
	const [notificationSound2] = useState(new Audio('/web_whatsapp.mp3'));
	const lastProcessedMessage = useRef<string | null>(null);
	const { chatId } = useChatStore();

	// Track global unread messages across all chats
	useEffect(() => {
		if (!currentUser?.id) return;

		const chatsQuery = query(collection(db, 'chats'), where('participantIds', 'array-contains', currentUser.id));

		return onSnapshot(chatsQuery, (snapshot) => {
			let count = 0;
			snapshot.docs.forEach((doc) => {
				const chat = doc.data();
				count += chat.unreadCount?.[currentUser.id] || 0;
			});
			setTotalUnread(count);
		});
	}, [currentUser?.id]);

	// Handle notification permission
	const requestNotificationPermission = useCallback(async () => {
		if (Notification.permission === 'default') {
			await Notification.requestPermission();
		}
	}, []);

	// Show notification
	const showNotification = useCallback(
		(message: Message) => {
			if (
				Notification.permission === 'granted' &&
				message.chatId !== currentChatId
				// &&
				// document.visibilityState !== 'visible'
			) {
				const notification = new Notification(message?.sender?.displayName, {
					body: getMessagePreview(message.type, message.content),
					tag: message.chatId,
					data: {
						chatId: message.chatId,
						messageId: message.id,
					},
				});

				notification.onclick = () => {
					window.focus();
					window.location.href = `/my-office/new-chat`;
				};
			}
		},
		[currentChatId]
	);

	// Play notification sound

	const playNotificationSound = useCallback(() => {
		notificationSound.play().catch(console.error);
	}, [notificationSound]);

	const playNotificationSound2 = useCallback(() => {
		notificationSound2.play().catch(console.error);
	}, [notificationSound2]);

	// Subscribe to new messages across all chats
	useEffect(() => {
		if (!currentUser?.id) return;

		const now = Timestamp.now();

		const messagesQuery = query(
			collection(db, 'messages'),
			where('createdAt', '>', now),
			where('senderId', '!=', currentUser.id),
			where('participantIds', 'array-contains', currentUser.id),
			orderBy('createdAt', 'desc')
		);

		return onSnapshot(messagesQuery, (snapshot) => {
			snapshot.docChanges().forEach(async (change) => {
				if (change.type === 'added') {
					const message = { id: change.doc.id, ...change.doc.data() } as Message;
					console.log('message', message);

					const notify = () =>
						toast.custom((t) => (
							<div
								className={`${
									t.visible ? 'animate-enter' : 'animate-leave'
								} pointer-events-auto flex w-full max-w-sm rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
							>
								<div className="w-0 flex-1 p-4">
									<div className="flex items-start">
										<Circle
											backgroundClass={'!size-[43px]'}
											type={0}
											icon={<Profile />}
											status={'online'}
											imageUrl={message?.sender?.photoURL}
										/>
										<div className="ml-3 flex-1">
											<p className="text-sm font-medium text-gray-900">{message?.sender?.displayName}</p>
											<p className="mt-1 text-sm text-gray-500"> {getMessagePreview(message.type, message.content)}</p>
										</div>
									</div>
								</div>
							</div>
						));

					// Prevent duplicate notifications
					if (lastProcessedMessage.current !== message.id) {
						lastProcessedMessage.current = message.id;
						const isMuted = await isChatMuted(message.chatId, currentUser.id);
						console.log('isMuted', isMuted);

						if (chatId && message.chatId === chatId) {
							markChatAsRead(message.chatId, currentUser.id);
							if (!isMuted) playNotificationSound2();
						} else {
							if (!isMuted) {
								notify();
								showNotification(message);
								playNotificationSound();
							}
						}
					}
				}
			});
		});
	}, [currentUser?.id, showNotification, playNotificationSound, chatId, playNotificationSound2]);

	return {
		totalUnread,
		requestNotificationPermission,
	};
}
