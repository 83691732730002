'use client';

import Button from '@/components/Button';
import LoginModal from '@/components/LoginModal';
import ProfileDropdown from '@/components/ProfileDropdown';
import GradientText from '@/components/custom-components/GradientText';
import CheckBox from '@/components/form/CheckBox';
import InputError from '@/components/form/InputError';
import VVSearch from '@/components/modals/VVSearch';
import {
	useLoginUsingEmailMutation,
	useLogoutMutation,
	useRegisterUsingEmailMutation,
	useSendEmailVerificationMutation,
} from '@/lib/api/mutations';
import { useAppSelector } from '@/lib/hooks';
import { logoutUser, setLogin, setToken, setUser } from '@/lib/slice/authSlice';
import { formatCurrency } from '@/utils/helpers';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import clsx from 'clsx';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { LogIn } from 'lucide-react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Circle from './Circle';
import EmailInput from './EmailInput';
import HeaderNotification from './HeaderNotification';
import LocaleSwitcher from './LocaleSwitcher';
import Balance from './icons/Balance';
import EmailO from './icons/EmailO';
import Logo from './icons/Logo';
import Magnify from './icons/Magnify';
import Messages from './icons/Messages';
import MyCredits from './icons/MyCredits';
import Notification from './icons/Notification';
import Plus from './icons/Plus';
import ShoppingCart from './icons/ShoppingCart';
import Wishlist from './icons/Wishlist';
import NewAssetBuildersModal from './modals/NewAssetBuildersModal';
import MegaMenu from './ui/MegaMenu';
import NonLoggedInMenu from './ui/NonLoggedInMenu';
import { InputOTP, InputOTPGroup, InputOTPSlot } from './ui/input-otp';
import { useToast } from './ui/use-toast';

const Header = ({ totalUnread }: { totalUnread: number }) => {
	const t = useTranslations('footer');

	const dispatch = useDispatch();
	const auth = useAppSelector((state) => state.auth);
	const { user, isLogin } = auth;
	const router = useRouter();
	const { toast } = useToast();
	const openLoginModalCookie = getCookie('loginModalCookie');
	const { counts } = useAppSelector((state) => state.counts);
	const { wishlists, balance, notifications, cart, credits } = counts;

	const [showMegaMenu, setShowMegaMenu] = useState(false);
	const [showNonLoggedInMenu, setShowNonLoggedInMenu] = useState(false);

	const [sendEmailVerificationRequest] = useSendEmailVerificationMutation();
	const [sendRegisterUserRequest, { data: registerUserData }] = useRegisterUsingEmailMutation();
	const [sendLoginUsingEmailRequest, { data: loginUserData, error: loginUserError }] = useLoginUsingEmailMutation();
	const [logoutF, { data: logoutData }] = useLogoutMutation();

	const [isOpen, setIsOpen] = useState(false);
	const [resetPage, setResetPage] = useState(false);
	const [otpPage, setOtpPage] = useState(false);
	const [otp, setOtp] = useState('');
	const [isOpenAssetBuilderModal, setIsOpenAssetBuilderModal] = useState(false);
	const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);
	const [signInType, setSignInType] = useState<'login' | 'register'>('login');
	const [email, setEmail] = useState<string>('');
	const [conditionAgreed, setConditionAgreed] = useState<boolean>(true);
	const [countdown, setCountdown] = useState<number>(0);
	const [emailError, setEmailError] = useState('');
	const [otpError, setOtpError] = useState('');
	const [otpAttempts, setOtpAttempts] = useState(3);
	const [otpDisabled, setOtpDisabled] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => {
			if (countdown > 0) {
				setCountdown(countdown - 1);
			}

			if (countdown === 0) {
				clearInterval(interval);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	});

	// useEffect(() => {
	// 	if (otpVerificationError) {
	// 		if ('data' in otpVerificationError) {
	// 			const errMsg: any = otpVerificationError.data;
	// 			setOtpError(errMsg.message);
	// 			setOtpAttempts((prev) => prev - 1);
	// 			toast({
	// 				title: errMsg.message,
	// 				variant: 'destructive',
	// 			});
	// 		}
	// 	}
	// }, [otpVerificationError]);

	useEffect(() => {
		if (loginUserError) {
			if ('data' in loginUserError) {
				const errMsg: any = loginUserError.data;
				setOtpError(errMsg.message);
				setOtpAttempts((prev) => prev - 1);
				toast({
					title: errMsg.message,
					variant: 'destructive',
				});
			}
		}
	}, [loginUserError]);

	useEffect(() => {
		if (otpAttempts == 0) {
			setOtpDisabled(true);
			const timer = setTimeout(
				() => {
					setOtpDisabled(false);
				},
				15 * 60 * 1000
			); // 15 minutes
			setOtpAttempts(1);
			return () => clearTimeout(timer);
		}
	}, [otpAttempts]);

	useEffect(() => {
		if (loginUserData) {
			dispatch(setUser(loginUserData.user));
			dispatch(
				setToken({ token: loginUserData.token, expires_in: loginUserData.expires_in + Math.floor(Date.now() / 1000) })
			);
			dispatch(setLogin(true));
			setIsOpen(false);
			setCookie('isLoggedIn', true);
			setCookie('verification_status', loginUserData.user.verification_status);
			setCookie('showWelcomeModal', loginUserData.user.is_hide_welcome ? 'true' : 'false');
			if (loginUserData.user.verification_status === 'pending') {
				router.replace('/pending-verification');
			} else if (loginUserData.user.verification_status !== 'approved') {
				router.replace('/legal-info-verification');
			}
		}
	}, [loginUserData]);

	useEffect(() => {
		if (registerUserData) {
			const expiry = registerUserData.expires_in + Math.floor(Date.now() / 1000);
			dispatch(setUser(registerUserData.user));
			dispatch(
				setToken({
					token: registerUserData.token,
					expires_in: isNaN(expiry) ? 34982094783274 : expiry,
				})
			);
			dispatch(setLogin(true));
			setIsOpen(false);
			setCookie('isLoggedIn', true);
			setCookie('verification_status', registerUserData.user.verification_status);
			window.open('/legal-info-verification', '_self');
		}
	}, [registerUserData]);

	useEffect(() => {
		if (logoutData && logoutData?.message) {
			toast({
				title: logoutData.message,
			});
			deleteCookie('isLoggedIn');
			deleteCookie('verification_status');
			deleteCookie('showVerificationModal');
			deleteCookie('showWelcomeModal');
			dispatch(logoutUser());
			router.replace('/');
		}
	}, [logoutData]);

	// useEffect(() => {
	//   if (otpVerificationData) {
	//     sendRegisterUserRequest({
	//       // email: email,
	//       phone: phone,
	//       code: otp,
	//       password: 'password',
	//       password_confirmation: 'password',
	//     });
	//   }
	// }, [otpVerificationData]);

	useEffect(() => {
		if (openLoginModalCookie === 'true') {
			setIsOpen(true);
		}
	}, [openLoginModalCookie]);
	const handleErrorResponse = (response: any) => {
		if (response?.error) {
			setEmailError(response?.error?.data?.errors?.phone[0] ?? '');
		}
		if (response?.data?.errors) {
			setEmailError(response?.data?.errors?.phone[0] ?? '');
		}
		if (response?.data?.message) {
			toast({
				title: response?.data?.message,
				variant: 'success',
			});
			setOtpPage(true);
			setCountdown(45);
		}
	};
	const handleLoginForm = async () => {
		const response: any = await sendEmailVerificationRequest({
			email: email,
			type: 'login',
		});
		handleErrorResponse(response);
	};

	const handleRegisterForm = async () => {
		const response: any = await sendEmailVerificationRequest({
			email: email,
			type: 'register',
		});
		handleErrorResponse(response);
	};

	const handleOtpSubmission = () => {
		if (signInType === 'register') {
			sendRegisterUserRequest({
				email,
				code: otp,
			});
		} else {
			sendLoginUsingEmailRequest({
				email,
				code: otp,
				type: 'code',
			});
		}
	};

	const handleBackToEnterNumber = () => {
		setOtpPage(false);
	};

	return (
		<div className="fixed inset-x-0 top-0 z-20">
			<InputError error={emailError} isToast={true} />
			<div className="flex w-full items-center justify-center border-b border-[#E5E5E5] bg-white px-[16px] py-2.5 md:px-[30px]">
				<LoginModal
					isOpen={isOpen}
					onClose={() => {
						setIsOpen(!isOpen);
						setCookie('loginModalCookie', 'false');
					}}
				>
					<div className="relative">
						{otpPage ? (
							<div className="mb-[15px] flex flex-col space-y-[15px]">
								<div className="flex h-6 items-center justify-center gap-2.5 self-stretch">
									<GradientText
										title={t('letsVerifyThis')}
										className="whitespace-nowrap font-poppins text-[20px] font-bold not-italic leading-6"
									/>
								</div>
								<div className="text-center">
									<p className="text-sm text-gray-500">{t('enterOtp')} </p>
									<p className="text-sm text-gray-500">{t('toVerify')}</p>
								</div>
								<div className="flex justify-center">
									<div className="flex flex-col gap-[10px]">
										<InputOTP
											disabled={otpDisabled}
											maxLength={6}
											containerClassName="!m-0 !p-0 !mt-4"
											onChange={(e) => setOtp(e)}
										>
											<InputOTPGroup className="gap-3.5">
												<InputOTPSlot
													index={0}
													className={`!rounded-sm border border-[#E5E5E5] ${otpError ? 'border-red-500 text-red-500' : 'border-[#016dea]'} bg-white`}
												/>
												<InputOTPSlot
													index={1}
													className={`!rounded-sm border border-[#E5E5E5] ${otpError ? 'border-red-500 text-red-500' : 'border-[#016dea]'} bg-white`}
												/>
												<InputOTPSlot
													index={2}
													className={`!rounded-sm border border-[#E5E5E5] ${otpError ? 'border-red-500 text-red-500' : 'border-[#016dea]'} bg-white`}
												/>
												<InputOTPSlot
													index={3}
													className={`!rounded-sm border border-[#E5E5E5] ${otpError ? 'border-red-500 text-red-500' : 'border-[#016dea]'} bg-white`}
												/>
												<InputOTPSlot
													index={4}
													className={`!rounded-sm border border-[#E5E5E5] ${otpError ? 'border-red-500 text-red-500' : 'border-[#016dea]'} bg-white`}
												/>
												<InputOTPSlot
													index={5}
													className={`!rounded-sm border border-[#E5E5E5] ${otpError ? 'border-red-500 text-red-500' : 'border-[#016dea]'} bg-white`}
												/>
											</InputOTPGroup>
										</InputOTP>
										{otpError != '' && (
											<div className="w-full text-center text-[10px] font-semibold capitalize text-red-500">
												<p>
													{t('invalidOtp')} {otpAttempts > 0 ? t('pleaseTryAgain') : ''}{' '}
												</p>
												{otpAttempts > 0 && (
													<p>
														{t('youHave')} {otpAttempts} {t('attemptsLeft')}
													</p>
												)}
												{otpAttempts == 0 && (
													<>
														<p>{t('maxAttemptsReached')}</p>
														<p>{t('tryAfter15Min')}</p>
													</>
												)}
											</div>
										)}
									</div>
								</div>
								<div className="flex items-center justify-center gap-6">
									{countdown >= 0 ? (
										<div className={`text-sm ${countdown == 0 ? 'text-gray-600' : 'text-black'}`}>
											{countdown < 10 ? '00:0' : '00:'}
											{countdown}
										</div>
									) : null}
									<button
										disabled={countdown > 0}
										className="disabled:cursor-not-allowed"
										onClick={() => {
											if (signInType === 'login') {
												handleLoginForm();
											} else {
												handleRegisterForm();
											}
										}}
									>
										<div
											title=""
											className={`whitespace-nowrap font-poppins text-sm font-medium not-italic leading-6 ${countdown == 0 ? 'default-gradient bg-clip-text text-transparent' : '!text-[#aaa9ad]'}`}
										>
											{t('resendOtp')}
										</div>
									</button>
								</div>
								<Button
									title={t('letsGo')}
									onClick={() => handleOtpSubmission()}
									disabled={otp.length < 6 || !conditionAgreed}
									className="mt-6 rounded-full bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] transition-colors duration-1000 ease-in-out hover:bg-[linear-gradient(242deg,#016DEA_27.41%,#FA0101_95.02%)]"
								/>
								<Button
									title={t('back')}
									onClick={() => handleBackToEnterNumber()}
									className="mt-6 rounded-full bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] transition-colors duration-1000 ease-in-out hover:bg-[linear-gradient(242deg,#016DEA_27.41%,#FA0101_95.02%)]"
								/>
								{signInType && (
									<label className="flex items-center -space-x-[1px]">
										<CheckBox
											checked={conditionAgreed}
											onChange={(v) => setConditionAgreed(v)}
											onKeyDown={(e) => {
												if (e.key === 'Enter' && otp.length === 6 && conditionAgreed) {
													handleOtpSubmission();
												}
											}}
										/>
										<p className="text-sm font-medium text-gray-400">
											{t('agreeToTerms')}{' '}
											<a
												target={'_blank'}
												href={'/the-legalities/terms-and-conditions'}
												className="text-blue-600 underline"
											>
												{t('clickHere')}
											</a>
										</p>
									</label>
								)}
							</div>
						) : (
							<div className="mb-[15px] flex flex-col space-y-[15px]">
								{!resetPage ? (
									<>
										<div className="flex h-6 items-center justify-center gap-2.5 self-stretch">
											<GradientText
												title={t('getIntoVv')}
												className="whitespace-nowrap font-poppins text-[20px] font-bold not-italic leading-6"
											/>
										</div>
										<div className="flex items-center justify-center">
											<TabGroup
												defaultIndex={signInType === 'login' ? 0 : 1}
												className={'flex flex-col items-center justify-center space-y-[15px]'}
											>
												<TabList className="border-gradient-custom w-[271px] rounded-full p-[1px]">
													<div className="flex w-full rounded-full bg-white px-[1px] pb-[1px] pt-[1px]">
														<Tab
															className={`data-[selected]:blue-red-gradient blue-red-gradient h-[38px] w-[126px] rounded-full bg-clip-text font-poppins text-[14px] font-semibold text-transparent data-[selected]:w-[141px] data-[selected]:bg-clip-content data-[selected]:text-white`}
															onClick={() => {
																setEmailError('');
																setSignInType('login');
															}}
														>
															{t('login')}
														</Tab>
														<Tab
															className={`data-[selected]:blue-red-gradient blue-red-gradient grid h-[38px] w-[126px] place-items-center rounded-full bg-clip-text font-poppins text-[14px] font-semibold text-transparent data-[selected]:w-[141px] data-[selected]:bg-clip-content data-[selected]:text-white`}
															onClick={() => {
																setEmailError('');
																setSignInType('register');
															}}
														>
															{t('joinVv')}
														</Tab>
													</div>
												</TabList>
												<TabPanels className="mt-3">
													<TabPanel className={'flex w-[365px] flex-col space-y-[15px]'}>
														<div className="relative flex min-w-[365px] flex-col gap-[10px]">
															<div className="relative w-full" onClick={() => setEmailError('')}>
																<EmailInput
																	error={emailError}
																	onInputChange={(e) => setEmail(e?.toString() || '')}
																	onKeyDown={(e) => {
																		if (e.key === 'Enter') {
																			handleLoginForm();
																			setSignInType('login');
																		}
																	}}
																/>
															</div>
														</div>
														<Button
															title={t('continue')}
															onClick={() => {
																setEmailError('');
																handleLoginForm();
																setSignInType('login');
															}}
															onKeyDown={(e) => {
																if (e.key === 'Enter' || e.key === 'Go') {
																	e.preventDefault();
																	setEmailError('');
																	handleLoginForm();
																	setSignInType('login');
																}
															}}
															tabIndex={0} // Ensures the button is focusable with keyboard
															className="rounded-full bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] transition-colors duration-1000 ease-in-out hover:bg-[linear-gradient(242deg,#016DEA_27.41%,#FA0101_95.02%)]"
														/>

														<div className="relative min-w-[365px] text-center font-poppins text-[14px] font-medium text-[#7f7f7f]">
															{t('cantLogin')}
															<span
																className="text-decoration-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] blue-red-gradient cursor-pointer bg-clip-text text-transparent underline"
																onClick={() => {
																	setResetPage(true);
																	setEmailError('');
																}}
															>
																{t('clickHere')}
															</span>
														</div>
													</TabPanel>
													<TabPanel className={'flex w-[365px] flex-col space-y-[15px]'}>
														<div className="relative flex min-w-[365px] flex-col gap-[10px]">
															<div className="relative w-full" onClick={() => setEmailError('')}>
																<EmailInput
																	value={email}
																	onInputChange={(e) => setEmail(e?.toString() || '')}
																	error={emailError}
																/>
															</div>
														</div>

														<Button
															title={t('continue')}
															onClick={() => {
																setEmailError('');
																handleRegisterForm();
																setSignInType('register');
															}}
															className="rounded-full bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] transition-colors duration-1000 ease-in-out hover:bg-[linear-gradient(242deg,#016DEA_27.41%,#FA0101_95.02%)]"
														/>
														<div className="relative flex min-w-[365px] justify-center text-center font-poppins text-[14px] font-medium text-[#7f7f7f]">
															{t('cantLogin')}
															<span
																className="text-decoration-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] blue-red-gradient cursor-pointer bg-clip-text text-transparent underline"
																onClick={() => {
																	setResetPage(true);
																}}
															>
																{t('clickHere')}
															</span>
														</div>
													</TabPanel>
												</TabPanels>
											</TabGroup>
										</div>
									</>
								) : (
									<div className="flex flex-col space-y-[15px]">
										<div className="flex h-6 items-center justify-center gap-2.5 self-stretch">
											<h3 className="whitespace-nowrap bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] bg-clip-text font-poppins text-[20px] font-bold not-italic leading-6 text-transparent">
												{t('resetPassword')}
											</h3>
										</div>
										<div className="flex flex-col items-center justify-center space-y-[15px]">
											<div className="relative flex min-w-[365px] flex-col gap-[10px]">
												<div className="relative w-full">
													<input
														type="email"
														placeholder={t('enterEmail')}
														onKeyUp={() => setEmailError('')}
														className={clsx(
															'w-full rounded-full border',
															emailError != '' ? '!border-red-500 text-red-500' : 'border-gray-300',
															'bg-white py-3 pl-4 pr-12 text-sm font-medium text-gray-700 placeholder-gray-500 focus:outline-none'
														)}
														// className={`w-full rounded-full border ${email.length > 0 ? (emailError ? 'border-red-500' : 'border-[#016dea]') : 'border-gray-300'} bg-white py-3 pl-4 pr-12 text-sm text-gray-700 placeholder-gray-500 focus:outline-none`}
													/>
													<EmailO
														width={16}
														height={16}
														className="absolute right-4 top-1/2 -translate-y-1/2 transform text-gray-400"
													/>
												</div>
												{emailError != '' && (
													<div className="w-full text-center text-[10px] font-semibold capitalize text-red-500">
														{emailError}
													</div>
												)}
											</div>
											<Button
												title={t('continue')}
												onClick={() => {}}
												className="w-full rounded-full bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] transition-colors duration-1000 ease-in-out hover:bg-[linear-gradient(242deg,#016DEA_27.41%,#FA0101_95.02%)]"
											/>
											<div className="relative flex min-w-[365px] justify-center text-center font-poppins text-[14px] font-medium text-[#7f7f7f]">
												<span
													className="text-decoration-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] blue-red-gradient cursor-pointer bg-clip-text text-transparent underline"
													onClick={() => {
														setResetPage(false);
														setSignInType('login');
													}}
												>
													{t('login')}
												</span>
												{t('or')}
												<span
													className="text-decoration-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] blue-red-gradient cursor-pointer bg-clip-text text-transparent underline"
													onClick={() => {
														setResetPage(false);
														setSignInType('register');
													}}
												>
													{t('joinVv')}
												</span>
											</div>
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</LoginModal>

				<NewAssetBuildersModal
					isOpen={isOpenAssetBuilderModal}
					setIsOpen={(value) => setIsOpenAssetBuilderModal(value)}
				/>
				<VVSearch isOpen={isOpenSearchModal} setIsOpen={(value) => setIsOpenSearchModal(value)} />

				<div className="flex flex-1 items-center justify-between p-px">
					<Link href="/">
						<Logo />
					</Link>

					<div className="flex items-center justify-end gap-x-3 lg:gap-x-5">
						{isLogin ? (
							user.verification_status === 'approved' ? (
								<>
									<div onClick={() => setIsOpenSearchModal(!isOpenSearchModal)} className="hidden lg:block">
										<Circle title={t('search')} type={0} icon={<Magnify />} zIndex={99} />
									</div>
									<div onClick={() => setIsOpenAssetBuilderModal(!isOpenAssetBuilderModal)} className="hidden lg:block">
										<Circle title={t('create')} type={0} icon={<Plus />} borderClass="cursor-pointer" zIndex={99} />
									</div>
									<div className="relative hidden lg:flex">
										<Link href="/my-office/notifications">
											<Circle title={t('notification')} type={0} icon={<Notification />} zIndex={99} />
										</Link>
										{notifications ? <HeaderNotification notification={notifications?.toString() || ''} /> : null}
									</div>
									<div className="relative hidden lg:flex">
										<Link href="/my-office/new-chat">
											<Circle title={t('messages')} type={0} icon={<Messages />} zIndex={99} />
										</Link>
										{totalUnread ? <HeaderNotification notification={totalUnread?.toString() || '0'} /> : null}
									</div>
									<div className="relative hidden lg:flex">
										<Link href="/my-office/wishlist">
											<Circle title={t('wishlist')} type={0} icon={<Wishlist />} zIndex={99} />
										</Link>
										{wishlists ? <HeaderNotification notification={wishlists} /> : null}
									</div>
									<div className="relative hidden lg:flex">
										<Link href="/my-office/shopping-cart">
											<Circle title={t('cart')} type={0} icon={<ShoppingCart />} zIndex={99} />
										</Link>
										{cart ? <HeaderNotification notification={cart} /> : null}
									</div>
									<div className="relative hidden lg:flex">
										<Link href="/my-account/my-credits/summary">
											<Circle title={t('credits')} type={0} icon={<MyCredits />} zIndex={99} />
										</Link>
										{credits ? <HeaderNotification notification={formatCurrency(credits)} /> : null}
									</div>
									<div className="relative hidden lg:flex">
										<Link href="/my-account/my-wallet/balance">
											<Circle title={t('balance')} type={0} icon={<Balance />} zIndex={99} />
										</Link>
										{balance ? (
											<HeaderNotification notification={formatCurrency(balance)} className="!left-[10px]" />
										) : null}
									</div>
									<ProfileDropdown />
									<LocaleSwitcher />
								</>
							) : (
								<Button title={t('logout')} onClick={() => logoutF({})} />
							)
						) : (
							<>
								<Button
									id="login/signup-button"
									onClick={() => {
										setOtpPage(false);
										// setEmail('');
										setEmail('');
										setConditionAgreed(true);
										setSignInType('login');
										setResetPage(false);
										setIsOpen(!isOpen);
									}}
									title={
										window.innerWidth < 768
											? ((<LogIn className="h-4 w-4" strokeWidth={2.5} absoluteStrokeWidth />) as unknown as any)
											: (t('loginJoin') as string)
									}
								/>
								<LocaleSwitcher />
							</>
						)}

						{isLogin ? (
							user.verification_status === 'approved' ? (
								<MegaMenu totalUnread={totalUnread} isOpen={showMegaMenu} setIsOpen={setShowMegaMenu} />
							) : (
								<NonLoggedInMenu isOpen={showNonLoggedInMenu} setIsOpen={setShowNonLoggedInMenu} />
							)
						) : (
							<NonLoggedInMenu isOpen={showNonLoggedInMenu} setIsOpen={setShowNonLoggedInMenu} />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
