import { SVGProps } from 'react';

type Props = {
	width?: number;
	height?: number;
} & SVGProps<SVGSVGElement>;

const DropdownProfile = (props: Props) => {
	const { width = 24, height = 24 } = props;
	return (
		<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clipPath="url(#clip0_10183_6791)">
				<path
					d="M16.6945 17.4039C15.6835 16.7869 15.3207 16.849 15.3207 15.6162C15.3207 15.1847 15.3207 14.383 15.3207 13.6434C15.8756 13.0272 16.0774 13.1039 16.5467 10.6846C17.4955 10.6846 17.4341 9.45176 17.8816 8.35821C18.2464 7.46768 17.9408 7.10665 17.6336 6.95909C17.8847 3.71387 17.8847 1.47881 14.2013 0.785454C12.8662 -0.358931 10.8515 0.0295942 9.89675 0.220561C8.94285 0.411528 8.08235 0.220561 8.08235 0.220561L8.24622 1.75816C6.84934 3.43453 7.54788 5.66958 7.32298 6.97867C7.02991 7.13833 6.76555 7.50645 7.11453 8.35821C7.56204 9.45176 7.50056 10.6846 8.44949 10.6846C8.91873 13.1039 9.12048 13.0272 9.67542 13.6434C9.67542 14.383 9.67542 15.1847 9.67542 15.6162C9.67542 16.849 9.25642 16.8703 8.30173 17.4039C5.99998 18.6905 3.00051 19.438 2.46311 23.4591C2.36467 24.1945 6.58753 24.9975 12.4981 25C18.4086 24.9975 22.6313 24.1945 22.5331 23.4591C21.9957 19.438 18.9687 18.7914 16.6945 17.4039Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_10183_6791">
					<rect width={width} height={height} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
export default DropdownProfile;
