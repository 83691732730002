'use client';

import { Locale } from '@/i18n/config';
import { setUserLocale } from '@/i18n/services';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { LanguageIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import Circle from '../Circle';
import TooltipComponent from '../TooltipComponent';

type Props = {
	items: Array<{ id: string; value: string; label: string }>;
	label: string;
	locale: string;
};

export default function LocaleSwitcherSelect({ locale, items, label }: Props) {
	const onLocaleChange = (value: string) => {
		setUserLocale(value as Locale).then(() => {
			window.location.reload();
		});
	};

	return (
		<Menu>
			<TooltipComponent title={label} zIndex={99}>
				<MenuButton>
					<Circle icon={<LanguageIcon className="h-6 w-6 text-white" />} />
				</MenuButton>
			</TooltipComponent>
			<MenuItems
				transition
				anchor="bottom end"
				className="border-gray z-30 max-w-40 translate-y-3 space-y-1 rounded-lg border bg-white p-1 text-sm font-medium text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
			>
				{items.map(({ value, label, id }) => {
					return (
						<MenuItem key={id}>
							<button
								className={clsx(
									'w-full rounded-md px-3 py-1.5 text-center',
									locale === value
										? 'default-border-gradient text-white'
										: 'group-hover:!text-white data-[focus]:bg-gray-100'
								)}
								onClick={() => onLocaleChange(value)}
							>
								{/* {locale === value && <CheckIcon className="size-6 default-border-gradient bg-clip-text" />} */}
								{label}
							</button>
						</MenuItem>
					);
				})}
			</MenuItems>
		</Menu>
	);
}
