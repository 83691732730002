import MenuDots from '@/components/icons/MenuDots';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { PencilIcon, TrashIcon } from '@heroicons/react/16/solid';
import clsx from 'clsx';
import { ReactNode } from 'react';

type Props = {
	Button?: ReactNode;
	menuClass?: string;
	children?: ReactNode;
	position?: any;
	isDisabled?: boolean;
};
export default function Dropdown({ Button, children, menuClass, position = 'bottom end', isDisabled = false }: Props) {
	return (
		<Menu>
			{Button ? (
				<MenuButton className="disabled:cursor-not-allowed" disabled={isDisabled}>
					{Button}
				</MenuButton>
			) : (
				<MenuButton
					disabled={isDisabled}
					className="inline-flex items-center gap-2 rounded-md text-sm/6 font-semibold text-black shadow-inner shadow-white/10 focus:outline-none disabled:cursor-not-allowed data-[focus]:outline-1 data-[focus]:outline-white"
				>
					<MenuDots />
				</MenuButton>
			)}

			<MenuItems
				transition
				anchor={position}
				className={clsx(
					'border-gray z-50 origin-top-right gap-[15px] rounded-xl border bg-white p-[15px]',
					'text-sm/6 text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)]',
					'focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0',
					menuClass
				)}
			>
				{children ? (
					children
				) : (
					<>
						<MenuItem>
							<button className="group flex w-full items-center gap-2 rounded-lg px-3 py-1 hover:text-white data-[focus]:bg-blue-500">
								<PencilIcon className="size-4 fill-gray-500 group-hover:fill-white" />
								Edit
							</button>
						</MenuItem>
						<MenuItem>
							<button className="group flex w-full items-center gap-2 rounded-lg px-3 py-1 hover:text-white data-[focus]:bg-red-500">
								<TrashIcon className="size-4 fill-gray-500 group-hover:fill-white" />
								Delete
							</button>
						</MenuItem>
					</>
				)}
			</MenuItems>
		</Menu>
	);
}
