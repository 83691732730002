import * as React from 'react';

type Props = {
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const XMark = (props: Props) => {
	const { width = 50, height = 50, ...rest } = props;
	return (
		<svg width={width} height={height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<circle opacity={0.6} cx={25} cy={25} r={25} fill="url(#paint0_linear_30_309)" />
			<defs>
				<linearGradient
					id="paint0_linear_30_309"
					x1={19.5602}
					y1={47.5379}
					x2={60.0003}
					y2={26.4634}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#016DEA" />
					<stop offset={1} stopColor="#FA0101" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default XMark;
