'use client';

import Button from '@/components/Button';
import Lock from '@/components/icons/Lock';
import Modal from '@/components/Modal';
import { useAppSelector } from '@/lib/hooks';
import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/navigation';
import CircleGradientLock from './icons/CircleGradientLock';

type Props = {
	visible?: boolean;
	onClose?: () => void;
};

export default function RestrictedContent(props: Props) {
	const router = useRouter();
	const { visible = false, onClose } = props;
	const isLoggedIn = useAppSelector((state) => state.auth.isLogin);
	const _showVerificationModal = getCookie('showVerificationModal');

	return (
		<Modal
			zIndexClass="!bg-[#333333] !bg-opacity-[97%] z-[100]"
			heading="Restricted Content"
			icon={<Lock />}
			isStrict={true}
			isOpen={_showVerificationModal === 'true' || visible}
			onClose={() => {
				setCookie('showVerificationModal', 'false');
				onClose?.();
				window.location.href = '/';
			}}
			footer={
				<div className="flex flex-row space-x-[15px]">
					<Button
						title="Cancel"
						onClick={() => {
							if (onClose) {
								onClose();
							} else {
								setCookie('showVerificationModal', 'false');
								window.location.href = '/';
							}
						}}
						type={'secondary'}
					/>
					<Button
						title={isLoggedIn ? 'Verify' : 'Login/Join VV'}
						onClick={() => {
							if (isLoggedIn) {
								router.push('/legal-info-verification');
							} else {
								setCookie('loginModalCookie', 'true');
								router.refresh();
							}
						}}
						type={'primary'}
					/>
				</div>
			}
		>
			<div className="flex flex-col items-center justify-center space-y-2.5">
				<CircleGradientLock />
				<span className="pt-1 text-lg font-bold leading-[21px] text-[#0567E9]">Exclusive Content</span>
				<p className="pt-1 text-sm/none font-medium text-black/50">
					This Content is Available Only For Registered &amp; Verified Users
				</p>
				<p className="pt-1 text-sm/none font-medium text-black/50">
					Please Login/Register To Be Able To Perform This Action
				</p>
			</div>
		</Modal>
	);
}
