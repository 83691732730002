import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const LoginVVIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width="226" height="230" viewBox="0 0 226 230" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M144.143 137.645C146.137 145.088 146.323 152.618 144.921 159.744L144.784 160.428C141.03 178.285 127.41 193.577 108.369 198.679C89.2822 203.794 69.8877 197.347 57.7075 183.76L57.2478 183.236L-51.1748 62.2874C-60.4339 51.9477 -55.3928 35.504 -41.8263 31.8688L26.1633 13.6511C33.0799 11.7978 40.4173 13.9616 45.1407 19.2307L66.7197 43.3028L133.751 118.122C138.544 123.588 142.153 130.217 144.143 137.645Z"
			fill="url(#paint0_linear_9303_13777)"
		/>
		<path
			d="M155.075 -20.8903C168.641 -24.5255 181.229 -12.8054 178.364 0.782872L149.905 136.101L144.895 159.752C146.313 152.621 146.108 145.08 144.117 137.652C142.127 130.224 138.518 123.595 133.741 118.125L66.7254 43.3015L73.3773 11.665C74.8334 4.74012 80.1215 -0.806655 87.0224 -2.65575L155.075 -20.8903Z"
			fill="url(#paint1_linear_9303_13777)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_9303_13777"
				x1="-34.2163"
				y1="133.357"
				x2="131.119"
				y2="89.0552"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FA4001" />
				<stop offset="0.333333" stopColor="#CD0D14" />
				<stop offset="0.666667" stopColor="#A91D1E" />
				<stop offset="1" stopColor="#812924" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_9303_13777"
				x1="78.8387"
				y1="88.4995"
				x2="195.586"
				y2="57.2172"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="0.334096" stopColor="#005FCD" />
				<stop offset="0.634882" stopColor="#0052B1" />
				<stop offset="1" stopColor="#003B7E" />
			</linearGradient>
		</defs>
	</svg>
);
export default LoginVVIcon;
