import { useLocale } from 'next-intl';
import { useEffect } from 'react';

export const useSyncLocaleWithStorage = () => {
	const locale = useLocale();

	useEffect(() => {
		if (locale) {
			localStorage.setItem('language', locale);
		}
	}, [locale]);
};
