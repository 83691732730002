import { useLocale } from 'next-intl';

const AdLabel = () => {
	const locale = useLocale();
	return (
		<div className="violet-gradient flex h-[18px] w-6 items-center justify-center rounded-[3px] rtl:p-3 rtl:px-5">
			<p className="text-xs font-bold leading-none text-white">{`${locale === 'en' ? 'AD' : 'اعلان'}`}</p>
		</div>
	);
};

export default AdLabel;
