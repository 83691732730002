import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Ad = (props: SVGProps<SVGSVGElement>) => (
	<svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.16669 0.000183105C1.06212 0.000183105 0.166687 0.895613 0.166687 2.00018V16.0002C0.166687 17.1048 1.06212 18.0002 2.16669 18.0002H22.1667C23.2713 18.0002 24.1667 17.1048 24.1667 16.0002V2.00018C24.1667 0.895613 23.2713 0.000183105 22.1667 0.000183105H2.16669ZM8.58978 5.61556C8.43451 5.24292 8.07041 5.00018 7.66671 5.00018C7.26301 5.00018 6.8989 5.24292 6.74363 5.61556L4.24363 11.6156C4.03121 12.1254 4.27229 12.7109 4.78209 12.9233C5.29189 13.1357 5.87737 12.8946 6.08978 12.3848L6.25004 12.0002H9.08337L9.24363 12.3848C9.45605 12.8946 10.0415 13.1357 10.5513 12.9233C11.0611 12.7109 11.3022 12.1254 11.0898 11.6156L8.58978 5.61556ZM8.25004 10.0002L7.66671 8.60018L7.08337 10.0002H8.25004ZM13.1667 6.00018C13.1667 5.4479 13.6144 5.00018 14.1667 5.00018H16.1667C17.8236 5.00018 19.1667 6.34333 19.1667 8.00018V10.0002C19.1667 11.6571 17.8236 13.0002 16.1667 13.0002H14.1667C13.6144 13.0002 13.1667 12.5525 13.1667 12.0002V6.00018ZM15.1667 7.00018V11.0002H16.1667C16.719 11.0002 17.1667 10.5525 17.1667 10.0002V8.00018C17.1667 7.44788 16.719 7.00018 16.1667 7.00018H15.1667Z"
			fill="url(#paint0_linear_54_1551)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_54_1551"
				x1="24.1667"
				y1="9.00019"
				x2="0.166687"
				y2="9.00019"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#7F00FF" />
				<stop offset="1" stopColor="#5200A5" />
			</linearGradient>
		</defs>
	</svg>
);
export default Ad;
