import { calculateRelevanceScore, generateSearchTerms } from '@/utils/chat/search';
import { chunkArray } from '@/utils/chat/utils';
import {
	Chat,
	ChatUser,
	DeleteAllMessagesOptions,
	DeleteMessageForAllParams,
	DeleteMessageParams,
	EditMessageParams,
	Message,
	MessageReaction,
	MessageStatus,
	MessageTypeChat,
	ToggleReactionParams,
} from '@/utils/types';
import {
	arrayRemove,
	arrayUnion,
	collection,
	doc,
	DocumentData,
	DocumentSnapshot,
	getDoc,
	getDocs,
	increment,
	limit,
	onSnapshot,
	orderBy,
	query,
	Query,
	serverTimestamp,
	startAfter,
	Timestamp,
	updateDoc,
	where,
	writeBatch,
} from 'firebase/firestore';
import { db } from './firebase';

const MESSAGES_PER_PAGE = 25;

export function subscribeToMessages(
	chatId: string,
	userId: string,
	callback: (messages: Message[]) => void,
	options: {
		limit?: number;
		lastMessage?: DocumentSnapshot<DocumentData>;
		IsWebinarPrivate?: boolean;
	} = {}
): () => void {
	const { limit: messageLimit = MESSAGES_PER_PAGE } = options;

	let messagesQuery: Query<DocumentData> = query(
		collection(db, 'messages'),
		where('deletedFor', 'not-in', [[userId]]),
		where('isDeletedForAll', '==', false),
		where('chatId', '==', chatId),
		orderBy('createdAt', 'desc'),
		limit(messageLimit)
	);

	if (options.lastMessage) {
		messagesQuery = query(messagesQuery, startAfter(options.lastMessage));
	}

	if (options.IsWebinarPrivate) {
		const webinarQuery = query(
			collection(db, 'messages'),
			where('webinarPrivateChat', '==', true),
			where('senderId', '==', userId),
			orderBy('createdAt', 'desc'),
			limit(messageLimit)
		);

		return onSnapshot(
			webinarQuery,
			(snapshot) => {
				const messages = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				})) as Message[];

				callback(messages);
			},
			(error) => {
				console.error('Error in subscribeToMessages:', error);
			}
		);
	}

	return onSnapshot(
		messagesQuery,
		(snapshot) => {
			const messages = snapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			})) as Message[];

			callback(messages);
		},
		(error) => {
			console.error('Error in subscribeToMessages:', error);
		}
	);
}

export async function loadMoreMessages(
	chatId: string | null,
	lastMessage: DocumentSnapshot<DocumentData>,
	limitNumber: number = MESSAGES_PER_PAGE
): Promise<{ messages: Message[]; lastMessage: DocumentSnapshot | null }> {
	try {
		const messagesQuery = query(
			collection(db, 'messages'),
			where('chatId', '==', chatId),
			orderBy('createdAt', 'desc'),
			startAfter(lastMessage),
			limit(limitNumber)
		);

		const snapshot = await getDocs(messagesQuery);
		const messages = snapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		})) as Message[];

		return {
			messages,
			lastMessage: snapshot.docs[snapshot.docs.length - 1] || null,
		};
	} catch (error) {
		console.error('Error in loadMoreMessages:', error);
		throw error;
	}
}

export function getMessagePreview(type: MessageTypeChat, content: string): string {
	switch (type) {
		case 'image':
			return '📷 Image';
		case 'video':
			return '🎥 Video';
		case 'audio':
			return '🎵 Audio message';
		case 'file':
			return '📎 File';
		default:
			return content;
	}
}

export async function updateMessageStatus(
	messageId: string,
	userId: string,
	chatId: string,
	status: MessageStatus
): Promise<void> {
	try {
		const messageRef = doc(db, 'messages', messageId);
		const chatRef = doc(db, 'chats', chatId);

		const batch = writeBatch(db);

		if (status === 'seen') {
			batch.update(messageRef, {
				seenBy: arrayUnion(userId),
				status: status,
			});

			// Update last message in chat if this is the last message
			batch.update(chatRef, {
				'lastMessage.seenBy': arrayUnion(userId),
				'lastMessage.status': status,
			});
		} else if (status === 'delivered') {
			batch.update(messageRef, {
				deliveredTo: arrayUnion(userId),
				status: status,
			});

			// Update last message in chat if this is the last message
			batch.update(chatRef, {
				'lastMessage.deliveredTo': arrayUnion(userId),
				'lastMessage.status': status,
			});
		}

		await batch.commit();
	} catch (error) {
		console.error('Error updating message status:', error);
		throw error;
	}
}

export async function sendMessage(
	chatId: string | null,
	userId: string,
	sender: ChatUser,
	messageData: {
		type: MessageTypeChat;
		content: string;
		attachments?: Message['attachments'];
		replyTo?: Message['replyTo'];
		isHost?: boolean;
	}
): Promise<string> {
	try {
		const batch = writeBatch(db);

		// First, get the chat document to get all participants
		const chatRef = doc(db, 'chats', chatId!);
		const chatDoc = await getDoc(chatRef);

		if (!chatDoc.exists()) {
			throw new Error('Chat not found');
		}

		const chatData = chatDoc.data();
		const participantIds = chatData.participantIds || [];

		// Create unread count updates for all participants except sender
		const unreadCountUpdates = participantIds.reduce(
			(acc: any, participantId: string) => {
				if (participantId !== userId) {
					acc[`unreadCount.${participantId}`] = increment(1);
				}
				return acc;
			},
			{} as { [key: string]: any }
		);

		const searchTerms = generateSearchTerms(messageData.content);

		// Create the message document
		const messageRef = doc(collection(db, 'messages'));
		const messageContent = {
			id: messageRef.id,
			chatId,
			senderId: userId,
			sender,
			type: messageData.type,
			content: messageData.content,
			attachments: messageData.attachments,
			replyTo: messageData.replyTo ?? null,
			status: 'sent',
			seenBy: [userId], // Add sender to seenBy array
			deliveredTo: [],
			createdAt: serverTimestamp(),
			updatedAt: serverTimestamp(),
			deletedFor: [],
			isDeletedForAll: false,
			isEdited: false,
			reactions: {} as Message['reactions'],
			searchTerms,
			contentLower: messageData.content.toLowerCase(),
			participantIds: participantIds,
			isHost: messageData.isHost ?? false,
		};

		batch.set(messageRef, messageContent);

		// Update the chat document with last message and unread counts
		const chatUpdates = {
			lastMessage: {
				id: messageRef.id,
				senderId: userId,
				sender,
				type: messageData.type,
				content: messageData.content,
				preview: getMessagePreview(messageData.type, messageData.content),
				createdAt: serverTimestamp(),
				seenBy: [userId], // Add sender to seenBy array
				deliveredTo: [],
			},
			updatedAt: serverTimestamp(),
			...unreadCountUpdates, // Add unread count updates
			lastActivity: serverTimestamp(),
		};

		batch.update(chatRef, chatUpdates);

		// Commit all operations
		await batch.commit();

		return messageRef.id;
	} catch (error) {
		console.error('Error in sendMessage:', error);
		throw error;
	}
}

export async function deleteAllMessages({ chatId, userId }: DeleteAllMessagesOptions): Promise<void> {
	try {
		// Get chat document to verify user is a participant
		const chatRef = doc(db, 'chats', chatId);
		const chatDoc = await getDoc(chatRef);

		if (!chatDoc.exists()) {
			throw new Error('Chat not found');
		}

		const chatData = chatDoc.data() as Chat;

		// Verify user is a participant
		if (!chatData.participantIds.includes(userId)) {
			throw new Error('Unauthorized to delete messages');
		}

		// Get all messages from this chat that aren't already deleted for this user
		const messagesQuery = query(
			collection(db, 'messages'),
			where('chatId', '==', chatId),
			where('deletedFor', 'not-in', [[userId]])
		);

		const messagesSnapshot = await getDocs(messagesQuery);

		// If no messages to delete, return early
		if (messagesSnapshot.empty) {
			return;
		}

		// Firestore has a limit of 500 operations per batch
		// So we need to chunk our updates if there are many messages
		const chunks = chunkArray(messagesSnapshot.docs, 500);

		for (const chunk of chunks) {
			const batch = writeBatch(db);

			chunk.forEach((doc) => {
				batch.update(doc.ref, {
					deletedFor: arrayUnion(userId),
					updatedAt: serverTimestamp(),
				});
			});

			await batch.commit();
		}

		// Update chat member document to mark messages as cleared
		const memberRef = doc(db, 'chatMembers', `${chatId}_${userId}`);
		await updateDoc(memberRef, {
			lastClearedAt: serverTimestamp(),
			updatedAt: serverTimestamp(),
		});
	} catch (error) {
		console.error('Error deleting all messages:', error);
		throw error;
	}
}

export async function editMessage({ messageId, chatId, content, userId }: EditMessageParams): Promise<void> {
	try {
		// Get message document to verify ownership
		const messageRef = doc(db, 'messages', messageId);
		const messageDoc = await getDoc(messageRef);

		if (!messageDoc.exists()) {
			throw new Error('Message not found');
		}

		const messageData = messageDoc.data();

		// Verify message ownership
		if (messageData.senderId !== userId) {
			throw new Error('Not authorized to edit this message');
		}

		// Update message
		await updateDoc(messageRef, {
			content,
			isEdited: true,
			editedAt: serverTimestamp(),
			updatedAt: serverTimestamp(),
		});

		// If this is the last message in the chat, update it there too
		const chatRef = doc(db, 'chats', chatId);
		const chatDoc = await getDoc(chatRef);

		if (chatDoc.exists()) {
			const chatData = chatDoc.data();

			if (chatData.lastMessage?.id === messageId) {
				const lastMessageUpdate = {
					lastMessage: {
						id: messageRef.id,
						senderId: userId,
						type: messageData.type,
						content: content,
						preview: getMessagePreview(messageData.type, content),
						createdAt: serverTimestamp(),
						seenBy: [], // Initialize empty arrays for tracking
						deliveredTo: [],
					},
					updatedAt: serverTimestamp(),
					deletedFor: [], // Initialize empty arrays for tracking
				};
				await updateDoc(chatRef, lastMessageUpdate);
			}
		}
	} catch (error) {
		console.error('Error editing message:', error);
		throw error;
	}
}

// Helper function to get the previous non-deleted message
async function getPreviousMessage(chatId: string, userId: string) {
	const messagesRef = collection(db, 'messages');
	const q = query(
		messagesRef,
		where('chatId', '==', chatId),
		where('deletedFor', 'not-in', [[userId]]),
		orderBy('createdAt', 'desc'),
		limit(1)
	);

	const snapshot = await getDocs(q);
	return snapshot.empty ? null : { id: snapshot.docs[0].id, ...snapshot.docs[0].data() };
}

export async function deleteMessageForUser({ messageId, chatId, userId }: DeleteMessageParams): Promise<void> {
	try {
		// Get message document
		const messageRef = doc(db, 'messages', messageId);
		const messageDoc = await getDoc(messageRef);

		if (!messageDoc.exists()) {
			throw new Error('Message not found');
		}

		// Update message document to add user to deletedFor array
		await updateDoc(messageRef, {
			deletedFor: arrayUnion(userId),
			updatedAt: serverTimestamp(),
		});

		// If this was the last message in chat, find and update the previous visible message
		const chatRef = doc(db, 'chats', chatId);
		const chatDoc = await getDoc(chatRef);

		if (chatDoc.exists()) {
			const chatData = chatDoc.data();

			if (chatData.lastMessage?.id === messageId) {
				// Get the previous message that's not deleted for this user
				const previousMessage = (await getPreviousMessage(chatId, userId)) as Message;

				if (previousMessage) {
					// Update chat's last message
					await updateDoc(chatRef, {
						lastMessage: {
							id: previousMessage.id,
							content: previousMessage.content,
							senderId: previousMessage.senderId,
							type: previousMessage.type,
							createdAt: previousMessage.createdAt,
							seenBy: previousMessage.seenBy,
						},
						updatedAt: serverTimestamp(),
					});
				} else {
					// If no previous message, remove lastMessage
					await updateDoc(chatRef, {
						lastMessage: null,
						updatedAt: serverTimestamp(),
					});
				}
			}
		}
	} catch (error) {
		console.error('Error deleting message:', error);
		throw error;
	}
}

async function getPreviousMessage2(chatId: string) {
	const messagesRef = collection(db, 'messages');
	const q = query(
		messagesRef,
		where('chatId', '==', chatId),
		where('isDeletedForAll', '==', false),
		orderBy('createdAt', 'desc'),
		limit(1)
	);

	const snapshot = await getDocs(q);
	return snapshot.empty ? null : { id: snapshot.docs[0].id, ...snapshot.docs[0].data() };
}

export async function deleteMessageForAll({ messageId, chatId, userId }: DeleteMessageForAllParams): Promise<void> {
	try {
		const batch = writeBatch(db);

		// Get message and chat documents
		const [messageDoc, chatDoc] = await Promise.all([
			getDoc(doc(db, 'messages', messageId)),
			getDoc(doc(db, 'chats', chatId)),
		]);

		if (!messageDoc.exists() || !chatDoc.exists()) {
			throw new Error('Message or chat not found');
		}

		const messageData = messageDoc.data();
		const chatData = chatDoc.data();

		// Verify user is the message sender or chat owner/admin
		const isMessageSender = messageData.senderId === userId;
		const isOwnerOrAdmin = chatData.ownerId === userId || (await isChatAdmin(chatId, userId));

		if (!isMessageSender && !isOwnerOrAdmin) {
			throw new Error('Not authorized to delete this message');
		}

		// Delete or update the message
		const messageRef = doc(db, 'messages', messageId);
		batch.update(messageRef, {
			content: 'This message was deleted',
			deletedAt: serverTimestamp(),
			deletedBy: userId,
			isDeletedForAll: true,
			attachments: [], // Clear any attachments
			updatedAt: serverTimestamp(),
		});

		// If this was the last message, update the chat
		if (chatData.lastMessage?.id === messageId) {
			// Get the previous message
			const previousMessage = (await getPreviousMessage2(chatId)) as Message;

			if (previousMessage) {
				batch.update(doc(db, 'chats', chatId), {
					lastMessage: {
						id: previousMessage.id,
						content: previousMessage.content,
						senderId: previousMessage.senderId,
						type: previousMessage.type,
						createdAt: previousMessage.createdAt,
						seenBy: previousMessage.seenBy,
					},
					updatedAt: serverTimestamp(),
				});
			} else {
				batch.update(doc(db, 'chats', chatId), {
					lastMessage: null,
					updatedAt: serverTimestamp(),
				});
			}
		}

		// Add system message about deletion
		const systemMessageRef = doc(collection(db, 'messages'));
		batch.set(systemMessageRef, {
			id: systemMessageRef.id,
			chatId,
			type: 'system',
			content: `A message was deleted by ${isMessageSender ? 'the sender' : 'an admin'}`,
			senderId: userId,
			createdAt: serverTimestamp(),
			updatedAt: serverTimestamp(),
		});

		await batch.commit();
	} catch (error) {
		console.error('Error deleting message for all:', error);
		throw error;
	}
}

// Helper function to check if user is chat admin
async function isChatAdmin(chatId: string, userId: string): Promise<boolean> {
	const memberRef = doc(db, 'chatMembers', `${chatId}_${userId}`);
	const memberDoc = await getDoc(memberRef);

	if (!memberDoc.exists()) return false;

	const memberData = memberDoc.data();
	return memberData.role === 'admin' || memberData.role === 'owner';
}

export async function toggleMessageReaction({ messageId, emoji, userId, chatId }: ToggleReactionParams): Promise<void> {
	try {
		const messageRef = doc(db, 'messages', messageId);
		const messageDoc = await getDoc(messageRef);

		if (!messageDoc.exists()) {
			throw new Error('Message not found');
		}

		const messageData = messageDoc.data();
		const reactions = messageData.reactions || {};

		// Find all current reactions by the user
		const userReactions: { emoji: string; reaction: MessageReaction }[] = [];
		Object.entries(reactions).forEach(([emojiKey, emojiReactions]) => {
			(emojiReactions as MessageReaction[]).forEach((reaction: MessageReaction) => {
				if (reaction.userId === userId) {
					userReactions.push({ emoji: emojiKey, reaction });
				}
			});
		});

		// Check if user is clicking the same emoji they already reacted with
		const existingReaction = reactions[emoji]?.find((reaction: MessageReaction) => reaction.userId === userId);

		// If clicking same emoji, just remove it
		if (existingReaction) {
			await updateDoc(messageRef, {
				[`reactions.${emoji}`]: arrayRemove({
					userId,
					emoji,
					timestamp: existingReaction.timestamp,
				}),
				updatedAt: serverTimestamp(),
			});
			return;
		}

		// Create batch to handle multiple updates
		const batch = writeBatch(db);

		// Remove all existing reactions by the user
		userReactions.forEach(({ emoji: oldEmoji, reaction }) => {
			batch.update(messageRef, {
				[`reactions.${oldEmoji}`]: arrayRemove(reaction),
			});
		});

		// Add new reaction
		const now = Timestamp.now();
		batch.update(messageRef, {
			[`reactions.${emoji}`]: arrayUnion({
				userId,
				emoji,
				timestamp: now,
			}),
			updatedAt: serverTimestamp(),
		});

		await batch.commit();
	} catch (error) {
		console.error('Error toggling reaction:', error);
		throw error;
	}
}

// src/firebase/messages.ts
export async function searchMessages(chatId: string, searchTerm: string): Promise<Message[]> {
	try {
		const searchTermLower = searchTerm.toLowerCase().trim();
		const searchWords = searchTermLower.split(/\s+/);

		// Query for exact matches
		const exactMatchQuery = query(
			collection(db, 'messages'),
			where('chatId', '==', chatId),
			where('contentLower', '>=', searchTermLower),
			where('contentLower', '<=', searchTermLower + '\uf8ff'),
			orderBy('contentLower'),
			orderBy('createdAt', 'desc')
		);

		// Query for partial matches
		const partialMatchQuery = query(
			collection(db, 'messages'),
			where('chatId', '==', chatId),
			where('searchTerms', 'array-contains-any', searchWords),
			orderBy('createdAt', 'desc')
		);

		// Execute queries
		const [exactMatches, partialMatches] = await Promise.all([getDocs(exactMatchQuery), getDocs(partialMatchQuery)]);

		// Process and deduplicate results
		const seenIds = new Set<string>();
		const allResults: Message[] = [];

		// Add exact matches
		exactMatches.docs.forEach((doc) => {
			if (!seenIds.has(doc.id)) {
				seenIds.add(doc.id);
				allResults.push({ id: doc.id, ...doc.data() } as Message);
			}
		});

		// Add partial matches
		partialMatches.docs.forEach((doc) => {
			if (!seenIds.has(doc.id)) {
				seenIds.add(doc.id);
				allResults.push({ id: doc.id, ...doc.data() } as Message);
			}
		});

		// Sort by relevance and return
		return allResults.sort((a, b) => {
			const scoreA = calculateRelevanceScore(a, searchTermLower);
			const scoreB = calculateRelevanceScore(b, searchTermLower);

			if (scoreA !== scoreB) {
				return scoreB - scoreA;
			}
			return b.createdAt.toMillis() - a.createdAt.toMillis();
		});
	} catch (error) {
		console.error('Error searching messages:', error);
		throw error;
	}
}
