type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const Close = (props: Props) => {
	const { width = 40, height = 40, color = 'white', ...rest } = props;

	return (
		<svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				fill="#fff"
				fillRule="evenodd"
				d="M18.529 20.419 5.33 33.617l1.886 1.886 13.198-13.198 13.2 13.199 1.885-1.886-13.2-13.2L35.5 7.219l-1.885-1.885-13.2 13.2-13.2-13.2L5.329 7.22l13.2 13.2Z"
				clipRule="evenodd"
			/>
		</svg>
	);
};
export default Close;
