import clsx from 'clsx';

type Props = {
	title: string;
	className?: string;
};

const GradientText = (props: Props) => {
	const { title, className } = props;
	return (
		<h3
			className={clsx(
				'whitespace-nowrap bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] bg-clip-text font-poppins font-bold not-italic leading-6 text-transparent',
				className
			)}
		>
			{title}
		</h3>
	);
};

export default GradientText;
