import Hamburger from '@/components/icons/Hamburger';
import Location from '@/components/icons/Location';
import Emails from '@/components/icons/megamenu_account_office/Emails';
import Close from '@/components/icons/megamenu_home/Close';
import Phone from '@/components/icons/Phone';
import { menuItems, SocialMedia } from '@/constants/constants';
import { Transition } from '@headlessui/react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useEffect } from 'react';
import TextLogo from '../icons/TextLogo';

type Props = {
	isOpen: boolean;
	setIsOpen: (item: boolean) => void;
};

export default function NonLoggedInMenu(props: Props) {
	const t = useTranslations('footer');

	const col1 = menuItems(t).col1[0];
	const col2 = [menuItems(t).col1[1]];
	const col3 = menuItems(t).col1[2];

	const { isOpen, setIsOpen } = props;

	useEffect(() => {
		if (isOpen) {
			document.getElementsByTagName('body')[0].style.overflow = 'hidden';
		} else {
			document.getElementsByTagName('body')[0].style.overflow = 'auto';
		}
	}, [isOpen]);

	return (
		<div>
			<div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer">
				<Hamburger />
			</div>
			<Transition
				show={isOpen}
				enter="transition-all ease-in-out duration-500"
				enterFrom="transform -translate-y-full"
				enterTo="transform translate-y-0"
				leave="transition-all ease-in-out duration-500"
				leaveFrom="transform translate-y-0"
				leaveTo="transform -translate-y-full"
			>
				<div className="default-gradient absolute left-0 top-0 z-30 h-screen w-full overflow-y-auto overflow-x-hidden px-[30px] py-[30px]">
					<div className="relative mb-2 flex w-full justify-between border-b border-white/20 pb-4">
						<div className="w-[205px]">
							<TextLogo />
						</div>

						<div className="h-10 w-10 cursor-pointer" onClick={() => props.setIsOpen(false)}>
							<Close />
						</div>
					</div>

					<div className="relative mt-7 flex w-full flex-col max-md:gap-5 md:flex-row md:justify-around 2xl:justify-between 2xl:gap-[30px]">
						<div className="flex flex-col max-2xl:gap-5 2xl:w-[50%] 2xl:flex-row 2xl:justify-between">
							<div className="flex h-auto flex-col space-y-[20px]">
								<Link href="/" onClick={() => props.setIsOpen(false)}>
									<h4 className="font-prompt text-3xl font-[900] uppercase text-white">{t('home')}</h4>
								</Link>
								<div className="flex h-auto flex-col justify-center space-y-[20px]">
									<div className="font-prompt text-3xl font-[900] uppercase text-white">{col1.header}</div>
									{col1.links.map((item: any, index: any) => {
										return (
											<Link
												onClick={() => props.setIsOpen(false)}
												href={item.href}
												key={index}
												className="flex w-full items-center gap-[5px]"
											>
												{item.icon}
												<p className="font-poppins text-xl font-normal leading-[20px] text-white">{item.name}</p>
											</Link>
										);
									})}
								</div>
							</div>

							<div className="flex h-auto flex-col space-y-[20px]">
								{col2.map((group, index) => {
									return (
										<div key={index} className="flex h-auto flex-col justify-center space-y-[20px]">
											<div className="font-prompt text-3xl font-[900] uppercase text-white">{group.header}</div>
											{group.links.map((item: any, index: any) => {
												return (
													<Link
														onClick={() => props.setIsOpen(false)}
														href={item.href}
														key={index}
														className="flex w-full items-center gap-[5px]"
													>
														{item.icon}
														<p className="font-poppins text-xl font-normal leading-[20px] text-white">{item.name}</p>
													</Link>
												);
											})}
										</div>
									);
								})}
								<div className="font-prompt text-3xl font-[900] uppercase text-white">{col3.header}</div>
								{col3.links.map((item: any, index: any) => {
									return (
										<Link
											onClick={() => props.setIsOpen(false)}
											href={item.href}
											key={index}
											className="flex w-full items-center gap-[5px]"
										>
											{item.icon}
											<p className="font-poppins text-xl font-normal leading-[20px] text-white">{item.name}</p>
										</Link>
									);
								})}
							</div>
						</div>
						<div className="flex h-auto flex-col space-y-[20px] 2xl:w-[30%]">
							<div className="flex flex-col space-y-[20px]">
								<div className="font-prompt text-3xl font-[900] uppercase text-white">{t('letsConnect')}</div>
								<div className="max-w-[343px]">
									<p className="font-poppins text-[14px] font-normal leading-[22px] text-white">
										{t('proudly')}, {t('firstOfItsKind')},
										<br /> {t('aioDigitalPlatform')}
									</p>
								</div>
							</div>

							<div className="border-y border-white">
								<div className="flex w-full flex-col space-y-[12px] py-[12px]">
									<div className="font-poppins text-[18px] font-extrabold text-white">{t('asiaHeadOffice')}</div>
									<a href="mailto:info@myvverse.com" className="flex h-4 w-full items-center gap-[5px]">
										<Emails />
										<p className="font-poppins text-[14px] font-medium text-white">info@myvverse.com</p>
									</a>
									<a href="tel:+971527956665" className="flex h-4 w-full items-center gap-[5px]">
										<Phone />
										<p className="font-poppins text-[14px] font-medium text-white">+971 52 7956665</p>
									</a>
									<div className="flex h-4 w-full items-center gap-[5px]">
										<Location />
										<p className="font-poppins text-[14px] font-medium text-white">{t('virtuallyManagedFromUAE')}</p>
									</div>
								</div>
								<div className="flex w-full flex-col space-y-[12px] py-[12px]">
									<div className="font-poppins text-[18px] font-extrabold text-white">{t('africaHeadOffice')}</div>
									<a href="mailto:info@myvverse.com" className="flex h-4 w-full items-center gap-[5px]">
										<Emails />
										<p className="font-poppins text-[14px] font-medium text-white">info@myvverse.com</p>
									</a>
									<a href="tel:+971527956665" className="flex h-4 w-full items-center gap-[5px]">
										<Phone />
										<p className="font-poppins text-[14px] font-medium text-white">+20 1008982000</p>
									</a>
									<div className="flex h-4 w-full items-center gap-[5px]">
										<Location />
										<p className="font-poppins text-[14px] font-medium text-white">{t('virtuallyManagedFromEgypt')}</p>
									</div>
								</div>
							</div>

							<div className="!mt-3 font-poppins text-[18px] font-extrabold text-white">{t('vvArabicSM')}</div>
							<div className="!mt-3 flex items-center">
								{SocialMedia.map((item, index) => {
									return (
										<a
											key={item.name}
											href={item.href}
											target="_blank"
											className="border-l border-white px-[10px] first:border-none"
										>
											{item.icon}
										</a>
									);
								})}
							</div>
							<div className="!mt-3 font-poppins text-[18px] font-extrabold text-white">{t('vvEnglishSM')}</div>
							<div className="!mt-3 flex items-center">
								{SocialMedia.map((item, index) => {
									return (
										<a
											key={item.name}
											href={item.href}
											target="_blank"
											className="border-l border-white px-[10px] first:border-none"
										>
											{item.icon}
										</a>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</Transition>
		</div>
	);
}
