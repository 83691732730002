import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Person = (props: SVGProps<SVGSVGElement>) => (
	<svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_8452_536990)">
			<path
				d="M16.6945 17.9039C15.6835 17.2869 15.3207 17.349 15.3207 16.1162C15.3207 15.6847 15.3207 14.883 15.3207 14.1434C15.8756 13.5272 16.0774 13.6039 16.5467 11.1846C17.4955 11.1846 17.4341 9.95176 17.8816 8.85821C18.2464 7.96768 17.9408 7.60665 17.6336 7.45909C17.8847 4.21387 17.8847 1.97881 14.2013 1.28545C12.8662 0.141069 10.8515 0.529594 9.89675 0.720561C8.94285 0.911528 8.08235 0.720561 8.08235 0.720561L8.24622 2.25816C6.84934 3.93453 7.54788 6.16958 7.32298 7.47867C7.02991 7.63833 6.76555 8.00645 7.11453 8.85821C7.56204 9.95176 7.50056 11.1846 8.44949 11.1846C8.91873 13.6039 9.12048 13.5272 9.67542 14.1434C9.67542 14.883 9.67542 15.6847 9.67542 16.1162C9.67542 17.349 9.25642 17.3703 8.30173 17.9039C5.99998 19.1905 3.00051 19.938 2.46311 23.9591C2.36467 24.6945 6.58753 25.4975 12.4981 25.5C18.4086 25.4975 22.6313 24.6945 22.5331 23.9591C21.9957 19.938 18.9687 19.2914 16.6945 17.9039Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_8452_536990">
				<rect width="25" height="25" fill="white" transform="translate(0 0.5)" />
			</clipPath>
		</defs>
	</svg>
);
export default Person;
