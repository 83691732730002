'use client';
import '@/app/vv-demo/videoPlayer.css';
import AdLabel from './icons/collectives/PersonalProfile/adLabel';

import '@vidstack/react/player/styles/default/layouts/audio.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import '@vidstack/react/player/styles/default/theme.css';

import SorryModule from '@/components/SorryModule';
import { MediaPlayer, MediaProvider, Poster } from '@vidstack/react';
import { DefaultVideoLayout, defaultLayoutIcons } from '@vidstack/react/player/layouts/default';
import { useEffect, useRef } from 'react';

type Props = {
	sizeClass?: string;
	sliderHeight?: string;
	videoSliderLength?: string;
	audioSlider?: string;
	iconSize?: number;
	iconButtonSize?: string;
	controlPadding?: string;
	controlGap?: string;
	videos?: {
		title: string | undefined;
		videoUrl: string;
		thumbnailUrl: string;
	}[];
	video?: {
		id: number;
		attachment_type_enum: string;
		file_size: number;
		file_name: string;
		file_extension_enum: string;
		file_path: string;
		url: string;
		module_enum: string;
	};
	showThumbnail?: boolean;
	isAd?: boolean;
	adTimer?: boolean;
	mediaPlayerClass?: string;
	isWidth?: boolean;
	aspectVideo?: string;
};

const VideoPlayer = (props: Props) => {
	const {
		sizeClass,
		videos = [],
		showThumbnail = true,
		isAd = false,
		adTimer = false,
		mediaPlayerClass,
		isWidth,
		video,
	} = props;
	const playerRef = useRef<React.ElementRef<typeof MediaPlayer>>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const currentVideo = videos[0];
	useEffect(() => {
		if (!currentVideo && !video) return;

		const player = playerRef.current;
		if (!player) return;

		// const handleTimeUpdate = () => setProgress((player.currentTime / player.duration) * 100);
		// const handleLoadedMetadata = () => setDuration(player.duration);
		// const handleVolumeChange = () => {
		// 	setVolume(player.volume);
		// 	setIsMute(player.muted);
		// };

		// player.addEventListener('timeupdate', handleTimeUpdate);
		// player.addEventListener('loadedmetadata', handleLoadedMetadata);
		// player.addEventListener('volumechange', handleVolumeChange);

		// return () => {
		// 	player.removeEventListener('timeupdate', handleTimeUpdate);
		// 	player.removeEventListener('loadedmetadata', handleLoadedMetadata);
		// 	player.removeEventListener('volumechange', handleVolumeChange);
		// };
	}, [video, currentVideo]);

	// Ensure video element is present before rendering
	if (!currentVideo && !video) {
		return (
			<div className="text-dark flex items-center justify-center">
				<SorryModule />
			</div>
		);
	}

	return (
		<div
			ref={containerRef}
			className={`relative md:aspect-video ${sizeClass} overflow-hidden rounded-lg`}
			style={{ width: isWidth ? '100%' : '' }}
		>
			{/* Video player component */}
			<MediaPlayer
				ref={playerRef}
				src={video?.url || currentVideo.videoUrl}
				poster={showThumbnail ? currentVideo.thumbnailUrl : ''}
				viewType="video"
				streamType="on-demand"
				playsInline
				title={video?.file_name || currentVideo.title}
				className={mediaPlayerClass}
			>
				<MediaProvider>
					<Poster className="vds-poster" />
				</MediaProvider>
				<DefaultVideoLayout thumbnails={showThumbnail ? currentVideo.thumbnailUrl : ''} icons={defaultLayoutIcons} />
			</MediaPlayer>

			{/* Display ad label if `isAd` is true */}
			{isAd && (
				<div className="absolute right-[7px] top-[5px]">
					<AdLabel />
				</div>
			)}

			{/* Display ad timer if `adTimer` is true */}
			{adTimer && (
				<div className="absolute left-[7px] top-[7px] h-[18px] rounded-full bg-gradient-to-r from-[#7F00FF] to-[#5200A5] px-[10px] py-[2.5px] text-[10px] font-semibold text-white">
					00:30/01:00
				</div>
			)}
		</div>
	);
};

export default VideoPlayer;
