'use client';

import Modal from '@/components/Modal';
import { useTranslations } from 'next-intl';
import Channels from '../icons/Channels';
import Person from '../icons/collectives/Person';
import Consultations from '../icons/Consultations';
import Courses from '../icons/Courses';
import Entity from '../icons/Entity';
import Events from '../icons/Events';
import Jobs from '../icons/Jobs';
import Magnify from '../icons/Magnify';
import Projects from '../icons/Projects';
import Services from '../icons/Services';
import SocialGroups from '../icons/SocialGroups';
import SocialPages from '../icons/SocialPages';
import Sponsorship from '../icons/Sponsorship';
import Stores from '../icons/Stores';
import VCandidate from '../icons/VCandidate';
import Blog from '../icons/VV_Assets/Blog';
import DigitalProd from '../icons/VV_Assets/DigitalProd';
import MenuEpisodes from '../icons/VV_Assets/MenuEpisodes';
import MenuPodcast from '../icons/VV_Assets/MenuPodcasts';
import PhysicalProd from '../icons/VV_Assets/PhysicalProd';
import Shorts from '../icons/VV_Assets/Shorts';
import Videos from '../icons/VV_Assets/Videos';
import VBlogAuthor from '../icons/VV_Profiles/VBlogAuthor';
import VCastAuthor from '../icons/VV_Profiles/VCastAuthor';
import VClient from '../icons/VV_Profiles/VClient';
import VEmployer from '../icons/VV_Profiles/VEmployer';
import VExpert from '../icons/VV_Profiles/VExpert';
import VHosts from '../icons/VV_Profiles/VHosts';
import VInfluencer from '../icons/VV_Profiles/VInfluencer';
import VInstructor from '../icons/VV_Profiles/VInstructor';
import VLancer from '../icons/VV_Profiles/VLancer';
import VNationAuthor from '../icons/VV_Profiles/VNationAuthor';
import VOrganizer from '../icons/VV_Profiles/VOrganizer';
import VSeller from '../icons/VV_Profiles/VSeller';
import VTubeAuthor from '../icons/VV_Profiles/VTubeAuthor';
import Webinars from '../icons/Webinars';
import ModalCard from '../ModalCard';

type Props = {
	isOpen: boolean;
	setIsOpen: (item: boolean) => void;
};
export default function VVSearch(props: Props) {
	const t = useTranslations('header');
	const { isOpen = false, setIsOpen } = props;
	const navItems = {
		Profiles: [
			{
				title: t('personalProfilesTitle'),
				icon: <Person />,
				description: t('personalProfilesDescription'),
				href: '/vv-search/personal-profile',
			},
			{
				title: t('entityProfilesTitle'),
				icon: <Entity />,
				description: t('entityProfilesDescription'),
				href: '/vv-search/entity-profile',
			},
			{
				title: t('vBlogAuthorsTitle'),
				icon: <VBlogAuthor width={24} height={24} />,
				description: t('vBlogAuthorsDescription'),
				href: '/vv-search/v-blog-author',
			},
			{
				title: t('vNationAuthorsTitle'),
				icon: <VNationAuthor width={24} height={24} />,
				description: t('vNationAuthorsDescription'),
				href: '/vv-search/v-nation-author',
			},
			{
				title: t('vTubeAuthorsTitle'),
				icon: <VTubeAuthor width={24} height={24} />,
				description: t('vTubeAuthorsDescription'),
				href: '/vv-search/v-tube-author',
			},
			{
				title: t('vEmployersTitle'),
				icon: <VEmployer width={24} height={24} />,
				description: t('vEmployersDescription'),
				href: '/vv-search/v-employer',
			},
			{
				title: t('vCandidatesTitle'),
				icon: <VCandidate width={24} height={24} />,
				description: t('vCandidatesDescription'),
				href: '/vv-search/v-candidate',
			},
			{
				title: t('vLancersTitle'),
				icon: <VLancer width={24} height={24} />,
				description: t('vLancersDescription'),
				href: '/vv-search/v-lancers',
			},
			{
				title: t('vClientsTitle'),
				icon: <VClient width={24} height={24} />,
				description: t('vClientsDescription'),
				href: '/vv-search/v-clients',
			},
			{
				title: t('vInstructorsTitle'),
				icon: <VInstructor />,
				description: t('vInstructorsDescription'),
				href: '/vv-search/v-instructors',
			},
			{
				title: t('vSellersTitle'),
				icon: <VSeller width={24} height={24} />,
				description: t('vSellersDescription'),
				href: '/vv-search/v-sellers',
			},
			{
				title: t('vOrganizersTitle'),
				icon: <VOrganizer width={24} height={24} />,
				description: t('vOrganizersDescription'),
				href: '/vv-search/organizers',
			},
			{
				title: t('vHostsTitle'),
				icon: <VHosts width={24} height={24} />,
				description: t('vHostsDescription'),
				href: '/vv-search/v-hosts',
			},
			{
				title: t('vExpertsTitle'),
				icon: <VExpert width={24} height={24} />,
				description: t('vExpertsDescription'),
				href: '/vv-search/v-experts',
			},
			{
				title: t('vInfluencersTitle'),
				icon: <VInfluencer width={24} height={24} />,
				description: t('vInfluencersDescription'),
				href: '/vv-search/v-influencers',
			},
			{
				title: t('vCastAuthorsTitle'),
				icon: <VCastAuthor width={24} height={24} />,
				description: t('vCastAuthorsDescription'),
				href: '/vv-search/v-podcasts-author',
			},
			{
				title: t('vBookAuthorsTitle'),
				icon: <VInfluencer width={24} height={24} />,
				description: t('vBookAuthorsDescription'),
				href: '/vv-search/v-author',
			},
		],
		Assets: [
			{
				title: t('blogPostsTitle'),
				icon: <Blog width={24} height={24} />,
				description: t('blogPostsDescription'),
				href: '/vv-search/posts',
			},
			{
				title: t('socialPagesTitle'),
				icon: <SocialPages />,
				description: t('socialPagesDescription'),
				href: '/vv-search/social-page',
			},
			{
				title: t('socialGroupsTitle'),
				icon: <SocialGroups />,
				description: t('socialGroupsDescription'),
				href: '/vv-search/social-group',
			},
			{
				title: t('channelsTitle'),
				icon: <Channels />,
				description: t('channelsDescription'),
				href: '/vv-search/channels',
			},
			{
				title: t('videosTitle'),
				icon: <Videos width={24} height={24} />,
				description: t('videosDescription'),
				href: '/vv-search/videos',
			},
			{
				title: t('shortsTitle'),
				icon: <Shorts width={24} height={24} />,
				description: t('shortsDescription'),
				href: '/vv-search/shorts',
			},
			{
				title: t('jobsTitle'),
				icon: <Jobs />,
				description: t('jobsDescription'),
				href: '/vv-search/jobs',
			},
			{
				title: t('servicesTitle'),
				icon: <Services />,
				description: t('servicesDescription'),
				href: '/vv-search/services',
			},
			{
				title: t('projectsTitle'),
				icon: <Projects />,
				description: t('projectsDescription'),
				href: '/vv-search/projects',
			},
			{
				title: t('coursesTitle'),
				icon: <Courses />,
				description: t('coursesDescription'),
				href: '/vv-search/courses',
			},
			{
				title: t('storesTitle'),
				icon: <Stores />,
				description: t('storesDescription'),
				href: '/vv-search/stores',
			},
			{
				title: t('physicalProductsTitle'),
				icon: <PhysicalProd width={24} height={24} />,
				description: t('physicalProductsDescription'),
				href: '/vv-search/physical-products',
			},
			{
				title: t('digitalProductsTitle'),
				icon: <DigitalProd width={24} height={24} />,
				description: t('digitalProductsDescription'),
				href: '/vv-search/digital-products',
			},
			{
				title: t('eventsTitle'),
				icon: <Events />,
				description: t('eventsDescription'),
				href: '/vv-search/v-events',
			},
			{
				title: t('webinarsTitle'),
				icon: <Webinars />,
				description: t('webinarsDescription'),
				href: '/vv-search/webinars',
			},
			{
				title: t('consultationsTitle'),
				icon: <Consultations />,
				description: t('consultationsDescription'),
				href: '/vv-search/consultations',
			},
			{
				title: t('sponsorshipsTitle'),
				icon: <Sponsorship />,
				description: t('sponsorshipsDescription'),
				href: '/vv-search/sponsorships',
			},
			{
				title: t('podcastsTitle'),
				icon: <MenuPodcast />,
				description: t('podcastsDescription'),
				href: '/vv-search/podcasts',
			},
			{
				title: t('episodesTitle'),
				icon: <MenuEpisodes />,
				description: t('episodesDescription'),
				href: '/vv-search/episodes',
			},
			{
				title: t('booksTitle'),
				icon: <VInfluencer width={24} height={24} />,
				description: t('booksDescription'),
				href: '/vv-search/books',
			},
		],
	};

	return (
		<Modal
			heading={t('vVerseSearch')}
			icon={<Magnify />}
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			width="min-w-full"
			bodyClass={'!max-h-[calc(100vh-57px)]'}
			customClass="w-full h-full overflow-auto rounded-none bg-white duration-300 ease-out"
		>
			<div className="grid grid-cols-1 gap-[16px] p-2.5">
				<h2 className="font-poppins text-[25px] font-extrabold capitalize not-italic leading-[30px] text-[#016DEA]">
					{t('imLookingFor')}
				</h2>

				<div className="grid grid-cols-1 gap-x-[30px] gap-y-[15px] md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
					{navItems &&
						navItems.Profiles.map((navItem, index) => (
							<ModalCard
								key={index}
								icon={navItem.icon}
								title={navItem.title}
								description={navItem.description}
								href={navItem.href}
								handleClick={() => setIsOpen(false)}
							/>
						))}
				</div>
				<hr className="hr-custom" />
				<div className="grid grid-cols-1 gap-x-[30px] gap-y-[15px] md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
					{navItems &&
						navItems.Assets.map((navItem, index) => (
							<ModalCard
								key={index}
								icon={navItem.icon}
								title={navItem.title}
								description={navItem.description}
								href={navItem.href}
								handleClick={() => setIsOpen(false)}
							/>
						))}
				</div>
			</div>
		</Modal>
	);
}
