import { BlockUserResult, Chat, ChatUser, SearchUsersResult, UserType } from '@/utils/types';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import {
	arrayRemove,
	arrayUnion,
	collection,
	doc,
	getDocs,
	onSnapshot,
	query,
	serverTimestamp,
	setDoc,
	where,
	writeBatch,
} from 'firebase/firestore';
import { auth, db } from './firebase';

export async function searchUsers2(users: UserType[], currentGroupMembers: string[]): Promise<SearchUsersResult> {
	try {
		if (!users) {
			return {
				users: [],
			};
		}

		// Filter out users who are already in the group
		const usersResults = users
			?.map((user) => ({ ...user }) as UserType)
			?.filter((user) => !currentGroupMembers.includes(user.id));

		return {
			users: usersResults,
		};
	} catch (error) {
		console.error('Error searching users:', error);
		throw error;
	}
}

export async function blockUserFn(currentUserId: string, userToBlockId: string): Promise<BlockUserResult> {
	try {
		const batch = writeBatch(db);

		const currentUserQuery = query(collection(db, 'users'), where('id', '==', currentUserId));

		// Query to fetch the user to block
		const userToBlockQuery = query(collection(db, 'users'), where('id', '==', userToBlockId));

		// Fetch both queries in parallel
		const [currentUserDoc, userToBlockDoc] = await Promise.all([getDocs(currentUserQuery), getDocs(userToBlockQuery)]);

		if (currentUserDoc.empty || userToBlockDoc.empty) {
			throw new Error('One or both users not found');
		}

		// Update current user's blockedUsers array
		batch.update(currentUserDoc.docs[0].ref, {
			blockedUsers: arrayUnion(userToBlockId),
			updatedAt: serverTimestamp(),
		});

		// Update blocked user's blockedBy array
		batch.update(userToBlockDoc.docs[0].ref, {
			blockedBy: arrayUnion(currentUserId),
			updatedAt: serverTimestamp(),
		});

		// Find existing direct chat between users if any
		const chatQuery = query(
			collection(db, 'chats'),
			where('type', '==', 'direct'),
			where('participantIds', 'array-contains', currentUserId)
		);

		const chatSnapshot = await getDocs(chatQuery);
		let existingChatId: string | undefined;

		chatSnapshot.forEach((doc) => {
			const chat = doc.data() as Chat;
			if (chat.participantIds.includes(userToBlockId)) {
				existingChatId = doc.id;
			}
		});

		// If there's an existing chat, update it
		if (existingChatId) {
			const chatRef = doc(db, 'chats', existingChatId);
			batch.update(chatRef, {
				isBlocked: true,
				blockedBy: currentUserId,
				updatedAt: serverTimestamp(),
			});
		}

		await batch.commit();

		return {
			success: true,
			chatId: existingChatId,
		};
	} catch (error) {
		console.error('Error blocking user:', error);
		throw error;
	}
}

export async function unblockUserFn(currentUserId: string, userToUnblockId: string): Promise<void> {
	try {
		const batch = writeBatch(db);

		const currentUserQuery = query(collection(db, 'users'), where('id', '==', currentUserId));

		// Query to fetch the user to block
		const userToUnBlockQuery = query(collection(db, 'users'), where('id', '==', userToUnblockId));

		// Fetch both queries in parallel
		const [currentUserDoc, userToUnBlockDoc] = await Promise.all([
			getDocs(currentUserQuery),
			getDocs(userToUnBlockQuery),
		]);

		// Update current user's blockedUsers array
		batch.update(currentUserDoc.docs[0].ref, {
			blockedUsers: arrayRemove(userToUnblockId),
			updatedAt: serverTimestamp(),
		});

		// Update unblocked user's blockedBy array
		batch.update(userToUnBlockDoc.docs[0].ref, {
			blockedBy: arrayRemove(currentUserId),
			updatedAt: serverTimestamp(),
		});

		// Find and update existing chat if any
		const chatQuery = query(
			collection(db, 'chats'),
			where('type', '==', 'direct'),
			where('participantIds', 'array-contains', currentUserId)
		);

		const chatSnapshot = await getDocs(chatQuery);
		chatSnapshot.forEach((doc) => {
			const chat = doc.data() as Chat;
			if (chat.participantIds.includes(userToUnblockId)) {
				batch.update(doc.ref, {
					isBlocked: false,
					blockedBy: null,
					updatedAt: serverTimestamp(),
				});
			}
		});

		await batch.commit();
	} catch (error) {
		console.error('Error unblocking user:', error);
		throw error;
	}
}

export async function getUserById(userId: string | number): Promise<ChatUser | null> {
	try {
		// Query Firestore to find the user by ID
		const userQuery = query(collection(db, 'users'), where('id', '==', userId));
		const querySnapshot = await getDocs(userQuery);

		if (!querySnapshot.empty) {
			// Assuming the user ID is unique and only one document is returned
			const userDoc = querySnapshot.docs[0];
			return { id: userDoc.id, ...userDoc.data() } as ChatUser;
		} else {
			console.error('User not found');
			return null;
		}
	} catch (error) {
		console.error('Error getting user by ID:', error);
		throw error;
	}
}

export function subscribeToUser(
	userId: string,
	callback: (user: ChatUser | null) => void,
	onError?: (error: Error) => void
): () => void {
	const userRef = doc(db, 'users', userId);

	return onSnapshot(
		userRef,
		(doc) => {
			if (doc.exists()) {
				callback({ id: doc.id, ...doc.data() } as ChatUser);
			} else {
				callback(null);
			}
		},
		(error) => {
			console.error('Error in user subscription:', error);
			onError?.(error);
		}
	);
}

export const createUser = async (user: UserType) => {
	try {
		// Query Firestore to check if the user already exists
		const userQuery = query(collection(db, 'users'), where('id', '==', user.id));
		const querySnapshot = await getDocs(userQuery);

		if (!querySnapshot.empty) {
			return; // Exit the function if the user exists
		}

		try {
			const email = user.email?.trim();
			if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
				throw new Error('Invalid email format');
			}

			const res = await createUserWithEmailAndPassword(auth, email, '123456');
			// Add user details to Firestore
			await setDoc(doc(db, 'users', res.user.uid), {
				id: user.id,
				displayName: user.full_name,
				email: user.email,
				photoURL: user.image,
			});
		} catch (error) {
			console.error('Error creating user:', error);
		}
	} catch (error) {
		console.error('Error creating user:', (error as Error).message);
	}
};

// Change user data
export async function updateUser(userId: number, updatedData: Partial<ChatUser>): Promise<void> {
	try {
		console.log('userId', userId);
		console.log('updated data', updatedData);

		const userQuery = query(collection(db, 'users'), where('id', '==', userId));
		const querySnapshot = await getDocs(userQuery);

		if (!querySnapshot.empty) {
			const userDoc = querySnapshot.docs[0];
			await setDoc(
				userDoc.ref,
				{
					...userDoc.data(),
					...updatedData,
					updatedAt: serverTimestamp(),
				},
				{ merge: true }
			);
		} else {
			throw new Error('User not found');
		}
	} catch (error) {
		console.error('Error updating user:', (error as Error).message);
		throw error;
	}
}
