import { MessageTypeChat } from '../types';

export function extractUrls(text: string): string[] {
	// Regular expression pattern to match URLs
	const urlPattern =
		/(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[0-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z0-9\\-]+\.)+[a-zA-Z]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?/g;
	// Find all URLs in the text
	const urls = text.match(urlPattern) || [];
	return urls;
}

export function getFileType(fileExtension: string | undefined): MessageTypeChat {
	if (!fileExtension) return 'document';

	const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
	const videoTypes = ['mp4', 'mov', 'avi', 'webm'];
	const audioTypes = ['mp3', 'wav', 'ogg', 'm4a'];
	const linkTypes = ['url'];

	const ext = fileExtension.toLowerCase();

	if (imageTypes.includes(ext)) return 'image';
	if (videoTypes.includes(ext)) return 'video';
	if (audioTypes.includes(ext)) return 'audio';
	if (linkTypes.includes(ext)) return 'link';
	return 'document';
}

// Helper function to chunk array for batch operations
export function chunkArray<T>(array: T[], size: number): T[][] {
	const chunked: T[][] = [];
	for (let i = 0; i < array.length; i += size) {
		chunked.push(array.slice(i, i + size));
	}
	return chunked;
}
