import { useAppSelector } from '@/lib/hooks';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import Button from './Button';
import AdLabel from './icons/collectives/PersonalProfile/adLabel';

type AvailableAdSpotProps = {
	sidebar?: boolean;
	firstOrLast?: boolean;
};

const AvailableAdSpot = ({ sidebar = false, firstOrLast = false }: AvailableAdSpotProps) => {
	const commonT = useTranslations('common');
	const router = useRouter();
	const user = useAppSelector((state) => state.auth.user);

	return (
		<div className={`w-full px-[0px] lg:px-[13px] ${!sidebar && 'pb-[15px]'} mt-5 lg:mt-0`}>
			<div
				className={`mx-auto flex flex-col items-center justify-between rounded-lg border bg-cover bg-center p-[15px] shadow ${sidebar ? (firstOrLast ? 'h-[230px] xl:w-[320px] 2xl:w-[345px]' : 'h-[600px] xl:w-[320px] 2xl:w-[345px]') : 'h-[330px] w-full'} relative`}
				style={{ backgroundImage: sidebar ? 'url(/images/V-guide-Ad-1.jpeg)' : 'url(/images/ad.png)' }}
			>
				<div className="absolute end-2 top-2">
					<AdLabel />
				</div>
				{/* <p className="gradient-text text-center text-[18px] font-[800]">This ad spot is available for renting</p> */}

				{!sidebar && user.id && (
					<Button
						customClass="mx-auto"
						innerCustomClass="!py-2"
						title={commonT('buttons.rentAdSlot')}
						onClick={() => router.push('/my-office/ads-manager')}
					/>
				)}
			</div>
			{sidebar && user.id && (
				<div className="pt-2">
					<Button
						customClass="mx-auto"
						innerCustomClass="!py-2"
						title={commonT('buttons.rentAdSlot')}
						onClick={() => router.push('/my-office/ads-manager')}
					/>
				</div>
			)}
		</div>
	);
};

export default AvailableAdSpot;
