import { useLocale, useTranslations } from 'next-intl';
import LocaleSwitcherSelect from './LocaleSwitcherSelect';

export default function LocaleSwitcher() {
	const t = useTranslations('localeSwitcher');
	const locale = useLocale();

	return (
		<LocaleSwitcherSelect
			locale={locale}
			items={[
				{
					id: 'en',
					value: 'en',
					label: t('en'),
				},
				{
					id: 'ar',
					value: 'ar',
					label: t('ar'),
				},
			]}
			label={t('label')}
		/>
	);
}
