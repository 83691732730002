import { SVGProps, useEffect, useState } from 'react';

type Props = {
	status?: boolean;
	disable?: boolean;
	color?: string;
};
const AudioStatus = (props: Props & SVGProps<SVGSVGElement>) => {
	const [isHovered, setIsHovered] = useState(false);
	const [isClicked, setIsClicked] = useState(false);

	useEffect(() => {
		setIsClicked(props.status || false);
		if (!props.disable) setIsHovered(props.status || false);
	}, [props.status]);

	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			onMouseEnter={() => !isClicked && !props.disable && setIsHovered(true)}
			onMouseLeave={() => !isClicked && !props.disable && setIsHovered(false)}
			{...props}
		>
			<rect
				x="0.5"
				y="0.5"
				width="39"
				height="39"
				rx="19.5"
				fill={isHovered ? 'url(#paint1_linear_8365_377434)' : props.color || 'white'}
			/>
			<rect
				x="0.5"
				y="0.5"
				width="39"
				height="39"
				rx="19.5"
				stroke={isHovered ? props.color || 'white' : 'url(#paint0_linear_8365_377434)'}
			/>
			<path
				d="M20 9C19.2044 9 18.4413 9.31607 17.8787 9.87868C17.3161 10.4413 17 11.2044 17 12V20C17 20.7956 17.3161 21.5587 17.8787 22.1213C18.4413 22.6839 19.2044 23 20 23C20.7956 23 21.5587 22.6839 22.1213 22.1213C22.6839 21.5587 23 20.7956 23 20V12C23 11.2044 22.6839 10.4413 22.1213 9.87868C21.5587 9.31607 20.7956 9 20 9Z"
				stroke={isHovered ? props.color || 'white' : 'url(#paint0_linear_8365_377434)'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M27 18V20C27 21.8565 26.2625 23.637 24.9497 24.9497C23.637 26.2625 21.8565 27 20 27C18.1435 27 16.363 26.2625 15.0503 24.9497C13.7375 23.637 13 21.8565 13 20V18"
				stroke={isHovered ? props.color || 'white' : 'url(#paint0_linear_8365_377434)'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20 27V31"
				stroke={isHovered ? props.color || 'white' : 'url(#paint0_linear_8365_377434)'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16 31H24"
				stroke={isHovered ? props.color || 'white' : 'url(#paint0_linear_8365_377434)'}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			{isClicked && (
				<path
					d="M8 8L32 32"
					stroke={props.color || 'white'}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			)}
			<defs>
				<linearGradient id="paint0_linear_8365_377434" x1="0" y1="20" x2="40" y2="20" gradientUnits="userSpaceOnUse">
					<stop stopColor="#FA0101" />
					<stop offset="1" stopColor="#016DEA" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_8365_377434"
					x1="17.3964"
					y1="9.19924"
					x2="36.7753"
					y2="19.2875"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#016DEA" />
					<stop offset="1" stopColor="#FA0101" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_8365_377434"
					x1="12.3618"
					y1="14.9969"
					x2="15.6188"
					y2="16.6975"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#016DEA" />
					<stop offset="1" stopColor="#FA0101" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_8365_377434"
					x1="17.544"
					y1="14.992"
					x2="20.7859"
					y2="16.6702"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#016DEA" />
					<stop offset="1" stopColor="#FA0101" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_8365_377434"
					x1="22.7544"
					y1="14.999"
					x2="26.0015"
					y2="16.6917"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#016DEA" />
					<stop offset="1" stopColor="#FA0101" />
				</linearGradient>
				<clipPath id="clip0_8365_377434">
					<rect
						width="24"
						height="24"
						fill={isHovered ? 'url(#paint1_linear_8365_377434)' : props.color || 'white'}
						transform="translate(8 8)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
export default AudioStatus;
