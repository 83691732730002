import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/home/myvverse/htdocs/myvverse.com/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/myvverse/htdocs/myvverse.com/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/myvverse/htdocs/myvverse.com/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/myvverse/htdocs/myvverse.com/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/myvverse/htdocs/myvverse.com/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/home/myvverse/htdocs/myvverse.com/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Pinyon_Script\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-pinyon-script\"}],\"variableName\":\"pinyonScript\"}");
;
import(/* webpackMode: "eager" */ "/home/myvverse/htdocs/myvverse.com/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Prompt\",\"arguments\":[{\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-prompt\"}],\"variableName\":\"prompt\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/myvverse/htdocs/myvverse.com/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ClientWrapper"] */ "/home/myvverse/htdocs/myvverse.com/src/app/ClientWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/home/myvverse/htdocs/myvverse.com/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/myvverse/htdocs/myvverse.com/src/app/Pusher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/myvverse/htdocs/myvverse.com/src/app/StoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/home/myvverse/htdocs/myvverse.com/src/components/call/AgoraProvidor.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/myvverse/htdocs/myvverse.com/src/components/LayoutWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/myvverse/htdocs/myvverse.com/src/components/RestrictedContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/myvverse/htdocs/myvverse.com/src/components/ui/SidebarForMobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/myvverse/htdocs/myvverse.com/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/myvverse/htdocs/myvverse.com/src/components/WelcomeModal.tsx");
