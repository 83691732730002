'use client';
import { NextUIProvider } from '@nextui-org/system';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persist, store } from '../lib/store';

export default function StoreProvider({ children, className = '' }: { children: React.ReactNode; className?: string }) {
	return (
		<NextUIProvider className={className}>
			<Provider store={store}>
				<PersistGate persistor={persist}>{children}</PersistGate>
			</Provider>
		</NextUIProvider>
	);
}
