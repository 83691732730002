'use client';

import { menuItems, menuItems2 } from '@/constants/constants';
import { useAppSelector } from '@/lib/hooks';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useLocale, useTranslations } from 'next-intl';
import Link from 'next/link';
import { useEffect } from 'react';
import Close from '../icons/Close';
import Hamburger from '../icons/Hamburger';
import TextLogo from '../icons/TextLogo';
import MenuAndFooterAds from '../MenuAndFooterAds';

type Props = {
	isOpen: boolean;
	setIsOpen: (item: boolean) => void;
	totalUnread: number | string;
};

const MegaMenu = (props: Props) => {
	const t = useTranslations('footer');
	const { counts } = useAppSelector((state) => state.counts);
	const { isOpen, setIsOpen, totalUnread } = props;
	const { user } = useAppSelector((state) => state.auth);
	const locale = useLocale();
	const isArabic = locale === 'ar';

	useEffect(() => {
		if (isOpen) {
			document.getElementsByTagName('body')[0].style.overflow = 'hidden';
		} else {
			document.getElementsByTagName('body')[0].style.overflow = 'auto';
		}
	}, [isOpen]);

	return (
		<div>
			<div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer">
				<Hamburger
					className={clsx({
						'scale-x-[-1]': isArabic,
					})}
				/>
			</div>
			<Transition
				show={isOpen}
				enter="transition-all ease-in-out duration-500"
				enterFrom="transform -translate-y-full"
				enterTo="transform translate-y-0"
				leave="transition-all ease-in-out duration-500"
				leaveFrom="transform translate-y-0"
				leaveTo="transform -translate-y-full"
			>
				<main className="fixed left-0 top-0 z-30 max-h-screen w-full overflow-y-auto bg-[linear-gradient(62deg,#016dea_27%,#fa0101_95%)] px-4 pt-4 sm:px-6 sm:pt-6 md:px-8 lg:min-h-screen">
					<div className="relative mb-4 flex w-full justify-between border-b border-white/20 pb-4 sm:mb-5 sm:pb-5">
						<TextLogo />
						<div className="h-8 w-8 cursor-pointer sm:h-10 sm:w-10" onClick={() => props.setIsOpen(false)}>
							<Close />
						</div>
					</div>

					<div className="flex flex-col justify-start gap-4 sm:gap-6 lg:flex-row lg:gap-8 xl:gap-10">
						{/* Section 1 */}
						<div className="relative border-b border-white/20 pb-4 lg:border-b-0 lg:border-r lg:pb-0 lg:pr-4 xl:pr-8">
							<Link href="/" onClick={() => props.setIsOpen(false)}>
								<h4 className="mb-4 text-lg font-extrabold text-white sm:text-xl">{t('home')}</h4>
							</Link>
							<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 md:grid-cols-3 lg:grid-cols-1">
								{menuItems(t, counts, totalUnread).col1.map((group, index) => (
									<div key={index} className="flex flex-col justify-start space-y-2 sm:space-y-3">
										<div className="text-base font-extrabold text-white sm:text-lg">{group.header}</div>
										<div className="flex flex-col">
											{group.links.map((item, index) => (
												<Link
													onClick={() => props.setIsOpen(false)}
													href={item.href}
													key={index}
													className="flex items-center gap-1 py-1"
												>
													{item.icon}
													<p className="font-semibold text-white">{item.name}</p>
												</Link>
											))}
										</div>
									</div>
								))}
							</div>
						</div>

						{/* Section 2 */}
						<div className="flex flex-col border-b border-white/20 pb-4 lg:border-b-0 lg:border-r lg:pb-0 lg:pr-4 xl:pr-8">
							<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 md:grid-cols-3 lg:grid-cols-1">
								{menuItems(t, counts, totalUnread).col2.map((group, index) => {
									if (group.header === 'My Account' && user?.profile_type !== 'personal') {
										return null;
									}
									return (
										<div key={index} className="flex flex-col justify-start space-y-2 sm:space-y-3">
											<div className="text-base font-extrabold text-white sm:text-lg">{group.header}</div>
											<div className="flex flex-col">
												{group.links.map((item, index) => (
													<Link
														onClick={() => props.setIsOpen(false)}
														href={item.href}
														key={index}
														className="flex items-center gap-2 py-1"
													>
														{item.icon}
														<p className="font-medium text-white">{item.name}</p>
														{item.notifs !== '' && (
															<div className="relative h-4 w-8">
																<div className="absolute inset-0 rounded bg-[linear-gradient(62deg,#016dea_27%,#fa0101_95%)] p-px">
																	<div className="flex h-full w-full items-center justify-center rounded bg-white">
																		<p className="bg-[linear-gradient(62deg,#016dea_27%,#fa0101_95%)] bg-clip-text text-[10px] font-semibold text-transparent">
																			{item.notifs}
																		</p>
																	</div>
																</div>
															</div>
														)}
													</Link>
												))}
											</div>
										</div>
									);
								})}
							</div>
						</div>

						{/* Section 3 */}
						<div className="flex flex-col space-y-4 lg:pl-4 xl:pl-8">
							<div className="grid grid-cols-2 gap-4 sm:gap-6 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5">
								{menuItems2(t)
									.flat()
									.map((group, index) => (
										<div key={index} className="flex flex-col space-y-2 sm:space-y-3">
											<div className="text-base font-extrabold text-white sm:text-lg">{group.header}</div>
											<div className="flex flex-col">
												{group.links.map((item, index) => (
													<Link
														onClick={() => props.setIsOpen(false)}
														href={item.href}
														key={index}
														className="flex items-center gap-1 py-1"
													>
														{item.icon}
														<p className="font-medium text-white">{item.name}</p>
													</Link>
												))}
											</div>
										</div>
									))}
							</div>
							<div className="w-full border-b border-white/20" />
							<div className="w-full max-w-screen-xl">
								<MenuAndFooterAds location="mega-menu" />
							</div>
						</div>
					</div>
				</main>
			</Transition>
		</div>
	);
};

export default MegaMenu;
