const exampleVideo: {
	title: string | undefined;
	videoUrl: string;
	thumbnailUrl: string;
} = {
	title: 'For Bigger Escape',
	videoUrl: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
	thumbnailUrl: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/ForBiggerEscapes.jpg',
};

const exampleVidoeData = [exampleVideo];

export default exampleVidoeData;
