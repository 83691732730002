import { Dialog, DialogPanel } from '@headlessui/react';
import { ReactNode } from 'react';
import XMark from './icons/XMark';
import LoginEllipse from './icons/modal/LoginEllipse';
import LoginSmEllipse from './icons/modal/LoginSmEllipse';
import LoginVVIcon from './icons/modal/LoginVVIcon';

type Props = {
	isOpen: boolean;
	children: ReactNode;
	onClose: () => void;
};

const LoginModal = (props: Props) => {
	const { isOpen, children, onClose } = props;
	return (
		<Dialog open={isOpen} as="div" className="relative z-50 focus:outline-none" onClose={onClose}>
			<div className="fixed inset-0 z-10 w-screen bg-black/60">
				<div className="flex min-h-full items-center justify-center">
					<DialogPanel
						transition
						className="relative flex h-[400px] w-[480px] overflow-hidden rounded-[15px] bg-white duration-300 ease-out"
					>
						<div className="absolute left-0 z-0 opacity-20">
							<LoginVVIcon />
						</div>
						{/* login-ellipse-animation  */}
						<LoginEllipse className="login-ellipse-animation absolute -bottom-4 -right-4 z-0" />
						<LoginSmEllipse className="login-small-ellipse-animation absolute bottom-6 right-5 z-0" />
						<div className="relative flex h-full w-full p-[20px]">
							<div className="absolute right-5 text-xl font-bold text-white">
								<button onClick={onClose} className="rounded-full border-[1px] border-[#b8b8b8] p-[8px]">
									<XMark color="black" />
								</button>
							</div>
							<div className="flex flex-1 flex-col items-center justify-center">{children}</div>
						</div>
					</DialogPanel>
				</div>
			</div>
		</Dialog>
	);
};

export default LoginModal;
