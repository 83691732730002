import { SVGProps, useEffect, useState } from 'react';

type Props = {
	status?: boolean;
	disable?: boolean;
	color?: string;
};
const VideoStatus = (props: Props & SVGProps<SVGSVGElement>) => {
	const [isHovered, setIsHovered] = useState(false);
	const [isClicked, setIsClicked] = useState(false);

	useEffect(() => {
		setIsClicked(props.status || false);
		if (!props.disable) setIsHovered(props.status || false);
	}, [props.status]);

	return (
		<>
			<div
				className={props.className}
				onMouseEnter={() => !isClicked && !props.disable && setIsHovered(true)}
				onMouseLeave={() => !isClicked && !props.disable && setIsHovered(false)}
			>
				{isClicked ? (
					<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
						<rect
							x="0.5"
							y="0.5"
							width="39"
							height="39"
							rx="19.5"
							fill={isHovered ? 'url(#paint1_linear_8365_377434)' : props.color || 'white'}
						/>
						<rect
							x="0.5"
							y="0.5"
							width="39"
							height="39"
							rx="19.5"
							stroke={isHovered ? props.color || 'white' : 'url(#paint0_linear_8365_377434)'}
						/>
						<g clipPath="url(#clip0_8274_223322)">
							<path
								d="M24 24V25C24 25.5304 23.7893 26.0391 23.4142 26.4142C23.0391 26.7893 22.5304 27 22 27H11C10.4696 27 9.96086 26.7893 9.58579 26.4142C9.21071 26.0391 9 25.5304 9 25V15C9 14.4696 9.21071 13.9609 9.58579 13.5858C9.96086 13.2107 10.4696 13 11 13H13M18.66 13H22C22.5304 13 23.0391 13.2107 23.4142 13.5858C23.7893 13.9609 24 14.4696 24 15V18.34L25 19.34L31 15V25"
								stroke={isHovered ? props.color || 'white' : 'url(#paint0_linear_8365_377434)'}
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M9 9L31 31"
								stroke={isHovered ? props.color || 'white' : 'url(#paint0_linear_8365_377434)'}
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</g>
						<defs>
							<linearGradient
								id="paint0_linear_8365_377434"
								x1="0"
								y1="20"
								x2="40"
								y2="20"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#FA0101" />
								<stop offset="1" stopColor="#016DEA" />
							</linearGradient>
							<linearGradient
								id="paint1_linear_8365_377434"
								x1="17.3964"
								y1="9.19924"
								x2="36.7753"
								y2="19.2875"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#016DEA" />
								<stop offset="1" stopColor="#FA0101" />
							</linearGradient>
							<linearGradient
								id="paint2_linear_8365_377434"
								x1="12.3618"
								y1="14.9969"
								x2="15.6188"
								y2="16.6975"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#016DEA" />
								<stop offset="1" stopColor="#FA0101" />
							</linearGradient>
							<linearGradient
								id="paint3_linear_8365_377434"
								x1="17.544"
								y1="14.992"
								x2="20.7859"
								y2="16.6702"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#016DEA" />
								<stop offset="1" stopColor="#FA0101" />
							</linearGradient>
							<linearGradient
								id="paint4_linear_8365_377434"
								x1="22.7544"
								y1="14.999"
								x2="26.0015"
								y2="16.6917"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#016DEA" />
								<stop offset="1" stopColor="#FA0101" />
							</linearGradient>
							<clipPath id="clip0_8365_377434">
								<rect
									width="24"
									height="24"
									fill={isHovered ? 'url(#paint1_linear_8365_377434)' : props.color || 'white'}
									transform="translate(8 8)"
								/>
							</clipPath>
						</defs>
					</svg>
				) : (
					<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect
							x="0.5"
							y="0.5"
							width="39"
							height="39"
							rx="19.5"
							fill={isHovered ? 'url(#paint1_linear_8365_377434)' : props.color || 'white'}
						/>
						<rect
							x="0.5"
							y="0.5"
							width="39"
							height="39"
							rx="19.5"
							stroke={isHovered ? props.color || 'white' : 'url(#paint0_linear_8365_377434)'}
						/>
						<path
							d="M24 19.5V25C24 25.5304 23.7893 26.0391 23.4142 26.4142C23.0391 26.7893 22.5304 27 22 27H11C10.4696 27 9.96086 26.7893 9.58579 26.4142C9.21071 26.0391 9 25.5304 9 25V15C9 14.4696 9.21071 13.9609 9.58579 13.5858C9.96086 13.2107 10.4696 13 11 13H18.66H22C22.5304 13 23.0391 13.2107 23.4142 13.5858C23.7893 13.9609 24 14.4696 24 15V19.5ZM24 19.5H24.5L31 15V25L24.5 20.5"
							stroke={isHovered ? props.color || 'white' : 'url(#paint0_linear_8365_377434)'}
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<defs>
							<linearGradient
								id="paint0_linear_8365_377434"
								x1="0"
								y1="20"
								x2="40"
								y2="20"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#FA0101" />
								<stop offset="1" stopColor="#016DEA" />
							</linearGradient>
							<linearGradient
								id="paint1_linear_8365_377434"
								x1="17.3964"
								y1="9.19924"
								x2="36.7753"
								y2="19.2875"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#016DEA" />
								<stop offset="1" stopColor="#FA0101" />
							</linearGradient>
							<linearGradient
								id="paint2_linear_8365_377434"
								x1="12.3618"
								y1="14.9969"
								x2="15.6188"
								y2="16.6975"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#016DEA" />
								<stop offset="1" stopColor="#FA0101" />
							</linearGradient>
							<linearGradient
								id="paint3_linear_8365_377434"
								x1="17.544"
								y1="14.992"
								x2="20.7859"
								y2="16.6702"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#016DEA" />
								<stop offset="1" stopColor="#FA0101" />
							</linearGradient>
							<linearGradient
								id="paint4_linear_8365_377434"
								x1="22.7544"
								y1="14.999"
								x2="26.0015"
								y2="16.6917"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#016DEA" />
								<stop offset="1" stopColor="#FA0101" />
							</linearGradient>
							<clipPath id="clip0_8365_377434">
								<rect
									width="24"
									height="24"
									fill={isHovered ? 'url(#paint1_linear_8365_377434)' : props.color || 'white'}
									transform="translate(8 8)"
								/>
							</clipPath>
						</defs>
					</svg>
				)}
			</div>
		</>
	);
};
export default VideoStatus;
