import {
	Chat,
	ChatPreferences,
	ChatsResult,
	ChatTag,
	ChatType,
	ChatUser,
	ChatWithUser,
	DeleteChatOptions,
	GetChatsOptions,
} from '@/utils/types';
import {
	arrayUnion,
	collection,
	deleteField,
	doc,
	getDoc,
	getDocs,
	limit,
	onSnapshot,
	orderBy,
	query,
	Query,
	QuerySnapshot,
	serverTimestamp,
	startAfter,
	Timestamp,
	updateDoc,
	where,
	writeBatch,
} from 'firebase/firestore';
import { db } from './firebase';

async function findExistingDirectChat(
	userId1: string,
	userId2: string,
	type: ChatType = 'direct'
): Promise<string | null> {
	const chatsRef = collection(db, 'chats');
	const q = query(chatsRef, where('type', '==', type), where('participantIds', 'array-contains', userId1));

	const snapshot = await getDocs(q);
	const existingChat = snapshot.docs.find((doc) => {
		const chat = doc.data() as Chat;

		return chat.participantIds.includes(userId2);
	});

	return existingChat?.id ?? null;
}
async function createDirectChat(userId1: string, userId2: string, type: ChatType = 'direct'): Promise<string> {
	const batch = writeBatch(db);

	// // Get users' data
	// const [user1Data, user2Data] = await Promise.all([
	// 	getDoc(doc(db, 'users', userId1)),
	// 	getDoc(doc(db, 'users', userId2)),
	// ]);

	// if (!user1Data.exists() || !user2Data.exists()) {
	// 	throw new Error('One or more users not found');
	// }

	// Create chat document
	const chatRef = doc(collection(db, 'chats'));
	const chatData: Chat = {
		id: chatRef.id,
		unreadCount: { [userId1]: 0, [userId2]: 0 },
		type,
		participantIds: [userId1, userId2] as [string, string],
		createdAt: Timestamp.now(),
		updatedAt: Timestamp.now(),
		metadata: {
			memberCount: 2,
			name: `name`,
		},
		isActive: true,
		isArchived: false,
		isGroup: false,
		deletedFor: [],
		isBlocked: false,
		blockedBy: '',
		isDeleted: false,
		muted: {
			[userId1]: type === 'private_webinar' || type === 'webinar' ? true : false,
			[userId2]: type === 'private_webinar' || type === 'webinar' ? true : false,
		},
	};

	batch.set(chatRef, chatData);

	// Create chat members
	const member1Ref = doc(db, 'chatMembers', `${chatRef.id}_${userId1}`);
	const member2Ref = doc(db, 'chatMembers', `${chatRef.id}_${userId2}`);

	const createMemberData = (userId: string) => ({
		chatId: chatRef.id,
		userId,
		role: 'member' as const,
		isActive: true,
		unreadCount: 0,
		lastReadAt: serverTimestamp(),
		createdAt: serverTimestamp(),
		updatedAt: serverTimestamp(),
	});

	batch.set(member1Ref, createMemberData(userId1));
	batch.set(member2Ref, createMemberData(userId2));

	await batch.commit();
	return chatRef.id;
}
export async function findOrCreateDirectChat(userId1: string, userId2: string, type?: ChatType): Promise<string> {
	try {
		// Try to find existing chat
		const existingChatId = await findExistingDirectChat(userId1, userId2, type);

		if (existingChatId) return existingChatId;

		// Create new chat if none exists
		return await createDirectChat(userId1, userId2, type);
	} catch (error) {
		console.error('Error in findOrCreateDirectChat:', error);
		throw new Error('Failed to get or create direct chat');
	}
}
export function subscribeToDirectChats(userId: string, callback: (chats: ChatWithUser[]) => void): () => void {
	const q = query(
		collection(db, 'chats'),
		where('type', '==', 'direct'),
		where('participantIds', 'array-contains', userId),
		where('deletedFor', 'not-in', [[userId]]), // Exclude messages deleted by the user
		where('isDeleted', '==', false),
		orderBy('updatedAt', 'desc'),
		limit(20)
	);

	return onSnapshot(q, async (snapshot) => {
		const enrichedChats = await enrichChatsWithUserData(snapshot, userId);
		callback(enrichedChats);
	});
}
export async function getUserDirectChats(
	userId: string,
	options: {
		limit?: number;
		lastChatTimestamp?: Date;
	} = {}
): Promise<ChatWithUser[]> {
	const { limit: queryLimit = 20, lastChatTimestamp } = options;

	try {
		// Build query
		let chatQuery = query(
			collection(db, 'chats'),
			where('type', '==', 'direct'),
			where('participantIds', 'array-contains', userId),
			orderBy('updatedAt', 'desc'),
			limit(queryLimit)
		);

		if (lastChatTimestamp) {
			chatQuery = query(chatQuery, where('updatedAt', '<', lastChatTimestamp));
		}

		const chatsSnapshot = await getDocs(chatQuery);

		return await enrichChatsWithUserData(chatsSnapshot, userId);
	} catch (error) {
		console.error('Error getting user direct chats:', error);
		throw new Error('Failed to get direct chats');
	}
}
export async function deleteChatForUser({ chatId, userId }: DeleteChatOptions): Promise<void> {
	try {
		const batch = writeBatch(db);

		// Get chat document
		const chatRef = doc(db, 'chats', chatId);
		const chatDoc = await getDoc(chatRef);

		if (!chatDoc.exists()) {
			throw new Error('Chat not found');
		}

		const chatData = chatDoc.data() as Chat;

		// Create or update the deletedFor field in chat document
		batch.update(chatRef, {
			deletedFor: arrayUnion(userId),
			updatedAt: serverTimestamp(),
		});

		// Update chatMember document to mark as deleted
		const memberRef = doc(db, 'chatMembers', `${chatId}_${userId}`);
		batch.update(memberRef, {
			isDeleted: true,
			deletedAt: serverTimestamp(),
			updatedAt: serverTimestamp(),
		});

		await batch.commit();
	} catch (error) {
		console.error('Error deleting chat for user:', error);
		throw error;
	}
}
export async function markChatAsRead(chatId: string, userId: string) {
	try {
		const batch = writeBatch(db);

		// Reset unread count for this chat
		const chatRef = doc(db, 'chats', chatId);
		batch.update(chatRef, {
			[`unreadCount.${userId}`]: 0,
			'lastMessage.seenBy': arrayUnion(userId),
			'lastMessage.status': 'seen',
		});

		await batch.commit();
	} catch (error) {
		console.error('Error marking chat as read:', error);
		throw error;
	}
}
export async function toggleChatMute(chatId: string, userId: string, mute: boolean) {
	try {
		const chatRef = doc(db, 'chats', chatId);
		await updateDoc(chatRef, {
			[`muted.${userId}`]: mute,
			updatedAt: serverTimestamp(),
		});
	} catch (error) {
		console.error('Error toggling chat mute:', error);
		throw error;
	}
}
export async function isChatMuted(chatId: string, userId: string): Promise<boolean> {
	try {
		const chatRef = doc(db, 'chats', chatId);
		const chatDoc = await getDoc(chatRef);

		if (!chatDoc.exists()) {
			return false;
		}

		const chatData = chatDoc.data() as Chat;
		return !!chatData.muted?.[userId];
	} catch (error) {
		console.error('Error checking chat mute status:', error);
		return false;
	}
}
export function buildChatsQuery({
	userId,
	type = 'all',
	searchTerm = '',
	lastChat,
	limit: queryLimit = 5,
}: GetChatsOptions): Query {
	const searchTermLower = searchTerm.toLowerCase();

	// Base query with participant filter
	let baseQuery = query(
		collection(db, 'chats'),
		where('participantIds', 'array-contains', userId),
		where('deletedFor', 'not-in', [[userId]]), // Exclude messages deleted by the user
		where('isDeleted', '==', false),
		where('isActive', '==', true)
	);

	// Add type filter if specified
	if (type !== 'all') {
		baseQuery = query(baseQuery, where('type', '==', type));
	}

	// Add ordering and pagination
	let finalQuery = query(baseQuery, orderBy('updatedAt', 'desc'), limit(queryLimit));

	if (lastChat) {
		finalQuery = query(finalQuery, startAfter(lastChat));
	}

	return finalQuery;
}
export async function enrichChatsWithUserData(
	snapshot: QuerySnapshot,
	currentUserId: string,
	type?: ChatType
): Promise<ChatWithUser[]> {
	// Get all participant IDs except current user
	const otherUserIds = new Set<string>();
	snapshot.docs.forEach((doc) => {
		const chat = doc.data() as Chat;
		chat.participantIds.forEach((id) => {
			if (id !== currentUserId) {
				otherUserIds.add(id);
			}
		});
	});

	if (otherUserIds.size === 0) {
		return snapshot.docs.map((doc) => {
			const chat = { id: doc.id, ...doc.data() } as Chat;
			return {
				...chat,
				otherUser: {
					id: '',
					displayName: '',
					photoURL: '',
					isOnline: false,
				},
				participants: [],
			};
		});
	}
	// Batch get all users' data
	const usersSnapshot = await getDocs(query(collection(db, 'users'), where('id', 'in', Array.from(otherUserIds))));

	const usersMap = new Map(usersSnapshot.docs.map((doc) => [doc.data().id, doc.data() as ChatUser]));

	// Enrich chats with user data
	return snapshot.docs
		.filter((doc) => {
			const chat = doc.data() as Chat;
			if (type === 'private_webinar') return chat.type === 'private_webinar';
			return chat.type !== 'webinar' && chat.type !== 'private_webinar';
		})
		.map((doc) => {
			const chat = { id: doc.id, ...doc.data() } as Chat;

			if (chat.type === 'direct' || chat.type === 'private_webinar') {
				const otherUserId = chat.participantIds.find((id) => id !== currentUserId)!;
				const otherUser = usersMap.get(otherUserId)!;

				return {
					...chat,
					otherUser: {
						id: otherUserId,
						displayName: otherUser?.displayName || 'unknown',
						photoURL: otherUser?.photoURL || '',
						isOnline: true,
					},
				};
			} else {
				// For group chats, return with metadata
				return {
					...chat,
					otherUser: {
						id: '',
						displayName: '',
						photoURL: '',
						isOnline: false,
					},
					participants: chat.participantIds
						.filter((id) => id !== currentUserId)
						.map((id) => {
							const user = usersMap.get(id);
							return user
								? {
										id: user.id,
										displayName: user.displayName || 'unknown',
										photoURL: user.photoURL,
									}
								: null;
						})
						.filter(Boolean),
				};
			}
		});
}
function filterChatsBySearch(chats: ChatWithUser[], searchTerm: string): ChatWithUser[] {
	if (!searchTerm) return chats;

	const searchTermLower = searchTerm.toLowerCase();

	return chats.filter((chat) => {
		if (chat.type === 'direct') {
			// Search in other user's display name for direct chats
			return chat.otherUser.displayName.toLowerCase().includes(searchTermLower);
		} else {
			// Search in group metadata for group chats
			return chat.metadata.name.toLowerCase().includes(searchTermLower);
		}
	});
}
export function subscribeToChats(options: GetChatsOptions, callback: (result: ChatsResult) => void): () => void {
	const chatsQuery = buildChatsQuery(options);

	return onSnapshot(chatsQuery, async (snapshot) => {
		try {
			const enrichedChats = await enrichChatsWithUserData(snapshot, options.userId, options.type);

			let filteredChats = enrichedChats;

			// Apply filter based on user preferences
			if (options.filter) {
				filteredChats = enrichedChats.filter((chat) => {
					const userPrefs = chat.preferences?.[options.userId];

					switch (options.filter) {
						case 'favourites':
							return userPrefs?.isFavourite === true;
						case 'hidden':
							return userPrefs?.isHidden === true;
						case 'visible':
							// Show chats that either don't have preferences or aren't hidden
							return userPrefs?.isHidden !== true;
						case 'unread':
							return (
								chat.lastMessage &&
								chat.lastMessage.senderId !== options.userId &&
								!chat.lastMessage.seenBy.includes(options.userId)
							);
						default:
							return true;
					}
				});
			}

			if (options.tag) {
				filteredChats = filteredChats.filter((chat) => {
					const userTag = chat.tags?.[options.userId]?.tag;
					return userTag === options.tag;
				});
			}

			// Apply search filter if provided
			if (options.searchTerm) {
				filteredChats = filterChatsBySearch(filteredChats, options.searchTerm);
			}

			callback({
				chats: filteredChats,
				lastVisible: snapshot.docs[snapshot.docs.length - 1] || null,
				hasMore: snapshot.docs.length === options.limit,
			});

			// Initialize preferences for chats that don't have them
			// Do this after returning results to not block the UI
			enrichedChats.forEach((chat) => {
				if (!chat.preferences?.[options.userId]) {
					initializeChatPreferences(chat.id, options.userId).catch((error) => {
						console.error('Error initializing chat preferences:', error);
					});
				}
			});
		} catch (error) {
			console.error('Error in chats subscription:', error);
			// Return empty result on error
			callback({
				chats: [],
				lastVisible: null,
				hasMore: false,
			});
		}
	});
}
export async function addTagToChat(chatId: string, userId: string, tag: ChatTag): Promise<void> {
	try {
		const chatRef = doc(db, 'chats', chatId);

		await updateDoc(chatRef, {
			[`tags.${userId}`]: {
				userId,
				tag,
				createdAt: serverTimestamp(),
			},
			updatedAt: serverTimestamp(),
		});
	} catch (error) {
		console.error('Error adding tag to chat:', error);
		throw error;
	}
}
export async function removeTagFromChat(chatId: string, userId: string): Promise<void> {
	try {
		const chatRef = doc(db, 'chats', chatId);

		await updateDoc(chatRef, {
			[`tags.${userId}`]: deleteField(),
			updatedAt: serverTimestamp(),
		});
	} catch (error) {
		console.error('Error removing tag from chat:', error);
		throw error;
	}
}
export async function toggleFavourite(chatId: string, userId: string): Promise<void> {
	const chatRef = doc(db, 'chats', chatId);
	const chatDoc = await getDoc(chatRef);

	if (!chatDoc.exists()) throw new Error('Chat not found');

	const currentPrefs = chatDoc.data().preferences?.[userId];

	await updateChatPreferences(chatId, userId, {
		isFavourite: !currentPrefs?.isFavourite,
	});
}
export async function toggleHidden(chatId: string, userId: string): Promise<void> {
	const chatRef = doc(db, 'chats', chatId);
	const chatDoc = await getDoc(chatRef);

	if (!chatDoc.exists()) throw new Error('Chat not found');

	const currentPrefs = chatDoc.data().preferences?.[userId];

	await updateChatPreferences(chatId, userId, {
		isHidden: !currentPrefs?.isHidden,
	});
}
export async function initializeChatPreferences(chatId: string, userId: string): Promise<void> {
	const chatRef = doc(db, 'chats', chatId);

	await updateDoc(chatRef, {
		[`preferences.${userId}`]: {
			isFavourite: false,
			isHidden: false,
			updatedAt: serverTimestamp(),
		},
	});
}
export async function updateChatPreferences(
	chatId: string,
	userId: string,
	updates: Partial<Omit<ChatPreferences, 'userId' | 'updatedAt'>>
): Promise<void> {
	try {
		const chatRef = doc(db, 'chats', chatId);

		await updateDoc(chatRef, {
			[`preferences.${userId}`]: {
				userId,
				...updates,
				updatedAt: serverTimestamp(),
			},
		});
	} catch (error) {
		console.error('Error updating chat preferences:', error);
		throw error;
	}
}

export async function getParticipantNames(chatId: string, userId: string): Promise<string[]> {
	try {
		// Fetch the chat document based on chatId
		const queryChat = query(collection(db, 'chats'), where('id', '==', chatId));
		const chatSnapshot = await getDocs(queryChat);

		// Ensure we have at least one result (chat)
		if (chatSnapshot.empty) {
			console.error(`No chat found with id: ${chatId}`);
		}

		// Extract participantIds from the chat data
		const chatData = chatSnapshot?.docs[0]?.data();
		const participantIds = chatData?.participantIds;

		// Fetch user details based on participantIds
		const usersSnapshot = await getDocs(query(collection(db, 'users'), where('id', 'in', participantIds)));
		const participantNamesSet = new Set(
			usersSnapshot.docs
				.map((doc) => {
					const user = doc.data() as ChatUser;
					return user.id !== userId ? user.displayName : null;
				})
				.filter(Boolean)
		);
		const participantNames = Array.from(participantNamesSet).filter((name): name is string => name !== null);

		return participantNames;
	} catch (error) {
		console.error('Error getting participant names:', error);
		throw error;
	}
}

export async function isUserInChat(chatId: string, userId: string): Promise<boolean> {
	try {
		const chatRef = doc(db, 'chats', chatId);
		const chatDoc = await getDoc(chatRef);

		if (!chatDoc.exists()) {
			return false;
		}

		const chatData = chatDoc.data() as Chat;
		return chatData.participantIds.includes(userId);
	} catch (error) {
		console.error('Error checking if user is in chat:', error);
		return false;
	}
}
