import { SVGProps } from 'react';

const Hamburger = (props: SVGProps<SVGSVGElement>) => (
	<svg width={40} height={41} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.00195 31.5106C5.00195 32.1023 5.48165 32.582 6.07338 32.582H33.9305C34.5223 32.582 35.002 32.1023 35.002 31.5106C35.002 30.9189 34.5223 30.4392 33.9305 30.4392H6.07338C5.48165 30.4392 5.00195 30.9189 5.00195 31.5106ZM11.4316 20.7964C11.4316 21.3881 11.9113 21.8678 12.5031 21.8678H33.9316C34.5234 21.8678 35.0031 21.3881 35.0031 20.7964C35.0031 20.2047 34.5234 19.725 33.9316 19.725H12.5031C11.9113 19.725 11.4316 20.2047 11.4316 20.7964ZM20.0031 11.153C19.4113 11.153 18.9316 10.6733 18.9316 10.0816C18.9316 9.48983 19.4113 9.01014 20.0031 9.01014H33.9316C34.5234 9.01014 35.0031 9.48983 35.0031 10.0816C35.0031 10.6733 34.5234 11.153 33.9316 11.153H20.0031Z"
			fill="url(#paint0_linear_144_58840)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_144_58840"
				x1={16.7385}
				y1={10.1709}
				x2={38.1666}
				y2={24.3834}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
		</defs>
	</svg>
);
export default Hamburger;
