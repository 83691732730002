import clsx from 'clsx';
type Props = {
	notification: string | number;
	className?: string;
};
const HeaderNotification = ({ className, notification }: Props) => {
	return (
		<>
			<div
				className={clsx(
					'red-blue-gradient absolute left-[20px] top-[0px] z-20 hidden w-auto rounded-[14px] p-[1px] lg:block',
					className
				)}
			>
				<div className="rounded-[14px] bg-white">
					<p className="w-full whitespace-nowrap rounded-[9px] bg-white bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] bg-clip-text px-[8px] text-center font-poppins text-[12px] not-italic leading-6 text-transparent">
						{notification}
					</p>
				</div>
			</div>
		</>
	);
};
export const HeaderNotificationMobile = ({ className, notification }: Props) => {
	return (
		<>
			<div
				className={clsx(
					'red-blue-gradient absolute left-[20px] top-[0px] block w-auto rounded-[14px] p-[1px] lg:hidden',
					className
				)}
			>
				<div className="rounded-[14px] bg-white">
					<p className="w-full whitespace-nowrap rounded-[9px] bg-white bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] bg-clip-text px-[8px] text-center font-poppins text-[12px] not-italic leading-6 text-transparent">
						{notification}
					</p>
				</div>
			</div>
		</>
	);
};

export default HeaderNotification;
