import { db } from '@/firebase/firebase';
import { Chat, otherUser } from '@/utils/types';
import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { create } from 'zustand';

interface ChatStoreState {
	chatId: string | null;
	user: otherUser | null;
	chat: Chat | null;
	isLoading: boolean;
	error: Error | null;
	unsubscribe: Unsubscribe | null;
	changeChat: (chatId: string, user?: otherUser) => void;
	resetChat: () => void;
	subscribeToChatUpdates: (chatId: string) => void;
	cleanup: () => void;
}

export const useChatStore = create<ChatStoreState>((set, get) => ({
	chatId: null,
	user: null,
	chat: null,
	isLoading: false,
	error: null,
	unsubscribe: null,

	changeChat: (chatId: string, user?: otherUser) => {
		// const currentUser = useUserStore.getState().currentUser;

		// if (!currentUser) {
		// 	throw new Error('Current user is not defined.');
		// }

		// Clean up existing subscription if any
		const currentUnsubscribe = get().unsubscribe;
		if (currentUnsubscribe) {
			currentUnsubscribe();
		}

		// Set new chat and user
		set({
			chatId,
			user,
			isLoading: true,
			error: null,
		});

		// Start new subscription
		get().subscribeToChatUpdates(chatId);
	},

	subscribeToChatUpdates: (chatId: string) => {
		const chatRef = doc(db, 'chats', chatId);

		const unsubscribe = onSnapshot(
			chatRef,
			(doc) => {
				if (doc.exists()) {
					set({
						chat: doc.data() as Chat,
						isLoading: false,
						error: null,
					});
				} else {
					set({
						chat: null,
						isLoading: false,
						error: new Error('Chat not found'),
					});
				}
			},
			(error) => {
				console.error('Error in chat subscription:', error);
				set({
					error: error as Error,
					isLoading: false,
				});
			}
		);

		set({ unsubscribe });
	},

	resetChat: () => {
		// Clean up subscription
		const currentUnsubscribe = get().unsubscribe;
		if (currentUnsubscribe) {
			currentUnsubscribe();
		}

		// Reset state
		set({
			chatId: null,
			user: null,
			chat: null,
			isLoading: false,
			error: null,
			unsubscribe: null,
		});
	},

	cleanup: () => {
		const currentUnsubscribe = get().unsubscribe;
		if (currentUnsubscribe) {
			currentUnsubscribe();
		}

		set({
			chatId: null,
			user: null,
			chat: null,
			isLoading: false,
			error: null,
			unsubscribe: null,
		});
	},
}));
