import { Message } from '../types';

// src/utils/search.utils.ts
export function generateSearchTerms(text: string): string[] {
	const cleanText = text.toLowerCase().trim();
	const words = cleanText.split(/\s+/);
	const searchTerms = new Set<string>();

	// Add individual words
	words.forEach((word) => {
		if (word.length > 2) {
			searchTerms.add(word);
		}
	});

	// Add consecutive word combinations
	for (let i = 0; i < words.length - 1; i++) {
		searchTerms.add(words[i] + ' ' + words[i + 1]);
	}

	return Array.from(searchTerms);
}

export function calculateRelevanceScore(message: Message, searchTerm: string): number {
	const content = message.contentLower;
	let score = 0;

	// Exact match
	if (content === searchTerm) {
		score += 100;
	}
	// Contains exact phrase
	else if (content.includes(searchTerm)) {
		score += 50;
	}

	// Word boundary matches
	const searchWords = searchTerm.split(/\s+/);
	searchWords.forEach((word) => {
		if (word.length > 2) {
			const regex = new RegExp(`\\b${word}\\b`, 'i');
			if (regex.test(content)) {
				score += 25;
			}
		}
	});

	// Partial word matches
	searchWords.forEach((word) => {
		if (word.length > 2 && content.includes(word)) {
			score += 10;
		}
	});

	// Recent messages boost
	const ageInHours = (Date.now() - message.createdAt.toMillis()) / (1000 * 60 * 60);
	score += Math.max(0, 5 - ageInHours / 24);

	return score;
}
