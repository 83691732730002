'use client';

import EBook from '@/components/icons/EBook';
import AutomatedIcon from '@/components/icons/webinar/AutomatedIcon';
import HybridIcon from '@/components/icons/webinar/HybridIcon';
import LiveIcon from '@/components/icons/webinar/LiveIcon';
import Modal from '@/components/Modal';
import NewAssetBuilderSubModal from '@/components/NewAssetBuilderSubModal';
import { ROUTES } from '@/constants';
import { useAppSelector } from '@/lib/hooks';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import BuilderModalCard from '../BuilderModalCard';
import Book from '../icons/Book';
import Channels from '../icons/Channels';
import Consultations from '../icons/Consultations';
import Courses from '../icons/Courses';
import Events from '../icons/Events';
import Jobs from '../icons/Jobs';
import Plus from '../icons/modal/Plus';
import Podcast from '../icons/Podcast';
import Posts from '../icons/Posts';
import Projects from '../icons/Projects';
import Services from '../icons/Services';
import SocialGroups from '../icons/SocialGroups';
import SocialPages from '../icons/SocialPages';
import Sponsorship from '../icons/Sponsorship';
import Stores from '../icons/Stores';
import Webinars from '../icons/Webinars';

type Props = {
	isOpen: boolean;
	setIsOpen: (item: boolean) => void;
};
export default function NewAssetBuildersModal(props: Props) {
	const t = useTranslations('header');
	const { isOpen = false, setIsOpen } = props;
	const [hoverIndex, setHoverIndex] = useState<any>();
	const [isOpenBook, setIsOpenBook] = useState<boolean>(false);
	const [isOpenWebinar, setIsOpenWebinar] = useState<boolean>(false);
	const { v_lance_type, v_job_type, user } = useAppSelector((state) => state.auth);

	useEffect(() => {
		setHoverIndex(null);
	}, [isOpen]);

	const assets = [
		{
			title: t('newBlogPost'),
			description: t('newBlogPostDescription'),
			icon: <Posts color={hoverIndex == 0 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-blog-post',
		},
		{
			title: t('newSocialPage'),
			description: t('newSocialPageDescription'),
			icon: <SocialPages color={hoverIndex == 1 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-social-page',
		},
		{
			title: t('newSocialGroup'),
			description: t('newSocialGroupDescription'),
			icon: <SocialGroups color={hoverIndex == 2 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-social-group',
		},
		{
			title: t('newChannel'),
			description: t('newChannelDescription'),
			icon: <Channels color={hoverIndex == 3 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-channel',
		},
		{
			title: t('newJob'),
			description: t('newJobDescription'),
			icon: <Jobs color={hoverIndex == 4 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-job',
			isHide: v_job_type === 'candidate',
		},
		{
			title: t('newService'),
			description: t('newServiceDescription'),
			icon: <Services color={hoverIndex == 5 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-service',
			isHide: v_lance_type !== 'freelancer',
		},
		{
			title: t('newProject'),
			description: t('newProjectDescription'),
			icon: <Projects color={hoverIndex == 6 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-project',
			isHide: v_lance_type !== 'freelancer',
		},
		{
			title: t('newCourse'),
			description: t('newCourseDescription'),
			icon: <Courses color={hoverIndex == 7 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-course',
		},
		{
			title: t('newStore'),
			description: t('newStoreDescription'),
			icon: <Stores color={hoverIndex == 8 ? '#016dea' : 'white'} />,
			url: ROUTES.myOffice.builders.createStore,
		},
		{
			title: t('newEvent'),
			description: t('newEventDescription'),
			icon: <Events color={hoverIndex == 9 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-event',
		},
		{
			title: t('newWebinar'),
			description: t('newWebinarDescription'),
			icon: <Webinars color={hoverIndex == 10 ? '#016dea' : 'white'} />,
			url: '',
			onClick: () => setIsOpenWebinar(true),
		},
		{
			title: t('newConsultation'),
			description: t('newConsultationDescription'),
			icon: <Consultations color={hoverIndex == 11 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-consultation',
		},
		{
			title: t('newSponsorship'),
			description: t('newSponsorshipDescription'),
			icon: <Sponsorship color={hoverIndex == 12 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-sponsorship',
		},
		{
			title: t('newPodcast'),
			description: t('newPodcastDescription'),
			icon: <Podcast color={hoverIndex == 13 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-podcast',
		},
		{
			title: t('newBook'),
			description: t('newBookDescription'),
			icon: <Book color={hoverIndex == 14 ? '#016dea' : 'white'} />,
			url: '',
			onClick: () => setIsOpenBook(true),
		},
	];

	const bookOptions = [
		{
			title: t('hardCoverBook'),
			description: t('hardCoverBookDescription'),
			icon: <Book height={60} width={60} color={hoverIndex == 30 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-book?type=physical',
			index: 30,
		},
		{
			title: t('eBook'),
			description: t('eBookDescription'),
			icon: <EBook color={hoverIndex == 31 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-book?type=digital',
			index: 31,
		},
	];

	const webinarOptions = [
		{
			title: t('liveWebinar'),
			description: t('liveWebinarDescription'),
			icon: <LiveIcon height={60} width={60} color={hoverIndex == 30 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-webinar?type=live',
			index: 32,
		},
		{
			title: t('automatedWebinar'),
			description: t('automatedWebinarDescription'),
			icon: <AutomatedIcon height={60} width={60} color={hoverIndex == 31 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-webinar?type=automated',
			index: 33,
		},
		{
			title: t('hybridWebinar'),
			description: t('hybridWebinarDescription'),
			icon: <HybridIcon color={hoverIndex == 31 ? '#016dea' : 'white'} />,
			url: '/my-office/builders/new-webinar?type=hybrid',
			index: 33,
		},
	];

	return (
		<>
			<NewAssetBuilderSubModal
				onClose={() => {
					setIsOpenBook(false);
				}}
				key={1}
				title={t('newBook')}
				isOpen={isOpenBook}
				setIsOpen={(value) => {
					setIsOpenBook(value);
					setIsOpen(true);
				}}
				options={bookOptions}
				icon={<Book />}
			/>
			<NewAssetBuilderSubModal
				onClose={() => {
					setIsOpenWebinar(false);
				}}
				key={2}
				title={t('newWebinar')}
				isOpen={isOpenWebinar}
				setIsOpen={(value) => {
					setIsOpenWebinar(value);
					setIsOpen(true);
				}}
				options={webinarOptions}
				icon={<Webinars />}
				width={'max-w-[930px]'}
				description={true}
			/>

			<Modal
				heading={t('newAssetsBuilders')}
				icon={<Plus />}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				width="max-w-screen-2xl mx-[30px]"
			>
				<div className="grid grid-cols-1 gap-x-[30px] gap-y-[15px] md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
					{assets.map(
						(asset, index) =>
							!asset.isHide && (
								<BuilderModalCard
									title={asset.title}
									description={asset.description}
									icon={asset.icon}
									key={index}
									url={asset.url || ''}
									onClick={() => {
										if (asset.onClick) {
											asset.onClick();
										}
										setIsOpen(false);
									}}
									isOpen={asset.title === 'New Webinar' ? isOpenWebinar : isOpenBook}
									setIsOpen={(value) => {
										if (asset.title === 'New Webinar') {
											setIsOpenWebinar(value);
										} else {
											setIsOpenBook(value);
										}
										setIsOpen(false);
									}}
									onMouseEnter={() => setHoverIndex(index)}
									onMouseLeave={() => setHoverIndex(null)}
									userPlan={user.plan_name}
								/>
							)
					)}
				</div>
			</Modal>
		</>
	);
}
