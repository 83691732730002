import { useRouter } from 'next/navigation';
import { ReactNode } from 'react';

type Props = {
	icon: ReactNode;
	title: string;
	description: string;
	href: string;
	handleClick: () => void;
};
const ModalCard = (props: Props) => {
	const { icon, title, description, href, handleClick } = props;
	const router = useRouter();
	return (
		<div
			onClick={() => {
				router.push(href);
				handleClick();
			}}
			className="hover:blue-gradient group inline-flex cursor-pointer rounded-[10px] bg-[linear-gradient(90deg,#016DEA_0%,#FA0101_100%)] p-px"
		>
			<div className="group-hover:blue-gradient relative flex w-full shrink-0 items-center gap-[10px] rounded-[10px] bg-white p-[20px] text-sm/none font-semibold text-white">
				<div className="inline-flex rounded-[50px] bg-[linear-gradient(90deg,#FA0101_0%,#016DEA_100%)] p-px group-hover:bg-[linear-gradient(90deg,#016DEA_0%,#016DEA_100%)]">
					<div className="relative flex size-[45px] shrink-0 items-center justify-center gap-[10px] rounded-[50px] bg-[linear-gradient(62deg,#016dea_27%,#fa0101_95%)] p-[11.5px] text-sm/none font-semibold">
						{icon}
					</div>
				</div>
				<div className="flex flex-grow flex-col items-start justify-start gap-[5px]">
					<h6 className="font-poppins text-[14px] font-bold capitalize leading-[16.8px] text-[#016DEA] group-hover:text-white">
						{title}
					</h6>
					<p className="text-[12px] font-medium leading-[16.8px] text-black/50 group-hover:text-white">{description}</p>
				</div>
			</div>
		</div>
	);
};

export default ModalCard;
