import Button from '@/components/Button';
import Circle from '@/components/Circle';
import Modal from '@/components/Modal';
import AudioStatus from '@/components/icons/AudioStatus';
import DropdownProfile from '@/components/icons/DropdownProfile';
import IncomingCallIcon from '@/components/icons/IncomingCallIcon';
import VideoStatus from '@/components/icons/VideoStatus';
import {
	useAcceptCallMutation,
	useDeclineCallMutation,
	useRoomAcceptMutation,
	useRoomDeclineCallMutation,
} from '@/lib/api/mutations';
import { useAppSelector } from '@/lib/hooks';
import { setAudioPermission, setVideoPermission } from '@/lib/slice/meetSlice';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { useDispatch } from 'react-redux';
type Props = {
	isOpen: boolean;
	onClose?: () => void;
	type?: 'private' | 'room';
};
const IncomingCallModal = (props: Props) => {
	const t = useTranslations('userPanel');
	const tCommon = useTranslations('common');
	const { isOpen, onClose, type } = props;
	const dispatch = useDispatch();
	const router = useRouter();
	const { is_audio_enable, is_video_enable } = useAppSelector((state) => state.meet);
	const [acceptCll, { isLoading }] = useAcceptCallMutation();
	const [roomAcceptCall, { isLoading: loading }] = useRoomAcceptMutation();
	const [declineCall] = useDeclineCallMutation();
	const [roomDeclineCall] = useRoomDeclineCallMutation();
	const { caller } = useAppSelector((state) => state.meet);

	const handleAcceptCall = async () => {
		let response = null;
		if (type == 'room') {
			response = await roomAcceptCall({
				callId: caller.call.id || '',
				signal: null,
				is_camera_on: is_video_enable ? 1 : 0,
			});
			if (response?.data) {
				router.push('/my-office/new-chat');
				onClose?.();
			} else {
				alert('something went wrong');
			}
		} else {
			response = await acceptCll({
				callId: caller.call.id || '',
				signal: null,
				is_camera_on: is_video_enable ? 1 : 0,
			});
			if (response?.data) {
				router.push('/my-office/new-chat');
				onClose?.();
			} else {
				alert('something went wrong');
			}
		}
	};
	const handleCancelCall = async () => {
		let response = null;
		if (type == 'room') {
			response = await roomDeclineCall(caller.call.id || '');
		} else {
			response = await declineCall(caller.call.id || '');
		}
		if (response?.data) {
			console.log('cancel');
			onClose?.();
		}
	};

	const handleAudio = () => {
		dispatch(setAudioPermission(!is_audio_enable));
	};
	const handleVideo = () => {
		dispatch(setVideoPermission(!is_video_enable));
	};
	return (
		<Modal
			footer={
				<div className={'flex gap-[15px]'}>
					<Button title={tCommon('buttons.cancelButton')} type={'secondary'} onClick={handleCancelCall} />
					<Button
						disabled={isLoading || loading}
						title={tCommon('buttons.accept')}
						type={'primary'}
						onClick={handleAcceptCall}
					/>
				</div>
			}
			isOpen={isOpen}
			heading={t('myOffice.common.incomingCall')}
			icon={<IncomingCallIcon />}
			onClose={() => onClose?.()}
		>
			<div>
				<div className={'justify-center'}>
					<div className={'flex justify-center'}>
						<Circle
							statusSizeClass={'h-[24px] w-[24px]'}
							status={'online'}
							backgroundClass="w-[80px] h-[80px]"
							statusContainerClass={'right-1'}
							icon={<DropdownProfile />}
						/>
					</div>

					<p className={'mb-2.5 mt-[15px] text-center text-[18px] font-bold text-[#2B2525]'}>
						{caller?.userData?.name}
					</p>
					<p
						className={
							'mb-2.5 whitespace-nowrap bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] bg-clip-text text-center text-[14px] font-bold not-italic text-transparent'
						}
					>
						{t('myOffice.chat.isCalling')}
					</p>
					<div className={'flex justify-center'}>
						<div className={'flex gap-[15px] rounded-[50px] border border-[#0000001A] p-[15px]'}>
							<AudioStatus className={'cursor-pointer'} onClick={handleAudio} status={!is_audio_enable} />
							<VideoStatus className={'cursor-pointer'} onClick={handleVideo} status={!is_video_enable} />
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};
export default IncomingCallModal;
