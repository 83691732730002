import { useCallback, useState } from 'react';

export const useAudioControls = (audioFile: string) => {
	const [audio] = useState(() => new Audio(audioFile));

	const playAudio = useCallback(() => {
		audio.play().catch(console.error);
	}, [audio]);

	const stopAudio = useCallback(() => {
		audio.pause();
		audio.currentTime = 0;
	}, [audio]);

	return { playAudio, stopAudio };
};
