import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { InputHTMLAttributes, ReactNode } from 'react';
import 'react-phone-number-input/style.css';

type Props = {
	label?: string;
	required?: boolean;
	customClass?: string;
	prefix?: string;
	suffix?: string;
	prefixIcon?: ReactNode;
	suffixIcon?: ReactNode;
	topInfoText?: string;
	bottomInfoText?: string;
	actionbuttons?: React.ReactNode;
	suffixClass?: string;
	bottomInfoChild?: ReactNode;
	error?: string;
	onInputChange?: (val: string) => void;
	onKeyDown?: (val: any) => void;
} & InputHTMLAttributes<HTMLInputElement>;

const EmailInput = (props: Props) => {
	const {
		label,
		required,
		customClass,
		prefix,
		suffix,
		prefixIcon,
		suffixIcon,
		suffixClass,
		actionbuttons,
		topInfoText,
		bottomInfoText,
		bottomInfoChild,
		error,
		onInputChange,
		onKeyDown,
		...inputProps
	} = props;
	const t = useTranslations('footer');
	return (
		<div className={clsx('relative flex flex-col', customClass, 'flex-row-reverse')}>
			<div
				className={clsx(
					'w-full overflow-hidden rounded-full border',
					error != '' ? '!border-red-500' : 'border-gray-300',
					'bg-white text-sm font-medium text-gray-700 placeholder-gray-500 focus:outline-none'
				)}
			>
				<label
					htmlFor={inputProps.id}
					className={clsx(
						'flex items-center overflow-hidden rounded-md text-sm font-medium leading-[22px]',
						inputProps.disabled && 'cursor-not-allowed !bg-[#F4F4F4] text-black/50'
					)}
				>
					<input
						type="email"
						className={clsx(
							'login-input h-10 max-w-[calc(100%-35px)] flex-1 bg-transparent px-4 text-sm leading-[22px] placeholder-gray-500 placeholder:font-medium focus:outline-none disabled:cursor-not-allowed disabled:text-black/50',
							error != '' ? '!text-red-500' : 'text-[#2B2525]'
						)}
						placeholder={t('enterEmail')}
						value={inputProps.value?.toString()}
						onChange={(e) => onInputChange && onInputChange(e.target.value || '')}
						disabled={inputProps.disabled}
						onKeyDown={onKeyDown}
						{...inputProps}
					/>
					<div className={''}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke-width="1.5"
							stroke="#AAA9AD"
							className="size-6 ltr:right-[1px] rtl:-left-[1px]"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
							/>
						</svg>
					</div>
				</label>
			</div>
		</div>
	);
};
export default EmailInput;
