import Card from '@/components/Card';
import Modal from '@/components/Modal';
import clsx from 'clsx';
import Link from 'next/link';
import { ReactNode } from 'react';
import Circle from './Circle';

type Props = {
	isOpen: boolean;
	description?: boolean;
	setIsOpen: (value: boolean) => void;
	onClose?: () => void;
	options: any;
	icon?: ReactNode;
	title: string;
	width?: string;
};
const NewAssetBuilderSubModal = (props: Props) => {
	const { isOpen, setIsOpen, options = [], icon, title, width, description = false, onClose } = props;
	return (
		<Modal
			heading={title}
			icon={icon}
			isOpen={isOpen}
			onClose={() => {
				// setIsOpenBook(false)
				setIsOpen?.(false);
			}}
			width={width}
			bodyClass={'p-[10px]'}
		>
			<div className="flex flex-col justify-between gap-4 lg:flex-row">
				{options.map((item: any, index: number) => (
					<div
						className={'w-full'}
						key={index}
						// onMouseEnter={() => setHoverIndex(item.index)}
						// onMouseLeave={() => setHoverIndex(null)}
					>
						<Card
							hoverBorderType={0}
							customClass="hover:bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] group cursor-pointer "
							mainClass={'!h-full '}
						>
							<Link
								onClick={() => onClose?.()}
								href={item.url}
								className={clsx(
									'flex h-full flex-col items-center justify-center gap-[15px]',
									description ? 'py-[10px]' : 'py-[38px]'
								)}
							>
								<h3 className="whitespace-nowrap bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] bg-clip-text font-poppins text-xl font-bold not-italic leading-6 text-transparent group-hover:text-white">
									{item.title}
								</h3>
								<Circle hasHover={false} backgroundClass="!size-[125px]" type={0} icon={item.icon} />
								{description && (
									<h3 className="whitespace-nowrap bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] bg-clip-text font-poppins text-sm font-bold not-italic leading-6 text-transparent group-hover:text-white">
										{item.description}
									</h3>
								)}
							</Link>
						</Card>
					</div>
				))}
			</div>
		</Modal>
	);
};

export default NewAssetBuilderSubModal;
