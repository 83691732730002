import { db } from '@/firebase/firebase';
import { ChatUser } from '@/utils/types';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { create } from 'zustand';

interface UserStore {
	currentUser: ChatUser | null;
	isLoading: boolean;
	fetchUserInfo: (id: string) => Promise<void>;
}

export const useUserStore = create<UserStore>((set) => ({
	currentUser: null,
	isLoading: true,
	fetchUserInfo: async (id: string) => {
		if (!id) {
			set({ currentUser: null, isLoading: false });
			console.warn('No user ID provided');
			return;
		}

		try {
			const userQuery = query(collection(db, 'users'), where('id', '==', id));

			// Real-time listener with onSnapshot
			onSnapshot(userQuery, (querySnapshot) => {
				if (!querySnapshot.empty) {
					const userDoc = querySnapshot.docs[0];
					set({ currentUser: userDoc.data() as ChatUser, isLoading: false });
				} else {
					console.warn(`User with id ${id} does not exist.`);
					set({ currentUser: null, isLoading: false });
				}
			});
		} catch (error) {
			console.error('Error fetching user info:', error);
			set({ currentUser: null, isLoading: false });
		}
	},
}));
