'use client';
import IncomingCallModal from '@/components/modals/IncomingCallModal';
import { useAppSelector } from '@/lib/hooks';
import { setCaller, setDeclineCall } from '@/lib/slice/meetSlice';
import { useRouter } from 'next/navigation';
import pusherJs from 'pusher-js';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function Pusher() {
	const { isLogin, token, user } = useAppSelector((state) => state.auth);
	const [openIncomingCallModel, setOpenIncomingCallModel] = useState<boolean>(false);
	const [incomingCallType, setIncomingCallType] = useState<'private' | 'room'>('private');
	const dispatch = useDispatch();
	const router = useRouter();
	useEffect(() => {
		const pusher = new pusherJs(process.env.NEXT_PUBLIC_PUSHER_APP_KEY || '', {
			cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER || '',
			authEndpoint: `${process.env.NEXT_PUBLIC_API_URL}pusher/auth/online-event`,
			auth: {
				headers: {
					Authorization: 'Bearer ' + token, // Replace with your JWT token
					Accept: 'application/json',
				},
			},
		});

		pusher.connection.bind('connected', function () {
			const socketId = pusher.connection.socket_id;

			const channel = pusher.subscribe('presence-online-status');

			channel.bind('pusher:subscription_succeeded', function () {
				console.log('presence-online-status: Successfully subscribed!');
			});
			// Unsubscribe when the page is closed
			window.onbeforeunload = function () {
				pusher.unsubscribe('presence-online-status');
			};
		});

		const callEventPusher = new pusherJs(process.env.NEXT_PUBLIC_PUSHER_APP_KEY || '', {
			cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER || 'eu',
			authEndpoint: `${process.env.NEXT_PUBLIC_API_URL}pusher/auth/call-event`,
			auth: {
				headers: {
					Authorization: 'Bearer ' + token, // Replace with your JWT token
					Accept: 'application/json',
				},
				params: {
					conversation_type: 'private',
				},
			},
		});
		callEventPusher.connection.bind('connected', function () {
			const socketId = callEventPusher.connection.socket_id;
			console.log('Socket ID: ' + socketId); // Log the socket ID to the console

			const channel = callEventPusher.subscribe(`private-calls.${user.id}`);

			channel.bind('pusher:subscription_succeeded', () => {
				// console.log('private-calls: Successfully subscribed!')
			});
			channel.bind('audio-incoming-call', function (data: any) {
				console.log('private audio-incoming-call');
				setIncomingCallType('private');
				setOpenIncomingCallModel(true);
				dispatch(setCaller(data));
			});
			channel.bind('call-accepted', function (data: any) {
				console.log('call-accepted');
				router.push('/my-office/new-chat');
				dispatch(setCaller(data));
			});
			channel.bind('call-declined', function (data: any) {
				console.log('call-declined');
				dispatch(setDeclineCall(true));
			});
		});

		const roomCallEventPusher = new pusherJs(process.env.NEXT_PUBLIC_PUSHER_APP_KEY || '', {
			cluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER || 'eu',
			authEndpoint: `${process.env.NEXT_PUBLIC_API_URL}pusher/auth/call-event`,
			auth: {
				headers: {
					Authorization: 'Bearer ' + token, // Replace with your JWT token
					Accept: 'application/json',
				},
				params: {
					conversation_type: 'room',
				},
			},
		});
		roomCallEventPusher.connection.bind('connected', function () {
			const socketId = roomCallEventPusher.connection.socket_id;
			console.log('Socket ID: ' + socketId); // Log the socket ID to the console

			const channel = roomCallEventPusher.subscribe(`private-room-calls.${user.id}`);

			channel.bind('pusher:subscription_succeeded', () => {
				// console.log('private-calls: Successfully subscribed!')
			});
			channel.bind('audio-incoming-call', function (data: any) {
				setIncomingCallType('room');
				setOpenIncomingCallModel(true);
				dispatch(
					setCaller({
						call: {
							id: data.call.id,
						},
						conversation: {
							id: data.conversation.id,
						},
						userData: data.userData,
					})
				);
			});

			// channel.bind('call-accepted', function (data: any) {
			//   console.log('room call-accepted');
			//   router.push('/my-office/new-chat');
			//   dispatch(pushRoomUser(data));
			// });

			channel.bind('call-declined', function (data: any) {
				console.log('room call-declined');
				dispatch(setDeclineCall(true));
			});
		});
	}, []);

	const IncomingCall = () => {
		return (
			<IncomingCallModal
				isOpen={openIncomingCallModel}
				type={incomingCallType}
				onClose={() => setOpenIncomingCallModel(false)}
			/>
		);
	};
	return <IncomingCall />;
}
