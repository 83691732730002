import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Emails = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
		<path
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M2.667 2.667h10.666c.734 0 1.334.6 1.334 1.333v8c0 .733-.6 1.334-1.334 1.334H2.667c-.734 0-1.334-.6-1.334-1.334V4c0-.733.6-1.333 1.334-1.333Z"
		/>
		<path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M14.667 4 8 8.667 1.333 4" />
	</svg>
);
export default Emails;
