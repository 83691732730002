import Button from '@/components/Button';
import Chip from '@/components/Chip';
import Dropdown from '@/components/email-comp/Dropdown';
import RadioGroup from '@/components/form/RadioGroup';
import Heading from '@/components/Heading';
import HorizontalLine from '@/components/HorizontalLine';
import VCandidate from '@/components/icons/VCandidate';
import VEmployee from '@/components/icons/VEmployee';
import VClient from '@/components/icons/VV_Profiles/VClient';
import VLancer from '@/components/icons/VV_Profiles/VLancer';
import { useToast } from '@/components/ui/use-toast';
import { useLoginRelatedAccountMutation, useLogoutMutation, useUpdateOnlineStatusMutation } from '@/lib/api/mutations';
import { useLazyMyRelatedAccountsQuery } from '@/lib/api/queries';
import { useAppSelector } from '@/lib/hooks';
import { logoutUser, setToken, setUser, setVJobType, setVLanceType } from '@/lib/slice/authSlice';
import { clsx } from 'clsx';
import { deleteCookie, setCookie } from 'cookies-next';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { persist } from '../lib/store';
import GradientChevron from './icons/GradientChevron';
import ProfileIcon from './ProfileIcon';

const ProfileDropdown = () => {
	const t = useTranslations('footer');

	const { toast } = useToast();
	const router = useRouter();
	const dispatch = useDispatch();

	const { user, v_job_type, v_lance_type, expires_in } = useAppSelector((state) => state.auth);
	const [currUser, setCurrUser] = useState(user);
	const [vJob, setVJob] = useState<any>(v_job_type == 'employer' ? 2 : 1);
	const [vLance, setVLance] = useState<any>(v_lance_type == 'client' ? 2 : 1);
	const [logout, { data, isLoading }] = useLogoutMutation();
	const [updateOnlineStatus, { data: updatedOnlineStatus }] = useUpdateOnlineStatusMutation();
	const [loginRelatedAccount, { data: relatedAccountData, error: realatedAccountError }] =
		useLoginRelatedAccountMutation();
	const [getUsers, { data: userData }] = useLazyMyRelatedAccountsQuery();

	useEffect(() => {
		getUsers({ profile_type_enum: 'all' }, false);
	}, [updatedOnlineStatus]);

	useEffect(() => {
		if (data) {
			if (data?.message) {
				toast({
					title: data.message,
				});
				dispatch(logoutUser());
				deleteCookie('isLoggedIn');
				deleteCookie('verification_status');
				deleteCookie('showVerificationModal');
				deleteCookie('showWelcomeModal');
				persist.purge();
				router.replace('/');
			}
		}
	}, [data]);
	useEffect(() => {
		if (relatedAccountData) {
			dispatch(setUser(relatedAccountData.user));
			setCurrUser(relatedAccountData.user);
			dispatch(
				setToken({
					token: relatedAccountData.token,
					expires_in: relatedAccountData.expires_in + Math.floor(Date.now() / 1000),
				})
			);
			setCookie('verification_status', relatedAccountData.user.verification_status);
			setCookie('showWelcomeModal', relatedAccountData.user.is_hide_welcome ? 'true' : 'false');
			window.location.reload();
		}
	}, [relatedAccountData]);

	useEffect(() => {
		if (realatedAccountError) {
			if ('data' in realatedAccountError) {
				const errMsg: any = realatedAccountError.data;
				toast({
					title: errMsg.error,
					variant: 'destructive',
				});
			}
		}
	}, [realatedAccountError]);

	const handleLogoutButton = () => {
		if (!isLoading) {
			logout({});
		}
	};

	const handleSwitchProfile = (_user: any) => {
		if (_user.id !== currUser.id) loginRelatedAccount({ id: _user.id });
		if (_user.profile_type != 'personal') {
			setVJob(2);
			setVLance(1);
			dispatch(setVJobType('employer'));
			dispatch(setVLanceType('freelancer'));
		}
	};

	const VJobsProfile = !user?.is_v_jobs_profile_built;
	const VLancerProfile = !user?.is_v_lance_profile_built;

	return (
		<Dropdown
			Button={
				<div className="flex items-center space-x-1">
					<ProfileIcon
						active={userData?.data?.find((_user) => _user.id === currUser.id)?.is_online_profile_state}
						image={userData?.data?.find((_user) => _user.id === currUser.id)?.image}
						profile_type={userData?.data?.find((_user) => _user.id === currUser.id)?.profile_type ?? ''}
					/>
					<GradientChevron />
				</div>
			}
			menuClass={'mt-2'}
		>
			<div className="w-[282px]">
				<Heading title={t('iWillShowUpAs')} customClass="!text-sm" />
				{userData?.data.map((_user, index) => (
					<div
						key={index}
						className={clsx(
							'mt-[5px] flex gap-2.5 rounded-md border p-[15px]',
							_user.id === currUser.id ? 'blue-gradient' : 'cursor-pointer bg-[#F5F5F5]'
						)}
						onClick={() => handleSwitchProfile(_user)}
					>
						<div>
							<ProfileIcon
								active={_user.is_online_profile_state}
								image={_user?.image}
								profile_type={_user.profile_type}
							/>
						</div>

						<div className="flex flex-col justify-center">
							<p className={clsx('text-[14px] font-bold', _user.id === currUser.id ? 'text-white' : 'text-[#2B2525]')}>
								{_user.full_name}
							</p>
							{_user.current_title ? (
								<p
									className={clsx(
										'line-clamp-1 text-[12px] leading-4 group-hover:text-white',
										_user.id === currUser.id ? 'text-white' : 'text-[#2B2525]'
									)}
								>
									{_user.current_title}
								</p>
							) : null}
						</div>
					</div>
				))}

				{user?.profile_type === 'personal' && (
					<>
						<Heading title={t('inVJobsImA')} customContainerClass={'my-2.5'} customClass="!text-sm" />
						<RadioGroup
							disable={user?.profile_type != 'personal' || VJobsProfile}
							iconCheck={true}
							name={'vacancy'}
							selectedValue={
								vJob == 2
									? {
											id: 2,
											name: t('vEmployer'),
											icon: <VEmployee disabled={VJobsProfile} color={vJob == 2 ? '#fff' : '#00000080'} />,
											value: 'employer',
										}
									: {
											id: 1,
											name: t('vCandidate'),
											icon: <VCandidate disabled={VJobsProfile} color={vJob == 1 ? '#fff' : '#00000080'} />,
											value: 'candidate',
										}
							}
							iconClass={'!bg-white border-[1px] border-black/50 !h-[26px] !w-[26px]'}
							options={[
								{
									id: 1,
									name: t('vCandidate'),
									icon: <VCandidate disabled={VJobsProfile} color={vJob == 1 ? '#fff' : '#00000080'} />,
									value: 'candidate',
								},
								{
									id: 2,
									name: t('vEmployer'),
									icon: <VEmployee disabled={VJobsProfile} color={vJob == 2 ? '#fff' : '#00000080'} />,
									value: 'employer',
								},
							]}
							onChange={(item) => {
								setVJob(item.id);
								dispatch(setVJobType(item.value));
							}}
						/>
					</>
				)}
				<Heading title={t('inVLanceImA')} customContainerClass={'my-2.5'} customClass="!text-sm" />
				<RadioGroup
					disable={VLancerProfile}
					iconCheck={true}
					name={'vacancy'}
					selectedValue={
						vLance == 1
							? {
									id: 1,
									name: t('vLancer'),
									icon: <VLancer disabled={VLancerProfile} color={vLance == 2 ? '#fff' : '#00000080'} />,
									value: 'freelancer',
								}
							: {
									id: 2,
									name: t('vClient'),
									icon: <VCandidate disabled={VLancerProfile} color={vLance == 1 ? '#fff' : '#00000080'} />,
									value: 'client',
								}
					}
					iconClass={'!bg-white border-[1px] border-black/50 !h-[26px] !w-[26px]'}
					options={[
						{
							id: 1,
							name: t('vLancer'),
							icon: <VLancer disabled={VLancerProfile} color={vLance == 1 ? '#fff' : '#00000080'} />,
							value: 'freelancer',
						},
						{
							id: 2,
							name: t('vClient'),
							icon: <VClient color={vLance == 2 ? '#fff' : '#00000080'} />,
							value: 'client',
						},
					]}
					onChange={(item) => {
						setVLance(item.id);
						dispatch(setVLanceType(item.value));
					}}
				/>

				{userData?.data && (
					<>
						<Heading title={t('othersWillSeeMe')} customContainerClass={'my-2.5'} customClass="!text-sm" />
						<div className={'flex rounded-full border'}>
							<Chip
								onClick={() => updateOnlineStatus({ status: true })}
								label={t('online')}
								type={
									userData?.data?.find((_user) => _user.id === currUser.id)?.is_online_profile_state ? 1 : 'outline'
								}
								className={'!w-1/2 justify-center border-0 !py-3 text-center'}
							/>
							<Chip
								onClick={() => updateOnlineStatus({ status: false })}
								label={t('offline')}
								type={
									userData?.data?.find((_user) => _user.id === currUser.id)?.is_online_profile_state ? 'outline' : 2
								}
								className={'!w-1/2 justify-center border-0 !py-3 text-center'}
							/>
						</div>
					</>
				)}

				<HorizontalLine className={'my-2.5'} />

				<Button
					disabled={isLoading}
					onClick={handleLogoutButton}
					title={t('logout')}
					type={'secondary'}
					className={'w-full'}
				/>
			</div>
		</Dropdown>
	);
};
export default ProfileDropdown;
