import * as React from 'react';

type Props = {
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const GradientChevron = (props: Props) => {
	const { width = 16, height = 16, ...rest } = props;
	return (
		<svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path
				d="M4 6L8 10L12 6"
				stroke="url(#paint0_linear_9690_64050)"
				strokeWidth={2}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_9690_64050"
					x1={7.12963}
					y1={9.80303}
					x2={11.0733}
					y2={5.69274}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#016DEA" />
					<stop offset={1} stopColor="#FA0101" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default GradientChevron;
