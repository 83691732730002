type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const ShoppingCart = (props: Props) => {
	const { width = 24, height = 24, ...rest } = props;
	return (
		<svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
			<path
				d="M6.29977 5.00024H21L19 12.0002H7.37671M20 16.0002H8L6 3.00024H3M9 20.0002C9 20.5525 8.55228 21.0002 8 21.0002C7.44772 21.0002 7 20.5525 7 20.0002C7 19.4479 7.44772 19.0002 8 19.0002C8.55228 19.0002 9 19.4479 9 20.0002ZM20 20.0002C20 20.5525 19.5523 21.0002 19 21.0002C18.4477 21.0002 18 20.5525 18 20.0002C18 19.4479 18.4477 19.0002 19 19.0002C19.5523 19.0002 20 19.4479 20 20.0002Z"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default ShoppingCart;
