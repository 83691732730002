'use client';
import { fetchToken, messaging } from '@/firebase/notifications';
import { useSendFCMTokenMutation } from '@/lib/api/mutations';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { increaseNotificationCount } from '@/lib/slice/notificatins';
import { onMessage, Unsubscribe } from 'firebase/messaging';
import { useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';

async function getNotificationPermissionAndToken() {
	// Step 1: Check if Notifications are supported in the browser.
	if (!('Notification' in window)) {
		console.info('This browser does not support desktop notification');
		return null;
	}

	// Step 2: Check if permission is already granted.
	if (Notification.permission === 'granted') {
		return await fetchToken();
	}

	// Step 3: If permission is not denied, request permission from the user.
	if (Notification.permission !== 'denied') {
		const permission = await Notification.requestPermission();
		if (permission === 'granted') {
			return await fetchToken();
		}
	}

	console.log('Notification permission not granted.');
	return null;
}

const useFcmToken = () => {
	const router = useRouter(); // Initialize the router for navigation.
	const [notificationPermissionStatus, setNotificationPermissionStatus] = useState<NotificationPermission | null>(null); // State to store the notification permission status.
	const [token, setToken] = useState<string | null>(null); // State to store the FCM token.
	const retryLoadToken = useRef(0); // Ref to keep track of retry attempts.
	const isLoading = useRef(false); // Ref to keep track if a token fetch is currently in progress.
	const dispatch = useAppDispatch();
	const [sendFCMToken] = useSendFCMTokenMutation();
	const { token: authToken } = useAppSelector((state) => state.auth);
	const loadToken = async () => {
		// Step 4: Prevent multiple fetches if already fetched or in progress.
		if (isLoading.current) return;

		isLoading.current = true; // Mark loading as in progress.
		const token = await getNotificationPermissionAndToken(); // Fetch the token.

		// Step 5: Handle the case where permission is denied.
		if (Notification.permission === 'denied') {
			setNotificationPermissionStatus('denied');
			console.info(
				'%cPush Notifications issue - permission denied',
				'color: green; background: #c7c7c7; padding: 8px; font-size: 20px'
			);
			isLoading.current = false;
			return;
		}
		// Step 6: Retry fetching the token if necessary. (up to 3 times)
		// This step is typical initially as the service worker may not be ready/installed yet.
		if (!token) {
			if (retryLoadToken.current >= 3) {
				isLoading.current = false;
				return;
			}

			retryLoadToken.current += 1;
			console.error('An error occurred while retrieving token. Retrying...');
			isLoading.current = false;
			await loadToken();
			return;
		}

		// Step 7: Set the fetched token and mark as fetched.
		setNotificationPermissionStatus(Notification.permission);
		setToken(token);
		isLoading.current = false;
	};
	const sendFcmToken = async () => {
		const res = await sendFCMToken({
			token,
			device_type: 'web',
		});
	};
	useEffect(() => {
		// Step 8: Initialize token loading when the component mounts.
		if ('Notification' in window && authToken) {
			loadToken();
		}
	}, [authToken]);

	useEffect(() => {
		const setupListener = async () => {
			if (!token) return; // Exit if no token is available.

			const m = await messaging();
			if (!m) return;

			// Step 9: Register a listener for incoming FCM messages.
			const unsubscribe = onMessage(m, (payload) => {
				if (Notification.permission !== 'granted') return;
				// refetch data
				// refetchCount();
				dispatch(increaseNotificationCount());
				console.log('Foreground push notification received:', payload);
				// const link = payload.fcmOptions?.link || payload.data?.link;

				// if (link) {
				// 	Toast({ title: `${payload.notification?.title}: ${payload.notification?.body}` });
				// } else {
				// 	Toast({ title: `${payload.notification?.title}: ${payload.notification?.body}` });
				// }

				// --------------------------------------------
				// Disable this if you only want toast notifications.
				// const n = new Notification(payload.notification?.title || 'New message', {
				// 	body: payload.notification?.body || 'This is a new message',
				// 	data: link ? { url: link } : undefined,
				// });

				// // Step 10: Handle notification click event to navigate to a link if present.
				// n.onclick = (event) => {
				// 	event.preventDefault();
				// 	const link = (event.target as any)?.data?.url;
				// 	if (link) {
				// 		router.push(link);
				// 	} else {
				// 		console.log('No link found in the notification payload');
				// 	}
				// };
				// --------------------------------------------
			});

			return unsubscribe;
		};

		let unsubscribe: Unsubscribe | null = null;

		setupListener().then((unsub) => {
			if (unsub) {
				unsubscribe = unsub;
			}
		});

		// Step 11: Cleanup the listener when the component unmounts.
		return () => unsubscribe?.();
	}, [token, router, dispatch]);

	useEffect(() => {
		if (token) sendFcmToken();
	}, [token]);
	return { token, notificationPermissionStatus }; // Return the token and permission status.
};

export default useFcmToken;
