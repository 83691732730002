'use client';

import { SocialMedia, VV_Assets, VV_Profiles, V_list, menuItems } from '@/constants/constants';
import { getCookie } from 'cookies-next';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import Location from '../icons/Location';
import Emails from '../icons/megamenu_account_office/Emails';
import StarDefault from '../icons/megamenu_home/StarDefault';
import Phone from '../icons/Phone';
import TextLogo from '../icons/TextLogo';
import RestrictedContent from '../RestrictedContent';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import MenuAndFooterAds from '../MenuAndFooterAds';

export default function Footer() {
	const t = useTranslations('footer');
	const [openModal, setOpenModal] = useState(false);
	const loginModal = getCookie('loginModalCookie');
	useEffect(() => {
		if (loginModal) setOpenModal(false);
	}, [loginModal]);
	return (
		<footer className="flex flex-col items-center justify-center">
			<RestrictedContent visible={openModal} onClose={() => setOpenModal(false)} />

			<MenuAndFooterAds location="footer" />

			<div className="bottom-0 h-auto w-full bg-[linear-gradient(62deg,#016dea_27%,#fa0101_95%)] p-[28px] lg:block">
				<div className="flex flex-col space-y-5">
					<div className="flex w-full flex-col justify-between gap-[25px] xl:flex-row">
						<div className="mt-[10px] flex flex-col justify-start md:w-[343px]">
							<div className="flex justify-center md:justify-start">
								<TextLogo />
							</div>
							<div className="w-full text-center md:max-w-[343px] md:text-start">
								<p className="my-3 text-balance font-poppins text-sm/[22.4px] font-medium text-white">
									{t('proudly')}, {t('firstOfItsKind')},
									<br /> {t('aioDigitalPlatform')}
								</p>
							</div>

							<div className="hidden border-y border-white md:block">
								<div className="flex w-full flex-col space-y-[15px] py-[10px]">
									<div className="text-lg/[21.6px] font-extrabold text-white">{t('asiaHeadOffice')}</div>
									<a href="mailto:info@myvverse.com" className="flex h-4 w-full items-center gap-[5px]">
										<Emails />
										<p className="text-sm/none font-medium text-white">info@myvverse.com</p>
									</a>
									<a href="tel:+971527956665" className="flex h-4 w-full items-center gap-[5px]">
										<Phone />
										<p className="text-sm/none font-medium text-white">+971 52 7956665</p>
									</a>
									<div className="flex h-4 w-full items-center gap-[5px]">
										<Location />
										<p className="text-sm/none font-medium text-white">{t('virtuallyManagedFromUAE')}</p>
									</div>
								</div>
								<div className="flex w-full flex-col space-y-[15px] py-[10px]">
									<div className="text-lg/[21.6px] font-extrabold text-white">{t('africaHeadOffice')}</div>
									<a href="mailto:info@myvverse.com" className="flex h-4 w-full items-center gap-[5px]">
										<Emails />
										<p className="text-sm/none font-medium text-white">info@myvverse.com</p>
									</a>
									<a href="tel:+971527956665" className="flex h-4 w-full items-center gap-[5px]">
										<Phone />
										<p className="text-sm/none font-medium text-white">+20 1008982000</p>
									</a>
									<div className="flex h-4 w-full items-center gap-[5px]">
										<Location />
										<p className="text-sm/none font-medium text-white">{t('virtuallyManagedFromEgypt')}</p>
									</div>
								</div>
							</div>

							<div className="w-full border-t"></div>

							<div className="mt-3 text-center text-lg/[21.6px] font-extrabold text-white md:text-start">
								{t('vvArabicSM')}
							</div>
							<div className="justify-centerr mt-2 flex items-center justify-center md:justify-start">
								{SocialMedia.map((item, index) => {
									return (
										<a
											key={item.name}
											href={item.href}
											target="_blank"
											className={clsx(
												'border-l border-white px-[10px] py-[2px] first:border-none',
												index === 0 ? '!pl-0' : ''
											)}
										>
											{item.icon}
										</a>
									);
								})}
							</div>

							<div className="mt-3 text-center text-lg/[21.6px] font-extrabold text-white md:text-start">
								{t('vvEnglishSM')}
							</div>
							<div className="mt-2 flex items-center justify-center md:justify-start">
								{SocialMedia.map((item, index) => {
									return (
										<a
											key={item.name}
											href={item.href}
											target="_blank"
											className={clsx(
												'border-l border-white px-[10px] py-[2px] first:border-none',
												index === 0 ? '!pl-0' : ''
											)}
										>
											{item.icon}
										</a>
									);
								})}
							</div>
						</div>

						<div className="hidden w-[70%] grid-cols-1 gap-[30px] md:grid md:grid-cols-2 md:pt-3 xl:grid-cols-4">
							<div className="relative flex w-[200px] flex-col space-y-[15px]">
								<Link href="/" className="text-lg/[21.6px] font-extrabold text-white">
									{t('home')}
								</Link>
								<div className="flex flex-col space-y-[20px]">
									{menuItems(t).col1.map((group, index) => {
										return (
											<div key={index} className="flex flex-col space-y-[15px]">
												<div className="text-lg/[21.6px] font-extrabold text-white">{group.header}</div>
												<div className="flex flex-col space-y-[10px]">
													{group.links.map((item, index) => {
														if (item.verified && getCookie('verification_status') !== 'approved') {
															return (
																<button
																	onClick={() => setOpenModal(true)}
																	key={index}
																	className="flex flex-1 items-center gap-[5px]"
																>
																	{item.icon}
																	<p className="min-w-max text-sm/none font-medium text-white">{item.name}</p>
																</button>
															);
														}

														return (
															<Link href={item.href} key={index} className="flex flex-1 items-center gap-[5px]">
																{item.icon}
																<p className="min-w-max text-sm/none font-medium text-white">{item.name}</p>
															</Link>
														);
													})}
												</div>
											</div>
										);
									})}
								</div>
							</div>
							<div className="relative w-[200px]">
								<div className="flex h-auto flex-col space-y-[15px]">
									<div className="text-lg/[21.6px] font-extrabold text-white">{t('theVs')}</div>
									<div className="flex flex-col space-y-[10px]">
										{V_list(t).map((item, index) => {
											if (item.verified && getCookie('verification_status') !== 'approved') {
												return (
													<button
														onClick={() => setOpenModal(true)}
														key={index}
														className="flex w-full items-center gap-[5px]"
													>
														<StarDefault />
														<p className="text-sm/none font-medium text-white">{item.name}</p>
													</button>
												);
											}

											return (
												<Link href={item.href} key={index} className="flex w-full items-center gap-[5px]">
													<StarDefault />
													<p className="text-sm/none font-medium text-white">{item.name}</p>
												</Link>
											);
										})}
									</div>
								</div>
							</div>

							<div className="relative w-[200px]">
								<div className="flex h-auto flex-col space-y-[15px]">
									<div className="text-lg/[21.6px] font-extrabold text-white">{t('vvAssets')}</div>
									<div className="flex flex-col space-y-[10px]">
										{VV_Assets(t).map((item, index) => {
											if (item.verified && getCookie('verification_status') !== 'approved') {
												return (
													<button
														onClick={() => setOpenModal(true)}
														key={index}
														className="flex w-full items-center gap-[5px]"
													>
														{item.icon}
														<p className="text-sm/none font-medium text-white">{item.name}</p>
													</button>
												);
											}

											return (
												<Link href={item.href} key={index} className="flex w-full items-center gap-[5px]">
													{item.icon}
													<p className="text-sm/none font-medium text-white">{item.name}</p>
												</Link>
											);
										})}
									</div>
								</div>
							</div>

							<div className="relative w-[200px]">
								<div className="flex h-auto flex-col space-y-[15px]">
									<div className="text-lg/[21.6px] font-extrabold text-white">{t('vvProfiles')}</div>
									<div className="flex flex-col space-y-[10px]">
										{VV_Profiles(t).map((item, index) => {
											if (item.verified && getCookie('verification_status') !== 'approved') {
												return (
													<button
														onClick={() => setOpenModal(true)}
														key={index}
														className="flex w-full items-center gap-[5px]"
													>
														{item.icon}
														<p className="text-sm/none font-medium text-white">{item.name}</p>
													</button>
												);
											}
											return (
												<Link href={item.href} key={index} className="flex w-full items-center gap-[5px]">
													{item.icon}
													<p className="text-sm/none font-medium text-white">{item.name}</p>
												</Link>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="mt-2 w-full border-t border-white">
						<div className="flex flex-col items-center justify-between gap-3 pt-5 md:flex-row md:gap-0">
							<div className="text-sm/none font-medium text-white">Copyright © 2024 V-Verse</div>

							<div className="text-sm/none font-medium text-white">
								Built with ❤️ By{' '}
								<a href="https://area-h.com" target="_blank">
									IKONIKS
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
