import Entity from '@/components/icons/Entity';
import clsx from 'clsx';
import Image from 'next/image';
import Circle from './Circle';
import DropdownProfile from './icons/DropdownProfile';

type Props = {
	image?: any;
	active?: boolean;
	customClass?: string;
	profile_type?: string;
};

const ProfileIcon = ({ image, active = true, customClass, profile_type = 'business' }: Props) => {
	return (
		<Circle
			backgroundClass={clsx('h-[43px] w-[43px]', customClass)}
			icon={
				<div className="relative">
					<div className={'flex h-[45px] w-[45px] items-center justify-center'}>
						{image ? (
							<Image
								src={image}
								alt="Profile Image"
								width={45}
								height={45}
								className="aspect-square rounded-full object-cover p-[1px]"
							/>
						) : profile_type == 'business' ? (
							<Entity />
						) : (
							<DropdownProfile />
						)}
					</div>
					<div
						className={clsx(
							'absolute bottom-0 right-0 h-[13px] w-[13px] rounded-full border-[2px] border-white',
							active ? 'bg-[#0ACD5E]' : 'bg-red-500'
						)}
					></div>
				</div>
			}
		/>
	);
};

export default ProfileIcon;
