import * as React from 'react';

type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const EmailO = (props: Props) => {
	const { width = 17, height = 16, color = 'white', ...rest } = props;
	return (
		<svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M3.16732 2.66699H13.834C14.5673 2.66699 15.1673 3.26699 15.1673 4.00032V12.0003C15.1673 12.7336 14.5673 13.3336 13.834 13.3336H3.16732C2.43398 13.3336 1.83398 12.7336 1.83398 12.0003V4.00032C1.83398 3.26699 2.43398 2.66699 3.16732 2.66699Z"
				stroke="#AAA9AD"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M15.1673 4L8.50065 8.66666L1.83398 4" stroke="#AAA9AD" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default EmailO;
