import * as React from 'react';

type Props = {
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const XMark = (props: Props) => {
	const { width = 80, height = 80, ...rest } = props;
	return (
		<svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<circle opacity={0.8} cx={40} cy={40} r={40} fill="url(#paint0_linear_30_308)" />
			<defs>
				<linearGradient id="paint0_linear_30_308" x1={0} y1={40} x2={80} y2={40} gradientUnits="userSpaceOnUse">
					<stop stopColor="#FA0101" />
					<stop offset={1} stopColor="#016DEA" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default XMark;
