import XMark from '@/components/icons/XMark';
import clsx from 'clsx';

type Props = {
	label: string;
	type?: 0 | 1 | 2 | 3 | 'outline';
	onClose?: () => void;
	className?: string;
	onClick?: () => void;
};

const Chip = (props: Props) => {
	const { label, type = 0, className, onClick } = props;

	return (
		<div
			onClick={() => {
				if (onClick) {
					onClick();
				}
			}}
			className={clsx(
				'w-max space-x-[5px] rounded-full border border-[#E5E5E5] px-2.5 py-[7px] text-[10px] font-medium not-italic leading-[9.6px] text-white',
				'flex items-center font-semibold',
				type == 0 && 'bg-[linear-gradient(270deg,#7F00FF_0%,#5200A5_100%)]',
				type == 1 && 'bg-[linear-gradient(90deg,#02903F_0%,#0ACD5E_100%)]',
				type == 2 && 'bg-[linear-gradient(90deg,#FA4001_0%,#CD0D14_33.33%,#A91D1E_66.66%,#812924_100%)]',
				type == 3 && 'bg-[linear-gradient(90deg,#016DEA_0%,#005FCD_33.41%,#0052B1_63.49%,#003B7E_100.01%)]',
				type == 'outline' && '!bg-white !text-black/50',
				onClick && 'cursor-pointer',
				className
			)}
		>
			<span>{label}</span>
			{props?.onClose && (
				<div>
					<XMark onClick={props.onClose} className="h-3 w-3 cursor-pointer text-white hover:text-gray-50" />
				</div>
			)}
		</div>
	);
};

export default Chip;
