import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Location = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
		<path
			stroke="#fff"
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M14 6.667c0 4.666-6 8.666-6 8.666s-6-4-6-8.666a6 6 0 1 1 12 0Z"
		/>
		<path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M8 8.667a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
	</svg>
);
export default Location;
