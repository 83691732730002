import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const Close = (props: SVGProps<SVGSVGElement>) => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.5286 20.4188L5.33016 33.6173L7.21577 35.5029L20.4142 22.3045L33.6134 35.5036L35.499 33.618L22.2998 20.4188L35.5 7.21863L33.6144 5.33301L20.4142 18.5332L7.21472 5.33374L5.3291 7.21936L18.5286 20.4188Z"
			fill="white"
		/>
	</svg>
);
export default Close;
