import { useGetAdsBySpotsIdsQuery } from '@/lib/api/queries';
import { ReceivedAdType } from '@/utils/types';
import { Skeleton } from '@nextui-org/react';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import { Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
import AvailableAdSpot from './AvailableAdSpot';
import Button from './Button';
import Ad from './icons/Ad';
import { useToast } from './ui/use-toast';
import VideoPlayer from './VideoPlayer';

type Props = {
	location: 'footer' | 'mega-menu';
};

const MenuAndFooterAds = ({ location }: Props) => {
	// STATES
	const [swiper, setSwiper] = useState<SwiperType>();
	const [ads, setAds] = useState<ReceivedAdType[] | null>([]);

	const { toast } = useToast();

	// QUERIES
	const {
		data: adsData,
		isLoading: isLoadingAds,
		isError: isErrorAds,
		error: adsError,
	} = useGetAdsBySpotsIdsQuery(location === 'footer' ? [7] : [1]);

	useEffect(() => {
		if (adsData) setAds(adsData[0]);
	}, [adsData]);

	useEffect(() => {
		if (isErrorAds)
			toast({
				title: (adsError as Error).message || adsData?.error || 'Logo is required',
				variant: 'destructive',
			});
	}, [adsData, adsError, isErrorAds]);

	if (isLoadingAds) {
		return <Skeleton className="h-[330px] w-full" />;
	}

	// CHECK FOR NO ADS
	if (!ads || ads.length === 0) {
		return <AvailableAdSpot />;
	}

	return (
		<div
			className={clsx(
				'mx-auto mt-4 flex h-[330px] w-full max-w-screen-2xl justify-center px-[30px]',
				location === 'mega-menu' && '!px-[0px]'
			)}
		>
			<Swiper
				effect={'fade'}
				spaceBetween={0}
				slidesPerView={1}
				autoplay={{
					delay: 5500,
					disableOnInteraction: false,
				}}
				scrollbar={{ draggable: true }}
				modules={[Autoplay, EffectFade]}
				onSwiper={(swiper) => {
					setSwiper(swiper);
				}}
			>
				{ads?.map((ad, index) => (
					<SwiperSlide key={index}>
						{ad?.ad_content_type === 'video' ? (
							<div className="mb-5 flex h-[260px] w-full flex-col items-center justify-center gap-2 rounded-lg border p-[15px] shadow">
								<VideoPlayer
									isAd
									adTimer
									videos={[
										{
											title: ad.artwork.file_name,
											videoUrl: ad.artwork.url,
											thumbnailUrl:
												'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/ForBiggerEscapes.jpg',
										},
									]}
									sizeClass="w-full max-w-[345px] h-[230px] min-w-52"
									videoSliderLength="175px"
									sliderHeight="4px"
									audioSlider="49px"
									controlGap="5px"
									controlPadding="5px"
									iconSize={18}
									iconButtonSize="32px"
									aspectVideo=""
								/>
							</div>
						) : (
							<div
								className="relative mx-auto h-[300px] w-full max-w-[1450px] overflow-hidden rounded-lg bg-cover bg-center px-[13px] pb-[15px] shadow-[0px_4px_15px_0px_rgba(0,0,0,0.15)]"
								style={{ backgroundImage: `url(${ad?.artwork.url})` }}
							>
								<div className="absolute right-7 top-4 h-[18px] w-[24px] rounded-full bg-white">
									<Ad />
								</div>
							</div>
						)}
						{ad?.url && (
							<Button
								type="primary"
								customClass="w-[102px] absolute left-7 top-4"
								innerCustomClass="!py-2"
								title="Click Here"
								onClick={() => window.open(ad?.url, '_blank')}
							/>
						)}
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default MenuAndFooterAds;
