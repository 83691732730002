'use client';

import VideoPlayer from '@/components/VideoPlayer';
import { useWelcomeFlagMutation } from '@/lib/api/mutations';
import { useAppSelector } from '@/lib/hooks';
import exampleVideoData from '@/utils/sampleVideos/exampleVideoData';
import { deleteCookie, getCookie } from 'cookies-next';
import { useEffect, useState } from 'react';
import Modal from './Modal';
import CheckBox from './form/CheckBox';
import HandShake from './icons/HandShake';

export default function WelcomeModal() {
	const _showWelcomeModal = getCookie('showWelcomeModal');
	const user = useAppSelector((state) => state.auth.user);
	const [showModal, setShowModal] = useState<boolean>(
		_showWelcomeModal !== undefined &&
			(user !== null || user !== undefined) &&
			(_showWelcomeModal === 'true' || !user.is_hide_welcome) &&
			user.is_hide_welcome == null
	);
	const [hideCheckbox, setHideCheckbox] = useState<boolean>(false);

	const [updateWelcomeFlag] = useWelcomeFlagMutation();

	useEffect(
		() =>
			setShowModal(
				_showWelcomeModal !== undefined &&
					(user !== null || user !== undefined) &&
					(_showWelcomeModal === 'true' || !user.is_hide_welcome) &&
					user.is_hide_welcome == null
			),
		[_showWelcomeModal]
	);

	const handleOnClose = () => {
		if (hideCheckbox) {
			updateWelcomeFlag({});
		}
		deleteCookie('showWelcomeModal');
		setShowModal(false);
	};

	return (
		<Modal
			heading="Welcome to V-Verse"
			icon={<HandShake />}
			isOpen={showModal}
			onClose={() => handleOnClose()}
			customClass="!max-w-[620px]"
		>
			<div className="flex flex-col items-center gap-[15px]">
				<h3 className="whitespace-nowrap bg-[linear-gradient(62deg,#016DEA_27.41%,#FA0101_95.02%)] bg-clip-text font-poppins text-xl/6 font-bold not-italic leading-6 text-transparent">
					Congratulations!, You&apos;ve Made It!
				</h3>

				<VideoPlayer videos={exampleVideoData} />

				<label className="flex items-center">
					<CheckBox checked={hideCheckbox} onChange={(v) => setHideCheckbox(v)} />
					<p className="text-sm font-medium text-gray-400">Please Don&apos;t Show Me This Message Again</p>
				</label>
			</div>
		</Modal>
	);
}
