import { SVGProps } from 'react';

const MenuDots = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
		<rect width={23} height={23} x={0.5} y={0.5} stroke="#000" strokeOpacity={0.1} rx={11.5} />
		<path
			stroke="#000"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeOpacity={0.5}
			strokeWidth={1.333}
			d="M12 12.666a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333ZM12 8a.667.667 0 1 0 0-1.333A.667.667 0 0 0 12 8ZM12 17.333A.667.667 0 1 0 12 16a.667.667 0 0 0 0 1.333Z"
		/>
	</svg>
);
export default MenuDots;
