'use client';

import NewAssetBuildersModal from '@/components/modals/NewAssetBuildersModal';
import VVSearch from '@/components/modals/VVSearch';
import { useAppSelector } from '@/lib/hooks';
import { useState } from 'react';
import Circle from '../Circle';
import Magnify from '../icons/Magnify';
import Plus from '../icons/Plus';

const SidebarForMobile = () => {
	const isLoggedIn = useAppSelector((state) => state.auth.isLogin);
	const [isOpenAssetBuilderModal, setIsOpenAssetBuilderModal] = useState(false);
	const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);

	return (
		isLoggedIn && (
			<div className="hidden">
				<NewAssetBuildersModal
					isOpen={isOpenAssetBuilderModal}
					setIsOpen={(value) => setIsOpenAssetBuilderModal(value)}
				/>
				<VVSearch isOpen={isOpenSearchModal} setIsOpen={(value) => setIsOpenSearchModal(value)} />
				<div className="fixed bottom-8 z-50 inline-flex flex-col gap-y-2 ltr:right-8 rtl:left-8">
					<div
						role="button"
						onClick={() => setIsOpenAssetBuilderModal(true)}
						className="cursor-pointer drop-shadow-[0_0_8px_white]"
					>
						<Circle type={0} icon={<Plus width={20} height={20} />} backgroundClass="h-[2rem] w-[2rem]" />
					</div>
					<div
						role="button"
						onClick={() => setIsOpenSearchModal(true)}
						className="cursor-pointer drop-shadow-[0_0_8px_white]"
					>
						<Circle type={0} icon={<Magnify width={20} height={20} />} backgroundClass="h-[2rem] w-[2rem]" />
					</div>
				</div>
			</div>
		)
	);
};

export default SidebarForMobile;
