import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
	notificationUnreadCount: 0,
};

const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		initialReadNotifications: (state, action: PayloadAction<number>) => {
			state.notificationUnreadCount = action.payload;
		},
		readOneNotification: (state) => {
			state.notificationUnreadCount = state.notificationUnreadCount - 1;
		},
		increaseNotificationCount: (state) => {
			state.notificationUnreadCount = state.notificationUnreadCount + 1;
		},
	},
});

export const { initialReadNotifications, readOneNotification, increaseNotificationCount } = notificationsSlice.actions;
export default notificationsSlice.reducer;
